import * as actionTypes from './outlookFeedbackActionType';
import axios from 'axios';

import config from "../../config/config";

export function createFeedback(requestObj) {

  return async function (dispatch) {
      try {
      dispatch({ type: actionTypes.CREATE_FEEDBACK_REQUEST });  
      const res = await axios.post(config.api.server_url.request_url.base_url + "feedback", requestObj, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        }
      });
      dispatch({ type: actionTypes.CREATE_FEEDBACK_SUCCESS, payload: res });
    }
    catch (error) {
      dispatch({ type: actionTypes.CREATE_FEEDBACK_FAILURE, error });
    }
  };
}