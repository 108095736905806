import React, { useState, useEffect } from 'react';
const FailAssesmentView = (props) => {
    const jsonString = localStorage.getItem("quizFeedbackData");
    const data = JSON.parse(jsonString);
    return (
      <div>
        Good try {props.userName}! <br /> But better luck next time <br /> Your
        score <div className="score"> {props.score}% </div>
        <div>{((data.correctAssesmentValue !== "" &&
          data.correctAssesmentValue !== undefined &&
          data.correctAssesmentValue !== null) ||
          (data.correctAssesmentVideoUrl !== "" &&
          data.correctAssesmentVideoUrl !== undefined &&
          data.correctAssesmentVideoUrl !== null) || 
          (data.correctAssesmentFilePath !== "" &&
          data.correctAssesmentFilePath !== undefined &&
          data.correctAssesmentFilePath !== null)) ? "Check extra learning material": ""}</div>
        {data.correctAssesmentValue !== "" &&
          data.correctAssesmentValue !== undefined &&
          data.correctAssesmentValue !== null && (
            <div
              dangerouslySetInnerHTML={{ __html: data.correctAssesmentValue }}
            ></div>
          )}
        {data.correctAssesmentVideoUrl !== "" &&
          data.correctAssesmentVideoUrl !== undefined &&
          data.correctAssesmentVideoUrl !== null && (
            <div>
              <a
                href={data.correctAssesmentVideoUrl}
                target="_blank"
                className="formField-link"
              >
                click here
              </a>{" "}
              to view video
            </div>
          )}
        {data.correctAssesmentFilePath !== "" &&
          data.correctAssesmentFilePath !== undefined &&
          data.correctAssesmentFilePath !== null && (
            <div>
              <a
                href={
                  process.env.REACT_APP_BASE_URL + data.correctAssesmentFilePath
                }
                target="_blank"
                className="formField-link"
              >
                click here
              </a>{" "}
              to download the file
            </div>
          )}
      </div>
    );
}
export default FailAssesmentView;
