import React, {Component} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import classnames from 'classnames';
import {css} from "@emotion/core";
import SyncLoader from "react-spinners/SyncLoader";
import { get, pick } from "lodash"

import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { createFeedback } from "./outlookFeedbackAction";

toast.configure();

const override = css`
  display: block;
  margin: 200px auto;
  width: 100px;
  padding: '25%'
`;

const TOAST_CONFIGURATION = {
    autoClose: 2000,
    hideProgressBar: true,
    position: "bottom-right"
};

class OutlookFeedback extends Component {
    constructor(props) {
        super(props);

        this.props.homeVisibility(false);

        this.state = {
            feedback: '',
            like: false,
            toggle:false,
            loading: false,
            dislike: false,
            contactAdmin:false,
            showFeedbackform:false,
        };
    }

    componentDidMount = async () => {
        if (!localStorage.getItem("mail")) {
           // alert("User Not Logged In!!!");
            this.props.history.push("/");
        }
    };

    setInitialState = () => {
        this.setState({
            feedback: '',
            like: false,
            toggle:false,
            loading: false,
            dislike: false,
            contactAdmin:false,
            showFeedbackform:false,
        });
    }
    
    likeToggleHandler = () => {
        this.setState({
            like:!this.state.like,
            dislike: false,
            showFeedbackform: !this.state.like || this.state.dislike
        });
    };

    disLikeToggleHandler = () => {
        this.setState({
            dislike:!this.state.dislike,
            like: false,
            showFeedbackform: this.state.like || !this.state.dislike
        })
    };

    allowCheckboxHandle = e => {
        this.setState({contactAdmin:e.target.checked});
    };
    
    handleChange = e => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    createFeedback = async () =>{
        const requestObj = pick(this.state, ["contactAdmin", "like", "feedback"])
        requestObj.username = localStorage.getItem('mail');
        await this.props.createFeedback(requestObj);
        if(get(this.props, "feedback.meta.success", false)) {
            this.setInitialState();
            toast.success(get(this.props, "feedback.meta.message", ""), TOAST_CONFIGURATION);
            this.props.history.push("/outlook-home")
        } else {
            toast.error(get(this.props, "feedback.meta.message", "Something went wrong. Please try again."), TOAST_CONFIGURATION);
        }
    };

    
    render() {

        return (
            <div className="page-container quest-wrapper outlook-quest-wrapper">
                <div className="container-fluid mob-container">
                    {this.state.loading ?
                        <SyncLoader
                            css={override}
                            size={20}
                            color={"#0b6eb6"}
                            loading={this.state.loading}/>
                        : <div className="main-container feedback-div">                            
                            <div className="row justify-content-center">
                                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 p-2 heading">
                                <h2 className="outlook-page-title">
                                    <span>FEEDBACK</span>
                                    </h2>
                                </div>
                            </div>
                            {/*--------------------------------------------------------------------------*/}
                            <div className="scroll-container-inner">
                            <div className="row justify-content-center">
                                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 p-2">
                                    <div className="main-div">
                                        <div className="card custom-card p-2">

                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                        <div className="card-title">
                                                            <span>Tell us what you think</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div
                                                        className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 line-height">
                                                        <div>
                                                            <i className={classnames({ "fa fa-font": true, 'fa-thumbs-o-up': !this.state.like, 'fa-thumbs-up': this.state.like})} id="like"
                                                               onClick={this.likeToggleHandler}/>
                                                            <span className="text-padding text-font">
                                                                I like something
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                        <div>
                                                            <i className={classnames({ "fa fa-font": true, 'fa-thumbs-o-down': !this.state.dislike, 'fa-thumbs-down': this.state.dislike})} id="disLike"
                                                               onClick={this.disLikeToggleHandler}/>
                                                            <span className="text-padding text-font">
                                                                I don't like something
                                                            </span>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*------------------------------------------------------------------------------*/}
                            {this.state.showFeedbackform?
                            <div className="row justify-content-center">
                                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 p-2">
                                    <div className="main-div">
                                        <div className="card custom-card p-2">

                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                        <div className="card-title">
                                                            <span>Have more to say?</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                        <textarea
                                                            className="form-control"
                                                            id="exampleFormControlTextarea1"
                                                            rows="3"
                                                            placeholder="Type your feedback"
                                                            name="feedback"
                                                            onChange={this.handleChange}

                                                        />
                                                    </div>
                                                </div>

                                                <br/>
                                                <div className="row">
                                                        <div className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1">
                                                        <input
                                                            checked={this.state.contactAdmin}
                                                            className="custom-check-box"
                                                            type="checkbox"
                                                            name="contactAdmin" id="contactAdmin"
                                                            value={this.state.contactAdmin}
                                                            onChange={e => this.allowCheckboxHandle(e)}/>{' '}
                                                            </div>
                                                            <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10">
                                                        <label className="checkbox-label"
                                                               htmlFor="contactAdmin">Allow SprinkleZone to contact me about feedback</label>
                                                    </div>
                                                </div>
                                                <div className="row" style={{marginTop:'10px',justifyContent:'flex-end'}}>
                                                    <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                                            <button type="submit"
                                                                className="btn feedback-btn"
                                                                onClick={this.createFeedback}
                                                            >
                                                                Submit
                                                            </button>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>:""}
                            {/*------------------------------------------------------------------------------*/}
                            </div>                        
                        </div>}
                        
                </div>
            </div>

        );
    }


}


function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            createFeedback: createFeedback,
        },
        dispatch
    );
}

const mapStateToProps = state => {
    return {
        feedback: state.outlookSkillReducer.feedback || {}
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OutlookFeedback);