import * as actionTypes from './popularSkillsActionType';
import axios from 'axios';
import config from "../../config/config";

export function getPopularSkills() {

    return function (dispatch) {

        return axios.get(config.api.server_url.request_url.base_url +"getPopularSkills?email="+ localStorage.getItem('mail'), {
            headers: {
                Username : localStorage.getItem('mail'),
                Authorization: 'Bearer ' + localStorage.getItem('token'),
            }
        }).then((res) => {
            dispatch({ type: actionTypes.FETCH_POPULAR_SKILLS_SUCCESS, payload: res.data.data});

        }).catch(error=>{
            dispatch({ type: actionTypes.FETCH_POPULAR_SKILLS_FAILURE, error: error });
        })
    };
}
