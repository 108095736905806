import React, {Component} from 'react';
import validate from './validate';
import {loginService} from './login-service';
import axios from 'axios';
import './login.css';
const teamsLanding = require("./../../images/landingPage/teamsLanding.png");

class TeamsLandingPage extends Component {
    componentDidMount() {
        const search =this.props.location.search;
        const params = new URLSearchParams(search);
        const code = params.get('code');
        const queryParams = new URLSearchParams(window.location.search);
        if(queryParams.get("isTeamsApp") && queryParams.get("username") && !code) {
            this.handleRequest();
        }
    }


    userIdforTokenMapping = async (userId) => {
        let data = {
            token: localStorage.getItem("notificationToken"),
            userId: userId
        }
        if (data.token === null) return;
        await axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_URL}notification/updateUserIdForToken`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: {data}
        }).then((res) => {

        })
    }

    
    handleRequest = async () => {
        let username;
        let password;
        let teamsFlag = false;
        const queryParams = new URLSearchParams(window.location.search);
        if(queryParams.get("isTeamsApp") && queryParams.get("username")) {
            username =  queryParams.get("username").toLowerCase();
            password = "Test@1234"; // This won't be used at backend
            teamsFlag = true;
        } else {
            username = document.getElementById('username').value.toLowerCase();
            password = document.getElementById('password').value;
        }
        const status = validate(username, password);
        var str = "@harbingergroup.com";
        if (status === true) {
            if (!username.includes("@")) {
                if (username.endsWith(str) === false) {
                    username += str;
                }
            }
            const data = loginService(username, password);
            axios({
                method: 'post',
                url: teamsFlag ? process.env.REACT_APP_API_URL + 'login?isTeamsApp=true&username=' + queryParams.get("username").toLowerCase(): process.env.REACT_APP_API_URL + 'login',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: {data}
            }).then(res => {
                axios({
                    method: 'post',
                    url: teamsFlag ? process.env.REACT_APP_API_URL + 'checkToken?isTeamsApp=true&username=' + queryParams.get("username").toLowerCase(): process.env.REACT_APP_API_URL + 'checkToken',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: {data}
                }).then(res => {
                    localStorage.setItem('token', res.data.data.token);
                    localStorage.setItem('mail', res.data.data.username);
                    // localStorage.setItem('userId', res.data.data.userId);
                    // localStorage.setItem('userRole', res.data.data.user_role);
                    localStorage.setItem('organization', res.data.data.organization.organizationName);
                    if(res.data.data.organization){
                        localStorage.setItem('organizationLogoId', res.data.data.organization.organizationLogoId);
                        localStorage.setItem('headerColor', res.data.data.organization.headerColor);
                        localStorage.setItem('unameFont', res.data.data.organization.fontColor);
                        localStorage.setItem('organizationMobileLogoId', res.data.data.organization.organizationMobileLogoId);

                    }else{
                        localStorage.setItem('organizationLogoId', "");
                        localStorage.setItem('headerColor', "");
                    }
                    this.props.loadLessonData();
                    if (localStorage.getItem('token')) {
                        if(res.data.data.userId) {
                            this.userIdforTokenMapping(res.data.data.userId);
                        }
                        // this.props.history.push('/skills');
                        this.props.history.push('/dashboard');
                    }
                })
            }).catch(error => {
                document.getElementById("error").innerHTML = "<font color='red'><b><center>" + error.errors.message + "</center></b></font>"
            });
        } else {
            document.getElementById("error").innerHTML = "<font color='red'><b><center> Username or password is incorrect </center></b></font>"
        }
    }

    render() {
        return (
            <img src={teamsLanding} alt='SprinkleZone Logo' height="100%" width = "100%"></img>         
        );
    }
}

export default TeamsLandingPage;