import axios from "axios";

export function interceptors(props) {
    return axios.interceptors.response.use((response) => {
        return response;
    }, (error) => {
        if(error.response &&  parseInt(error.response.status) === 401){
            if(localStorage.length > 0){
                if(localStorage.getItem('token')){
                    alert(error.response.data.errors.message);
                    localStorage.removeItem('token');
                    window.location='/';
                } else {
                    window.location='/';
                }
            } else {
                window.location='/';
            }
        }
        if (error.response && error.response.data) {
            return Promise.reject(error.response.data);
        }
        return Promise.reject(error.message);
    });
}
