const properties = {
    api: {
        localhost_url: {
            request_url: {
                base_url: process.env.REACT_APP_API_URL,
                lesson_url: "lessons",
                skill_url: "skills",
                question_url: process.env.REACT_APP_API_URL + 'questions/',
                post_rating: process.env.REACT_APP_API_URL + 'rating/',
                get_topics: process.env.REACT_APP_API_URL + 'topics/',
                quiz_url: process.env.REACT_APP_API_URL + 'quiz/checkIfQuizGivenToday/',
                assigned_url: process.env.REACT_APP_API_URL + 'checkIfSkillUnassigned/',
            }
        },
        server_url: {
            request_url: {
                base_url: process.env.REACT_APP_API_URL,
                lesson_url: "lessons",
                skill_url: "skills",
                question_url: process.env.REACT_APP_API_URL + 'questions/',
                post_rating: process.env.REACT_APP_API_URL + 'rating/',
                get_topics: process.env.REACT_APP_API_URL + 'topics/',
                quiz_url: process.env.REACT_APP_API_URL + 'quiz/checkIfQuizGivenToday/',
                assigned_url: process.env.REACT_APP_API_URL + 'checkIfSkillUnassigned/',
            }
        }
    }
}

export default properties;