import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getLessons, getNudge } from "./lessonActions";
import { getSkills, getUnassignedSkillsData } from "./../skills/skillAction";
import config from "../config/config";
import DefaultImg from './lessonImg/default_img_upload.png'
import { Progress, Tooltip } from 'reactstrap';
import Breadcrumbs from "../../Breadcrumbs";

import { css } from "@emotion/core";
import SyncLoader from "react-spinners/SyncLoader";

const override = css`
  display: block;
  margin: 200px auto;
  width: 100px;
  padding: '25%'
`;

class LessonsComponent extends Component {
  constructor(props) {
  window.speechSynthesis.cancel();
    super(props);
    this.props.homeVisibility(true)
    this.state = {
      loading: false,
      alertNotShown: true
    };
   this.toggle = this.toggle.bind(this);
  }
  componentDidMount = async () => {
  if (!localStorage.getItem('token')) {
     // alert("User Not Logged In!!!");
      this.props.history.push('/');
    }

    
    this.setState({loading: true})
    await this.props.lesson(this.props.match.params.skill_id);
    await this.props.skills();
    await this.props.getNudge(this.props.match.params.skill_id);
       
    if(this.props.resultSkill) {
        let loadingTime = this;
        setTimeout(function () {
          loadingTime.setState({ loading: false });
        }, 200);
    }
    if(this.props.nudge && this.props.nudge.data) {
      let skills_data = this.props.nudge.data;
      if(skills_data !== null){
        localStorage.setItem("nudgeId",skills_data.nudgeId);
        localStorage.setItem("scheduleDate",new Date(skills_data.startDate));
        localStorage.setItem("iteration",skills_data.iteration);
      }
    }
  }

  componentDidUpdate = async (prevProps) => {
    await this.props.unassigned(this.props.match.params.skill_id);
     if(this.props.unassignedResult === true){
       if(this.state.alertNotShown === true){
        alert("This Nugget is not assigned to you. Please ask Admin to assign it.");
        this.state.alertNotShown = false;
        this.props.history.push('/dashboard');
       }     
      }      
}
  
  toggle(id) {
    let st = this.state;
    st[id] = !st[id];
    this.setState(st);
  }


  render() {
    const { resultSkill } = this.props;
    let skill_name = '';
    if(resultSkill && resultSkill.data) {
      let skill_details = resultSkill.data.filter((skill) => skill._id === this.props.match.params.skill_id);
      if(skill_details && skill_details.length > 0){
        skill_name = skill_details[0].name;
      }
    }
   
    return (
        <div>
        <Breadcrumbs/>
      <div className="inner-courses-wrapper mt-0">
        <div className="container-fluid mob-container">
           
          { this.state.loading ? 
            <SyncLoader
            css={override}
            size={20}
            color={"#0b6eb6"}
            loading={this.state.loading}
          /> 
          : 
          <div className="main-container lesson-content-scroll">
            <div className="row">
              <div className="col-lg-12 intro-section">
              <div className="col-lg-6 col-md-6">
             <h3 className="skillname">{this.props.location.skill_name ? this.props.location.skill_name : skill_name }</h3>
             </div>
             <div className="col-lg-6 col-md-6 page-head" style={{ marginTop: 0 }}>
           <h2>Learning bites ({this.props.result.data ? this.props.result.data.length : null}) </h2>
           </div>
            </div>
            </div>
            <div className="row">
              {this.props.result.data
                ? this.props.result.data.map((item, index) => (
                
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" key={index}>
                   
                    <Link
                      to={{
                        pathname: `/lessons/${item.skill_id}/${item._id}`,
                        lessonsTheme: item.component ? item.component.name : "basicSlider",
                      }}
                    >
                      <div className="card lessoncard">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-2">
                              {this.props.result.data[index].image ?
                                <img
                                  src={config.api.server_url.request_url.base_url + "getUploadedFile/"
                                    + this.props.result.data[index].image}
                                  className="nudge_image"
                                  alt='Img'
                                /> : <img
                                  className="nudge_image"
                                  src={DefaultImg}
                                  alt='Img' />
                              }
                            </div>
                            <div className="col-7 col-sm-7 col-md-7 col-lg-7 col-xl-10">
                              <div className="card-txt">
                                <h3>
                                  <span>{index + 1}) {item.title}</span>
                                </h3>
                                {
                                  item.description === "" || item.description === undefined ? "" :
                                    <div  data-title={`${item.description}`}>
                                      <span className="text-overflow" >{item.description}</span>
                                    </div>
                                }
                              </div>
                            </div>
                             </div>
                             <div className="row">
                             <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-2">
                              </div>
                             <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-7">
                              <div className="small-prog">
                            
                                {localStorage[item._id] ?
                                  <Progress id={"pr_" + index} color="success" value={localStorage[item._id]} />
                                  :
                                  item.isCompleted?(
                                      <Progress id={"pr_" + index} color="success" value={100} />
                                  ):
                                  <Progress id={"pr_" + index} value={0} />
                                }
                                <Tooltip placement="top" isOpen={this.state['pr_' + item._id]} target={"pr_" + index} toggle={() => this.toggle('pr_' + item._id)}>
                                  {localStorage[item._id] ? (
                                    localStorage[item._id] < 100 ? <span>In Progress</span> : <span>Completed</span>)
                                    : <span>Not started</span>}
                                </Tooltip>
                              </div>
                            </div> 
                            </div>
                         
                        </div>
                      </div>
                    </Link>
                  </div>
                ))
                : ""}
            </div>
          </div> }
        </div>
      </div>
      </div>   
    );
  }
  
}


const mapDispatchToProps = dispatch => {
  return {
      unassigned: (skill_id) => dispatch(
         getUnassignedSkillsData(skill_id)),
      lesson: (skill_id) => dispatch(
         getLessons(`lessons/` + skill_id)),
      skills: () => dispatch(getSkills("skills")),   
      getNudge: (skill_id) => dispatch(
        getNudge(`getNudge/` + skill_id)),
  };
};

const mapStateToProps = state => {
  let skillIsUnAssigned = false;
  return {
    result: state.lessonReducer.result || [],
    resultSkill: state.skillReducer.resultSkill || [],
    unassignedResult: state.skillReducer.unassignedData ? state.skillReducer.unassignedData.data.isSkillUnassigned : skillIsUnAssigned,
    nudge: state.lessonReducer.nudge || [],
  }
};


export default connect(mapStateToProps, mapDispatchToProps)(LessonsComponent);