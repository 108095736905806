import React, { Component } from "react";
import Slider from "react-slick";
import MagicSliderDots from "react-magic-slider-dots";
import { Link } from "react-router-dom";
import TextToSpeech from "../../topics/TextToSpeech";
import config from "../../config/config";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getQuizData } from "../../quiz/quizActions";
import Iframe from 'react-iframe';
import jwt_decode from "jwt-decode";
import "../basicSlider/basicSliderstyle.scss";
import CircularProgressBar from "../ChangingProgressProvider";
;
const stripHtml = require("string-strip-html");
let timeout;
class SimpleSlider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      counter: 0,
      isChecked: false,
      imageUrl: "",
      progressCounter: 0,
      maxProgress: 1,
      isLastPage: false,
      viewedSlideCount: 0,
      voiceText: "",
      settext: true,
      lastPage: false,
      sliderpagesData: [],
      skill_Id: this.props.id ? this.props.id : "",
      time: 0,
      isOn: false,
      start: 0,
      modalOpen: false,
      themeImgPreview: "",
      quizFlag: this.props.checkIfQuizGivenToday,
      currentPageIndex: 0,
      previousPageIndex: 0,
      showSideBar: false,
      mouseMoving: true,
      slideIndex: 0,
      updateCount: 0,
      nextLearningBite: "",
      isFullScreen: false,
      // isOptOutAllowed: false,
    };
    this.setText = this.setText.bind(this);
    this.changeContent = this.changeContent.bind(this);
  }

  startInteractionTimer = () => {
    this.setState({ mouseMoving: true });
    this.setControllerVisibility(true);
    clearTimeout(timeout);
    this.setControllerVisibilityTimeout();
  };

  setControllerVisibilityTimeout = () => {
    timeout = setTimeout(() => {
      this.setControllerVisibility(false);
      this.setState({ mouseMoving: false });
    }, 5000);
  };

  setControllerVisibility = (visible) => {
    const next = document.querySelectorAll("button.slick-arrow.slick-next");
    const prev = document.querySelectorAll("button.slick-arrow.slick-prev");

    next.forEach((button) => {
      button.style.visibility = visible ? "visible" : "hidden";
    });

    prev.forEach((button) => {
      button.style.visibility = visible ? "visible" : "hidden";
    });
  };
  changeContent(newContent) {
    this.setState({
      sliderpagesData: newContent,
    });
  }

  setText(newText) {
    let stripedText = stripHtml(newText);
    if (this.state.voiceText !== stripedText) {
      this.setState(
        {
          voiceText: stripedText,
          lastPage: false,
        },
        () => { }
      );
    }
  }
  componentDidMount() {
    const { sliderPages, id, lessons, getQuizData } = this.props;
    const { sliderpagesData, id: stateId } = this.state;

    if (sliderPages !== "" && sliderpagesData !== sliderPages) {
      this.setState(
        {
          sliderpagesData: sliderPages,
        },
        () => {
          const { pages, isQuiz } = this.state.sliderpagesData;
          let textToShow;
          if (pages) {
            if (pages.length > 1) {
              textToShow = pages[0].text;
            } else if (isQuiz) {
              if (this.state.quizFlag) {
                textToShow = "You have already taken this assessment recently. You can re-attempt it after 24hrs of your previous attempt.";
              } else {
                textToShow = "Thank you! Take an assessment now.";
              }
            } else {
              textToShow = "You have successfully completed the lesson.";
            }
          }

          this.setText(textToShow);

        }
      );
    }

    if (id !== "" && stateId !== id) {
      this.setState({
        skill_Id: id,
      });
    }

    if (lessons !== "") {
      this.setState({
        nextLearningBite: lessons._id,
      });
    }

    getQuizData(sliderPages._id);

    this.setControllerVisibilityTimeout();
    document.addEventListener("fullscreenchange", this.handleFullscreenChange);
    document.addEventListener("webkitfullscreenchange", this.handleFullscreenChange);
    document.addEventListener("msfullscreenchange", this.handleFullscreenChange);
    document.addEventListener("keydown", this.handleKeyDown);
  }
  componentWillUnmount() {
    document.removeEventListener("fullscreenchange", this.handleFullscreenChange);
    document.removeEventListener("webkitfullscreenchange", this.handleFullscreenChange);
    document.removeEventListener("msfullscreenchange", this.handleFullscreenChange);
    document.removeEventListener("keydown", this.handleKeyDown);
  }
  handleFullscreenChange = () => {
    const isFullScreen =
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.msFullscreenElement;

    this.setState({ isFullScreen });
  };

  handleKeyDown = (event) => {
    if (event.key === "F11" || event.key === "Escape") {
      this.setState({ isFullScreen: false });
    }
  };

  openFullscreen = () => {
    this.setState({ isFullScreen: true });
    var elem = document.getElementById("longslider");
    if (elem !== null && elem !== undefined) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE11 */
        elem.msRequestFullscreen();
      }
    }
  };

  closeFullscreen = () => {
    this.setState({ isFullScreen: false });
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      /* Safari */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      /* IE11 */
      document.msExitFullscreen();
    }
  };

  componentDidUpdate(prevProps) {
    const { quizData } = this.props;
    if (prevProps.quizData !== quizData) {
      this.setState({
        quizFlag: quizData.data.is_quiz_given_today,
      });
    }
    // if (prevProps.nudgeData !== nudgeData) {
    //   if (nudgeData.completed_learningBites && nudgeData.totalLearningBites) {
    //     if (nudgeData.totalLearningBites === nudgeData.completed_learningBites && nudgeData.isOptOut) {
    //       this.setState({
    //         isOptOutAllowed: true
    //       });
    //     }
    //   }
    // }

    let x = document.getElementById("longslider");
    const next = document.querySelectorAll("button.slick-arrow.slick-next");
    const prev = document.querySelectorAll("button.slick-arrow.slick-prev");
    if (next[0]) {
      next[0].addEventListener("mousemove", this.startInteractionTimer);
    }
    if (prev[0]) {
      prev[0].addEventListener("mousemove", this.startInteractionTimer);
    }
    x.addEventListener("mousemove", this.startInteractionTimer);
    document.addEventListener('keydown', this.handleKeyDown);
  }

  createMarkup(text) {
    return { __html: text };
  }
  onClickCancel = () =>{
    this.props.history.push(`/skills/${this.state.skill_Id}`);
  }
  // handleClick = () => {
  //   let user = jwt_decode(localStorage.getItem("token"));
  //   if(localStorage.getItem("nudgeId")!=="undefined" && localStorage.getItem("nudgeId")!=="" && localStorage.getItem("nudgeId")!== null){
  //     let data = {
  //       userId: user.username,
  //       nudgeId: localStorage.getItem("nudgeId")
  //     }
  //   this.props.OptOutNudge(data);
  //   }
  //   this.props.history.push(`/skills/${this.state.skill_Id}`)
  // };
  lastpageHtmlReturn(topicId, pagesData,fontColor) {
    return pagesData.isQuiz ? (
      <div className="flashcard-last">
        <div className="img-last-flashcard evaluationslide">
          <img
            className="evaluationIMG"
            src={require("./longSliderImg/validate-image.svg")}
            alt="evaluation"
          />
        </div>
        <div className="secondSlidername-last-flashcard" style={{color: fontColor}}>
          {pagesData.pages.length === 1 ? null : (
            <p>As you have completed this course</p>
          )}
          {<p>Take an assessment now</p>}
        </div>
        <div className="btn-last-flashcard">
          <div className="submit-btn">
            <Link to={`/skills/quiz/${this.state.skill_Id}/${topicId}`}>
              <button
                type=""
                className="btn btn-success active"
                disabled={this.state.quizFlag === true}
              >
                {" "}
                I'm Ready
              </button>
            </Link>
          </div>
        </div>
        <div className="name-last-flashcard" style={{color: fontColor}}>
          {this.state.quizFlag === true ? (
            <p style={{ textAlign: "center" }}>
              You have already taken this assessment recently!
              <br />
              You can re-attempt it after 24hrs of your previous attempt.
            </p>
          ) : null}

        </div>
        {this.state.nextLearningBite !== "" && this.state.nextLearningBite !== undefined && this.state.quizFlag === true ? (
          <div>
            <div style={{ textAlign: "center" }}>{<p>Up Next</p>}</div>
            <CircularProgressBar skill_Id={this.state.skill_Id}
              nextLearningBite={this.state.nextLearningBite} />
           <div style={{ textAlign: "center" }}>
                <div className="cancelBtnBlock" onClick={e => (this.onClickCancel())}>
                  <div>
                    <button>Cancel</button>
                  </div>
                </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    ) : (
      <div className="flashcard-last">
        <div className="img-last-flashcard evaluationslide">
          <img
            className="evaluationIMG"
            src={require("./longSliderImg/thumbsUp2.png")}
            alt="evaluation"
          />
        </div>
        <div className="secondSlidername-last-flashcard" style={{ textAlign: "center" , color: fontColor}}>
          {<p>You have successfully completed the lesson</p>}
        </div>
        {this.state.nextLearningBite === "" || this.state.nextLearningBite === undefined && (
          <div className="submit-btn flex-child">
            <Link to={`/skills/${this.state.skill_Id}`}>
              <button type="" className="btn btn-success active">
                {" "}
                OK
              </button>
            </Link>
          </div>
        )}
        {this.state.nextLearningBite !== "" && this.state.nextLearningBite !== undefined ? (
          <div>
            <div style={{ textAlign: "center" }}>{<p>Up Next</p>}</div>
            <CircularProgressBar skill_Id={this.state.skill_Id}
              nextLearningBite={this.state.nextLearningBite} />
            <div style={{ textAlign: "center" }}>
              <Link to={`/skills/${this.state.skill_Id}`}>
                <div className="cancelBtnBlock">
                  <div>
                    <button>Cancel</button>
                  </div>
                </div>
              </Link>
            </div>
            {/* {this.state.isOptOutAllowed ?
            <div className="submit-btn flex-child">
              <button type="" className="btn btn-success-next active" style={{
                  width: "100%"
                }} onClick={this.handleClick}>
                {" "}
                OptOut Next schedules
              </button>
            </div>
            : */}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }

  getId(url) {
    const regExp =
      /^.*(youtu.be\/|v\/|embed\/|watch\?|youtube.com\/user\/[^#]*#([^\/]*?\/)*)\??v?=?([^#\&\?]*).*/;
    const match = url.match(regExp);

    if (match === null) {
      return url;
    } else if (match && match[3] !== undefined && match[3].length === 11) {
      return `https://www.youtube.com/embed/${match[3]}`;
    }

    return null;
  }

  toggleModal = (e) => {
    this.setState({
      modalOpen: false,
    });
  };

  showModal = (e) => {
    let themeImgPreview = e.target.src;
    this.setState({
      modalOpen: true,
      themeImgPreview: themeImgPreview,
    });
  };

  isPageImggImageTrueHtmlReturn(
    pageText,
    pageImgg,
    pageUrl,
    pageVideo,
    playVideo,
    isActiveSlide,
    fontColor
  ) {
    const videoURL = this.getId(pageVideo);
    return (
      <div className="key_text_video">
        <div
          id=""
          className="secondSlidekey-text"
          style={{
            color: `${fontColor}`,
          }}
          dangerouslySetInnerHTML={this.createMarkup(pageText)}
        />
        <div className="key-link">
          {playVideo ? (
            <Iframe
              url={videoURL}
              playing={false}
              controls={true}
              className="react-player"
            />
          ) : (
            <a
              href={pageVideo}
              color="blue"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img
                src={
                  config.api.server_url.request_url.base_url +
                  "getUploadedFile/" +
                  pageImgg
                }
                alt=""
              />
            </a>
          )}
        </div>
      </div>
    );
  }

  isPageImggTrueHtmlReturn(pageText, pageImage, pageImageUrl, fontColor) {
    return (
      <div className="key_linkisshow">
        <div
          id=""
          className="secondSlidekey-text"
          style={{
            color: `${fontColor}`,
          }}
          dangerouslySetInnerHTML={this.createMarkup(pageText)}
        />
        <div className="key-link">
          {
            <div className="img-wrapper">
              <a
                href={pageImageUrl}
                color="blue"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img
                  src={
                    config.api.server_url.request_url.base_url +
                    "getUploadedFile/" +
                    pageImage
                  }
                  alt=""
                  className="imageurldisplay"
                />
              </a>
            </div>
          }
        </div>
      </div>
    );
  }

  isPageImggFalseHtmlReturn(PageText, pageImage, fontColor) {
    return (
      <div className="textandimagecontainer">
        <div
          id=""
          className="secondSlidekey-text"
          style={{
            color: `${fontColor}`,
          }}
          dangerouslySetInnerHTML={this.createMarkup(PageText)}
        />
        <div className="img-center">
          <div className="image_box"

          >
            <img
              src={
                config.api.server_url.request_url.base_url +
                "getUploadedFile/" +
                pageImage
              }
              alt="img"
              className="allview-img"
              onClick={e => this.showModal(e)}
            />
          </div>
        </div>
      </div>
    );
  }

  clickableImage(pageImage, imageUrl) {
    return (
      <div className="secondslideonlyimage imgwithurl">
        <div className="img-center" style={{maxHeight:"485px"}}>
          {imageUrl ? (
            <a
              href={imageUrl}
              color="blue"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img
                src={
                  config.api.server_url.request_url.base_url +
                  "getUploadedFile/" +
                  pageImage
                }
                alt=""
                className="imageurldisplay"
              />
            </a>
          ) : (
            <img
              src={
                config.api.server_url.request_url.base_url +
                "getUploadedFile/" +
                pageImage
              }
              alt="img"
              className="flashcardImage"
              onClick={e => this.showModal(e)}
            />
          )}
        </div>
      </div>
    );
  }

  clickableImageWithVideo(pageImage, pageImageUrl, pageUrl) {
    const videoURL = this.getId(pageUrl);
    return (
      <div className="keyvideoandimage">
        <div className="keyImageMain">
          {pageImageUrl ? (
            <a
              href={pageImageUrl}
              color="blue"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img
                src={
                  config.api.server_url.request_url.base_url +
                  "getUploadedFile/" +
                  pageImage
                }
                alt=""
                className="imageurldisplay"
              />
            </a>
          ) : (
            <img
              src={
                config.api.server_url.request_url.base_url +
                "getUploadedFile/" +
                pageImage
              }
              alt=""
              className="imageurldisplay"
              onClick={e => this.showModal(e)}

            />
          )}
        </div>

        {pageUrl !== "" && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div className="key-link">
              <Iframe
                url={videoURL}
                playing={false}
                controls={true}
                className="react-player"
              />
            </div>
          </div>
        )}
      </div>
    );
  }

  contentImgVideo(pageText, pageImage, imageUrl, videoUrl, fontColor) {
    const videoURL = this.getId(videoUrl);
    return (
      <div className="key_link_Image wrapper">
        <div className="div key-link" >
          <Iframe
            url={videoURL}
            playing={false}
            controls={true}
            className="react-player-solid"
          />
        </div>
        <div className="div displayImage" >
          <div className="image_box">
            {imageUrl ? (
              <a
                href={imageUrl}
                color="blue"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img
                  className="allview-img"
                  src={
                    config.api.server_url.request_url.base_url +
                    "getUploadedFile/" +
                    pageImage
                  }
                  alt=""
                />
              </a>
            ) : (
              <img
                className="allview-img"
                src={
                  config.api.server_url.request_url.base_url +
                  "getUploadedFile/" +
                  pageImage
                }
                alt=""
                onClick={e => this.showModal(e)}
              />
            )}
          </div>
        </div>
        <div
          id=""
          className="div secondSlidekey-text"
          style={{
            color: `${fontColor}`
          }}
          dangerouslySetInnerHTML={this.createMarkup(pageText)}
        />
      </div>
    );
  }

  onlyVideo(url) {
    const videoURL = this.getId(url);
    return (
      <div style={{ width: "80%", height: "100%"}}>
        <div className="key-link-video">
          <Iframe
            allow="fullscreen"
            url={videoURL}
            // playing={false}
            // controls={true}
            className="react-player-solid"
          />
        </div>
      </div>
    );
  }

  render() {
    var result;
    let topic, fontColor = null;
    let settings;
    let length = 0;
    let pageNumber = 1;
    let isquizFlag = false;
    let lastPageText = "You have successfully completed the lesson";
    if (this.state.sliderpagesData.pages) {
      length = this.state.sliderpagesData.pages.length;
      isquizFlag = this.state.sliderpagesData.isQuiz ? this.state.sliderpagesData.isQuiz : false;
      settings = {
        dots: true,
        arrows: true,
        infinite: false,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              vertical: true,
              verticalSwiping: true,
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              vertical: true,
              verticalSwiping: true,
            }
          }
        ],
        appendDots: dots => {
          return (
            <MagicSliderDots dots={dots} numDotsToShow={5} dotWidth={30} />
          );
        },
        beforeChange: (current, next) => this.setState({ slideIndex: next }),
        afterChange: async current => {
          this.setState({ currentSlide: current });
          let sliderpagesData = this.state.sliderpagesData;
          sliderpagesData.pages.forEach(function (page) {
            page.IsPlay = false;
          });
          // if(current!=0){

          //     sliderpagesData.pages[current-1].IsPlay=false;
          // }

          // sliderpagesData.pages[current].IsPlay=false;
          this.setState(state => ({ updateCount: state.updateCount + 1 }));
          this.setState({ sliderpagesData });
          let fromLocalStorage = 0;
          let percentage = 100 / this.state.sliderpagesData.pages.length;
          let maxProgress = 0;
          let actualPercentage =
            (100 * (current + 1)) / this.state.sliderpagesData.pages.length;
          this.setState({ actualPercentage });
          if (localStorage[this.state.sliderpagesData._id]) {
            fromLocalStorage = parseInt(
              localStorage[this.state.sliderpagesData._id]
            );
          }

          if (this.state.viewedSlideCount <= current) {
            if (actualPercentage >= maxProgress) {
              actualPercentage =
                percentage + percentage * (this.state.progressCounter + 1);
              if (actualPercentage >= fromLocalStorage) {
                localStorage.setItem(
                  this.state.sliderpagesData._id,
                  actualPercentage
                );
              }
              maxProgress = actualPercentage;
              this.setState({
                progressCounter: current + 1,
                viewedSlideCount: current,
              });
            }
          }
          pageNumber = current + pageNumber;
          if (current < this.state.sliderpagesData.pages.length - 1) {
            this.setState(
              {
                isLastPage: false,
              },
              function () {
                if (this.state.sliderpagesData.pages[current].text) {
                  this.setText(this.state.sliderpagesData.pages[current].text);
                } else {
                  this.setText("");
                }
              }
            );
          }
          if (current === this.state.sliderpagesData.pages.length - 1) {
            let user = jwt_decode(localStorage.getItem("token"));
            var data = {
              lessonId: this.props.sliderPages._id,
              userId: user._id,
            };
            this.props.saveUserLesson(data);
            if (!this.state.sliderpagesData.isQuiz && localStorage.getItem("nudgeId") !== undefined && localStorage.getItem("nudgeId") !== null) {
              var nudgeData = {
                lessonId: this.props.sliderPages._id,
                userId: user.username,
                isCompleted: true,
                completionDate: new Date(),
                nudgeId: localStorage.getItem("nudgeId"),
              };
              await this.props.saveUserNudgeLesson(nudgeData);
              //await this.props.getNudge(localStorage.getItem("nudgeId"));
            }
            this.setState(
              {
                isLastPage: true,
              },
              () => {
                if (isquizFlag) {
                  if (this.state.quizFlag) {
                    lastPageText = "You have already taken this assessment recently.You can re-attempt it after 24hrs of your previous attempt.";
                  } else {
                    lastPageText = "Thank you Take an assessment Now";
                  }
                }
                this.setText(
                  this.state.sliderpagesData.pages[current].text
                    .concat(" ")
                    .concat(lastPageText)
                );

              }
            );
          }
        },
        onInit: () => {
          this.setText(this.state.sliderpagesData.pages[0].text);
        },
      };

      result = this.state.sliderpagesData;

      if (result) {
        topic = result;
        // let percentage = 100 / topic.pages.length;
        // if (localStorage[topic._id]) {
        //     actualPercentage = localStorage[topic._id];

        // } else {
        //     actualPercentage = percentage;
        //     localStorage.setItem(topic._id, actualPercentage);
        // }
        if (this.state.sliderpagesData.lessonFontColor) {
          fontColor = this.state.sliderpagesData.lessonFontColor;
        } else {
          fontColor = "#fbeeb8";
        }
      }
    }
    return result ? (
      <div className="secondsliderstart sliderTimline" id="longslider">
        <div
          className="secondsliderwrapper"
          style={{
            color: `${fontColor}`,
          }}
        >
          <div className="background-img" id="background-img">
            <div
              id="interactionBackground"
              style={{
                backgroundImage: `url(${config.api.server_url.request_url.base_url +
                  "getUploadedFile/" +
                  this.props.bg
                  })`,

              }}
              className="inner-img"
              role="img"
              aria-label="Default Placeholder"
            >
            </div>
          </div>

          <div className="hero-slider">
            <div className="slidersecondmain mainSliderBlock">
              <Slider {...settings} ref={slider => (this.slider = slider)}>
                {this.state.sliderpagesData.pages.map((page, index) => {
                  return (
                    <div className="" key={index}>
                      <div>
                        {this.state.isLastPage &&
                          this.state.sliderpagesData.pages.length ===
                          this.state.progressCounter ||
                          this.state.sliderpagesData.pages.length === 1 ? (
                          this.lastpageHtmlReturn(
                            topic._id,
                            this.state.sliderpagesData,
                            fontColor
                          )
                        ) : page.text ? (
                          page.isHtml ? (
                            page.image ? (
                              page.isImageUrl ? (
                                page.isVideo ? (
                                  this.contentImgVideo(
                                    page.text,
                                    page.image,
                                    page.imageUrl,
                                    page.url,
                                    fontColor
                                  )
                                ) : (
                                  this.isPageImggTrueHtmlReturn(
                                    page.text,
                                    page.image,
                                    page.imageUrl,
                                    fontColor
                                  )
                                )
                              ) : page.isVideo ? (
                                this.contentImgVideo(
                                  page.text,
                                  page.image,
                                  page.imageUrl,
                                  page.url,
                                  fontColor
                                )
                              ) : (
                                this.isPageImggFalseHtmlReturn(
                                  page.text,
                                  page.image,
                                  fontColor
                                )
                              )
                            ) : page.url ? (
                              this.isPageImggImageTrueHtmlReturn(
                                page.text,
                                page.imgg,
                                page.image,
                                page.url,
                                page.isVideo,
                                index === this.state.currentSlide,
                                fontColor
                              )
                            ) : (
                              <div className="showonlytextwrapper">
                                <div className="showonlytext">
                                  <div
                                    id=""
                                    className="secondSlidekey-text"
                                    style={{
                                      color: `${fontColor}`,
                                    }}
                                    dangerouslySetInnerHTML={this.createMarkup(
                                      page.text
                                    )}
                                  />
                                </div>
                              </div>
                            )
                          ) : (
                            <div className="blockchaintext">
                              <div
                                className="secondslidertxt-center"
                                style={{
                                  color: `${fontColor}`,
                                }}
                              >
                                <p>{page.text}</p>
                              </div>
                            </div>
                          )
                        ) : page.image ? (
                          page.isImageUrl ? (
                            page.isVideo ? (
                              this.clickableImageWithVideo(
                                page.image,
                                page.imageUrl,
                                page.url
                              )
                            ) : (
                              this.clickableImage(page.image, page.imageUrl)
                            )
                          ) : page.isVideo ? (
                            this.clickableImageWithVideo(
                              page.image,
                              page.imageUrl,
                              page.url
                            )
                          ) : (
                            this.clickableImage(page.image, page.imageUrl)
                          )
                        ) : page.isVideo ? (
                          page.url ? (
                            this.onlyVideo(page.url)
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  );
                })}
              </Slider>
              {/*Theme Modal*/}
              <Modal
                className="custom_model"
                isOpen={this.state.modalOpen}
                toggle={e => this.toggleModal(e)}
              >
                <ModalHeader toggle={e => this.toggleModal(e)}></ModalHeader>
                <ModalBody className="custom_model_body">
                  <img
                    className="modalThemeImg"
                    src={this.state.themeImgPreview}
                    alt="Img"
                  />
                </ModalBody>
              </Modal>
            </div>
          </div>
          <div
            className={this.state.mouseMoving ? "backButton" : "visibility"}
            style={{ width: "100%", margin: "0 auto" }}
          >
            <Link to={`/skills/${this.state.skill_Id}`}>
              <div className="backBtnBlock">
                <img
                  src={require("../../../images/backgroundImages/home.png")}
                  alt=""
                />
                <div>
                  <button style={{ marginLeft: "5px", marginTop: "5px" }}>
                    Home
                  </button>
                </div>
              </div>
            </Link>
          </div>
        </div>
        <div
          className={this.state.mouseMoving ? "stepper" : "visibility"}
          style={{ width: "100%", margin: "0 auto" }}
          id="stepper"
          name="stepper"
        >
          <div
            className={this.state.mouseMoving ? "stepperBlock" : "visibility"}
            id="stepperBlock"
          >
            <div className="stepperLable">
              {this.state.slideIndex + 1 <= 9 ? (
                <strong style={{ fontSize: "25px" }}>{`0${
                  this.state.slideIndex + 1
                }`}</strong>
              ) : (
                <strong style={{ fontSize: "25px" }}>{`${
                  this.state.slideIndex + 1
                }`}</strong>
              )}{" "}
              Out of {`${this.state.sliderpagesData.pages.length}`}{" "}
            </div>
          </div>
          <div className="speakerIcon">
            <TextToSpeech
              voiceText={this.state.voiceText}
              slideNumber={this.state.slideIndex}
            />
          </div>
          <div className="fullscreenBlock">
            {!this.state.isFullScreen ? (
              <button onClick={this.openFullscreen}>
                {" "}
                <img src={require("../expand.png")} alt="" />
              </button>
            ) : (
              <button onClick={this.closeFullscreen}>
                <img src={require("../collapse.png")} alt="" />
              </button>
            )}
          </div>
        </div>
      </div>
    ) : (
      <div />
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getQuizData,
    },
    dispatch
  );
}
const mapStateToProps = (state) => {
  return {
    quizData: state.questionReducer.quizData,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SimpleSlider);
