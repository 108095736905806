import * as actionTypes from './skillActionTypes';
import axios from 'axios';
import config from "./../config/config";
import jwt_decode from "jwt-decode";

export function getSkills(url) {

    return function (dispatch) {
        let user = jwt_decode(localStorage.getItem("token"));
        return axios.get(config.api.server_url.request_url.base_url + url, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token'),
                Username : user.username,
            }
        }).then((res) => {
            dispatch({ type: actionTypes.FETCH_SKILL_SUCCESS, payload: res.data });
        })
    };
}

export function getUnassignedSkillsData(skillId) {
    return async (dispatch) => {
        const res = await axios.get(config.api.server_url.request_url.assigned_url + skillId,{
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        });
        dispatch({ type: actionTypes.FETCH_UNASSIGNED_SKILLS_SUCCESS, payload: res.data });
    };
}
