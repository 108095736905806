import * as actionTypes from './ratingActionTypes';
import axios from 'axios';
import config from './../config/config';
import {toast} from 'react-toastify';
toast.configure();
const TOAST_CONFIGURATION = {
    autoClose: 2000,
    hideProgressBar: true,
    position: "top-right"
  };

function AddRating(experiences) {
    return async function (dispatch) {
        const res = await axios.post(config.api.server_url.request_url.post_rating, experiences, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        }).then((res) => {
            toast.success("Feedback submitted successfully", TOAST_CONFIGURATION);
            dispatch({ type: actionTypes.CREATE_RATING_SUCCESS, payload: res.data });
        }).catch(error => {
            toast.error("Failed to save the feedback", TOAST_CONFIGURATION);
        });

    };
}

export default AddRating;