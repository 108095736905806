/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { getUserProfile } from "./profileAction";
import { bindActionCreators } from "redux";
import { css } from "@emotion/core";
import Breadcrumbs from "../../Breadcrumbs";
import SyncLoader from "react-spinners/SyncLoader";
import Avatar from "react-avatar";
import moment from "moment";
import jwt_decode from "jwt-decode";

const override = css`
  display: block;
  margin: 200px auto;
  width: 100px;
  padding: "25%";
`;

class MyProfile extends Component {
    constructor(props) {
        super(props);
        
        this.props.homeVisibility(true);
        this.state = {
            loading: false,
        };
    }

    componentDidMount() {
        let userId = "";
        let user = jwt_decode(localStorage.getItem("token"));
        if (user._id) {
            userId = user._id;
          }
        this.props.getUserProfile(userId);
    }
    componentDidUpdate(prevProps) { 
        const { userDetails } = this.props;
        if (prevProps.userDetails !== userDetails) {
          this.setState({ loading: false });
        }
    }

    render() {
        let { name, role, email, oragnizationName, domain, last_login } = this.state;

        if (this.props.userDetails) {
          
            if (this.props.userDetails.data) {
                name = this.props.userDetails.data.user
                ? this.props.userDetails.data.user.userId.firstName +
                    " " +
                    this.props.userDetails.data.user.userId.lastName
                    : "";
                role = this.props.userDetails.data.user
                    ? this.props.userDetails.data.user.roleId.role_name
                    : "";
                email = this.props.userDetails.data.user
                    ? this.props.userDetails.data.user.userId.username
                    : "";
                oragnizationName = this.props.userDetails.data.orgDetails
                    ? this.props.userDetails.data.orgDetails.organizationName
                    : "";
                domain = this.props.userDetails.data.orgDetails
                    ? this.props.userDetails.data.orgDetails.organizationDomain
                    : "";
                last_login = this.props.userDetails.data.login
                    ? moment(this.props.userDetails.data.login.timestamp).format(
                        "DD-MMM-YYYY HH:mm:ss A"
                    )
                    : "-";

                  
            }
        }

        return (
            // Name | Email ID | User Role | Organization Name | Organization Domain | Last Login (MM:DD:YY , Timestamp with time-zone EST, PST, GMT )
            <div>
                <div>
                    {this.state.loading ? (
                        <SyncLoader
                            css={override}
                            size={20}
                            color={"#0b6eb6"}
                            loading={this.state.loading}
                        />
                    ) : (
                        <div className="dashboard-margin">
                            <Breadcrumbs />
                            <div className="courses-wrapper" id="effect-content">
                                <div className="profile-container">
                                    <div className="row">
                                        <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                            <div class="profile-left">
                                                <div class="emp-img">
                                                    <Avatar
                                                        size="130"
                                                        textSizeRatio={1.0}
                                                        round={true}
                                                        className="sb-avatar"
                                                        name={name}
                                                    />
                                                </div>
                                                <br></br>
                                                <div class="emp-name">{name}</div>
                                            </div>
                                        </div>
                                        <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                                            <div class="row"></div>
                                            <div class="profile-right">
                                                <div class="row border-line">
                                                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-5 col-5 label-text font-weight-bold">
                                                        Email ID
                                                    </div>
                                                    <div class="col-xl-8 col-lg-8 col-md-8 col-sm-7 col-7 value-text ">
                                                        {email}
                                                    </div>
                                                </div>
                                                <div class="row border-line">
                                                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-5 col-5 label-text font-weight-bold ">
                                                        Role
                                                    </div>
                                                    <div class="col-xl-8 col-lg-8 col-md-8 col-sm-7 col-7 label-text">
                                                        {role}
                                                    </div>
                                                </div>

                                                <div class="row border-line">
                                                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-5 col-5 label-text font-weight-bold ">
                                                        Organization Name
                                                    </div>
                                                    <div class="col-xl-8 col-lg-8 col-md-8 col-sm-7 col-7 label-text">
                                                        {oragnizationName}
                                                    </div>
                                                </div>
                                                <div class="row border-line">
                                                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-5 col-5 label-text font-weight-bold ">
                                                        Organization Domain
                                                    </div>
                                                    <div class="col-xl-8 col-lg-8 col-md-8 col-sm-7 col-7 label-text">
                                                        {domain}
                                                    </div>
                                                </div>
                                                <div class="row border-line">
                                                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-5 col-5 label-text font-weight-bold ">
                                                        Last Login
                                                    </div>
                                                    <div class="col-xl-8 col-lg-8 col-md-8 col-sm-7 col-7 label-text">
                                                        {last_login}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getUserProfile: getUserProfile,
        },
        dispatch
    );
}
const mapStateToProps = (state) => {
    return {
        userDetails: state.profileReducer.userDetails,
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);
