import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getSkills } from "./allSkillsAction";
import config from "../../config/config";
import DefaultImg from '../../lessons/lessonImg/default_img_upload.png';
import outlook from './../outlook.scss';

import { css } from "@emotion/core";
import SyncLoader from "react-spinners/SyncLoader";

const override = css`
  display: block;
  margin: 200px auto;
  width: 100px;
  padding: '25%'
`;

class AllSkills extends Component {
    constructor(props) {
        super(props);
        this.props.homeVisibility(false);
        this.state = {
            loading: false,
            showMore: false,
            show: false
        };
    }

    componentDidMount = async () => {
        if (!localStorage.getItem("mail")) {
           // alert("User Not Logged In!!!");
            this.props.history.push("/");
        }

        this.setState({ loading: true })
        await this.props.getSkills("outlook-skills");

        if (this.props.skillListData) {
            let loadingTime = this;
            setTimeout(function () {
                loadingTime.setState({ loading: false });
            }, 200);
        }

        window.speechSynthesis.cancel();
    };

    handleShowMore = (event) => {
        this.setState({ show: !this.state.show });
        event.stopPropagation();
        event.preventDefault();

    };

    render() {

        return (
            <div className="page-container quest-wrapper outlook-quest-wrapper">
                <div className="container-fluid mob-container">
                    {this.state.loading ?
                        <SyncLoader
                            css={override}
                            size={20}
                            color={"#0b6eb6"}
                            loading={this.state.loading} />
                        : <div className="main-container">
                            <div className="row">
                                <h2 className="outlook-page-title">
                                    <span>All Nuggets (
                                        {this.props.skillListData.data
                                            ? this.props.skillListData.data.length
                                            : 0
                                        })
                                    </span>
                                </h2>
                            </div>
                            <div className="row">
                                {this.props.skillListData.data
                                    ? this.props.skillListData.data.map((item, index) =>

                                        /*------------------------------------------------------------------------------*/
                                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 p-2">
                                            <div className="main-div">
                                                <div className="card custom-card">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                                                {
                                                                    item.image ? <img
                                                                        src={config.api.server_url.request_url.base_url + "getUploadedFile/" + item.image}
                                                                        className="nudge_image"
                                                                        alt={item.name} />
                                                                        : <img
                                                                            className="nudge_image"
                                                                            src={DefaultImg}
                                                                            alt='Img' />
                                                                }

                                                            </div>
                                                            <div className="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8">
                                                                <div className="card-txt">
                                                                    <h3>
                                                                        <span>{item.name}</span>
                                                                    </h3>
                                                                    <div>
                                                                        {
                                                                            item.description.length > 100 && !this.state.show ?
                                                                                <span> <span>{item.description.substring(0, 100)}</span>
                                                                                    <span className="showmore"
                                                                                        onClick={this.handleShowMore}> More&gt;&gt;</span>
                                                                                </span>
                                                                                : <span> <span>{item.description}</span>
                                                                                    {item.description.length > 100 ?
                                                                                        <span className="showmore"
                                                                                            onClick={this.handleShowMore}> Less&lt;&lt;</span>
                                                                                        : null}
                                                                                </span>
                                                                        }

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/*<div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                                                    <i className="fa fa-angle-right fa-3x pull-right"
                                                                       aria-hidden="true"/>
                                                                </div>*/}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        /*------------------------------------------------------------------------------*/
                                    )
                                    : ""}
                            </div>
                        </div>}
                </div>
            </div>

        );
    }


}


function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getSkills: getSkills,
        },
        dispatch
    );
}

const mapStateToProps = state => {
    return {
        skillListData: state.allSkillsReducer.skillListData || {}
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AllSkills);