import React, {Component} from 'react';
import ActiveUser from "./ActiveUser";
import "../landingPage/login.css"
import platoLogo from "../../images/brandImages/plato-logo-footer.png";

class Footer extends Component {
    componentDidMount() {
        ActiveUser();
    }

    render() {
        return (
            <footer>
                <div className="main-heading">
                    <div className='footer-logo'>
                        <img className='footer-logo-image' src={platoLogo} alt='SprinkleZone Logo'></img>
                    </div>
                    <div className="footer-text">
                    <span>Copyright © 2023 Harbinger SprinkleZone. All rights reserved.</span>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;
