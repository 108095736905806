import React, {Component} from "react";
import {loginOutlookService} from "../../landingPage/login-service";
import axios from "axios";

class OutlookLogin extends Component {
    constructor(props) {
        super(props);
  }

    componentDidMount = async () => {

        let urlParams = new URLSearchParams(window.location.search);
        let authToken = urlParams.get('code');
        let userName = "";
        if (authToken) {
            const data = {
                "authtoken" : authToken
            };
            axios({
                method: 'post',
                url: process.env.REACT_APP_API_URL + 'login',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: {data}
            }).then(res => {
                userName = res.data.meta.userid;
                const data = loginOutlookService(userName, authToken);
                axios({
                    method: 'post',
                    url: process.env.REACT_APP_API_URL + 'checkToken',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: {data}
                }).then(res => {
                    localStorage.setItem('token', res.data.data.token);
                    // localStorage.setItem('userId', res.data.data.userId);
                    // localStorage.setItem('userRole', res.data.data.user_role);
                    localStorage.setItem('organization', res.data.data.organization.organizationName);
                    if (res.data.data.organization) {
                        localStorage.setItem('organizationLogoId', res.data.data.organization.organizationLogoId);
                        localStorage.setItem('headerColor', res.data.data.organization.headerColor);
                        localStorage.setItem('unameFont', res.data.data.organization.fontColor);
                        localStorage.setItem('organizationMobileLogoId', res.data.data.organization.organizationMobileLogoId);

                    } else {
                        localStorage.setItem('organizationLogoId', "");
                        localStorage.setItem('headerColor', "");
                    }

                    if (localStorage.getItem('token')) {
                        if (res.data.data.userId) {
                            this.userIdforTokenMapping(res.data.data.userId);
                        }
                        this.props.history.push('/outlook-home');
                    }
                })
            }).catch(error => {
                document.getElementById("error").innerHTML = "<font color='red'><b><h4>" + error.errors.message + "</h4></b></font>"
            });
        } else {
            document.getElementById("error").innerHTML = "<font color='red'><b><h4> UnAuthorize Account! </h4></b></font>"
        }
    }

    userIdforTokenMapping = async (userId) => {
        let data = {
            token: localStorage.getItem("notificationToken"),
            userId: userId
        }
        if (data.token === null) return;
        await axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_URL}notification/updateUserIdForToken`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: {data}
        }).then((res) => {

        })
    }


    render() {

        return (
            <div style={{textAlign:'center',lineHeight:'30'}}>
                <div id="error" color="danger"></div>
            </div>
        );
    }
}

export default OutlookLogin;