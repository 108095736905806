import * as actionTypes from './signUpPageActionType';

const signUpPageReducer = (state = {}, action) => {
    switch (action.type) {

        case actionTypes.CREATE_USER_SUCCESS:
            return Object.assign({}, state, {
                userCreate: action.payload,
            });

        case actionTypes.CREATE_USER_FAILURE:
            return Object.assign({}, state, {
                userCreate: action.payload,
            });

            case actionTypes.CREATE_USER_ALREADY_EXIST:
            return Object.assign({}, state, {
                userCreate: action.payload,
            });

            case actionTypes.FETCH_ALL_ORGANIZATION_SUCCESS:
            return Object.assign({}, state, {
                organizationList: action.payload,
            });

            case actionTypes.FETCH_ALL_ORGANIZATION_FAILURE:
            return Object.assign({}, state, {
                organizationList: action.payload,
            });

        default:
            return state;
    }
}
export default signUpPageReducer;
