import { combineReducers } from 'redux';

import lessonReducer from './containers/lessons/lessonReducer';
import topicReducer from './containers/topics/topicsReducers';
import questionReducer from './containers/quiz/quizReducers';
import ratingReducer from './containers/rating/ratingReducers';
import skillReducer from './containers/skills/skillReducer';
import headerReducer from './containers/Header/headerReducer';
import signUpPageReducer from './containers/signUpPage/signUpPageReducer';
import dashboardReducer from './containers/learnerDashboard/dashboardReducer';
import reportReducer from './containers/reports/reportReducer'
import allSkillsReducer from './containers/outlook/allSkills/allSkillsReducer';
import outlookHeaderReducer from './containers/outlook/outlookHeader/outlookHeaderReducer';
import completedSkillsReducer from './containers/outlook/completedSkills/completedSkillsReducer';
import pendingSkillsReducer from './containers/outlook/pendingSkills/pendingSkillsReducer';
import reportsReducer from './containers/outlook/reports/reportsReducer';
import outlookSkillReducer from './containers/outlook/outlookFeedback/outlookFeedbackReducer';
import recentlyAddedSkillReducer from './containers/outlook/recentlyAddedSkills/recentlyAddedSkillsReducer';
import popularSkillReducer from './containers/outlook/popularSkills/popularSkillsReducer';
import profileReducer from './containers/profile/profileReducer';

const rootReducer =  combineReducers({
    lessonReducer,
    topicReducer,
    questionReducer,
    ratingReducer,
    skillReducer,
    headerReducer,
    signUpPageReducer,
    dashboardReducer,
    reportReducer,
    allSkillsReducer,
    outlookHeaderReducer,
    completedSkillsReducer,
    pendingSkillsReducer,
    reportsReducer,
    outlookSkillReducer,
    recentlyAddedSkillReducer,
    popularSkillReducer,
    profileReducer
});
  
export default rootReducer
