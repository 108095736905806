import * as actionTypes from './lessonActionTypes';

const lessonReducer = (state = {}, action) => {
    switch (action.type) {
        case actionTypes.FETCH_LESSON_SUCCESS:
            return Object.assign({}, state, {
                result: action.payload,
            });
        case actionTypes.FETCH_NUDGE_SUCCESS:
            return Object.assign({}, state, {
                nudge: action.payload,
            });    
        default:
            return state;
    }
}
export default lessonReducer;