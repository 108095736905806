import * as actionTypes from './completedSkillsActionType';

const allSkillsReducer = (state = {}, action) => {
    switch (action.type) {
        case actionTypes.FETCH_COMPLETED_SKILLS_SUCCESS:
            return Object.assign({}, state, {
                completeSkillsData: action.payload,
            });

            case actionTypes.FETCH_COMPLETED_SKILLS_FAILURE:
            return Object.assign({}, state, {
                completeSkillsData: action.error,
            });
        default:
            return state;
    }
}
export default allSkillsReducer;