import * as actionTypes from './reportActionTypes';

const reportReducer = (state = {}, action) => {

    switch (action.type) {

          case actionTypes.FETCH_USER_REPORT_SUCCESS:
            return Object.assign({}, state, {
                userReports: action.payload,
            });

            case actionTypes.FETCH_USER_REPORT_FAILURE:
            return Object.assign({}, state, {
                userReports: action.payload,
            });

            
            
         default:
            return state;
    }
}
export default reportReducer;