import React, {Component} from "react";
import {connect} from "react-redux";
import { get, isEmpty } from "lodash";
import {bindActionCreators} from "redux";
import {Modal, ModalBody, ModalHeader, Button} from 'reactstrap';
import {confirmAlert} from "react-confirm-alert";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {getPopularSkills} from "./popularSkillsAction";
import { getContactReasons,saveContactDetails, resetSaveContactDetails } from "../../Header/headerAction";
import config from "../../config/config";
import DefaultImg from '../../lessons/lessonImg/default_img_upload.png';

import {css} from "@emotion/core";
import SyncLoader from "react-spinners/SyncLoader";

toast.configure();

const TOAST_CONFIGURATION = {
  autoClose: 2000,
  hideProgressBar: true,
  position: "bottom-right"
};

const override = css`
  display: block;
  margin: 200px auto;
  width: 100px;
  padding: '25%'
`;

class PopularSkills extends Component {
  constructor(props) {
    super(props);
    this.props.homeVisibility(false);
    this.state = {
        loading: false,
        requestModal: false,
        skillRequestModal: false,
        selectedSkill: {},
    };
  }

  componentDidMount = async () => {
    if (!localStorage.getItem("mail")) {
       // alert("User Not Logged In!!!");
        this.props.history.push("/");
    }

    this.setState({loading: true});
    await this.props.getContactReasons();
    await this.props.getPopularSkills();
    if (this.props.popularSkillsData) {
        let loadingTime = this;
        setTimeout(function () {
            loadingTime.setState({loading: false});
        }, 200);
    }
    window.speechSynthesis.cancel();
  };

  requestToggle = (e, item = {}) => {
    this.setState({
      requestModal :!this.state.requestModal,
      selectedSkill: item
    });
  }

  sendNuggetRequest = () => {
    const { getContactReasonsData, saveContactDetails } = this.props;
    const { selectedSkill } = this.state;

    const reason = getContactReasonsData.find(x => x.reason === 'Request to Assign Nugget')
    let contactDetails = {
        contactReason: get(reason, '_id', ''),
        description: get(selectedSkill, 'skill_name.0', ''),
    }
    saveContactDetails(contactDetails);
  }

  skillRequestModal = () => {
    this.setState({
      skillRequestModal :!this.state.skillRequestModal
    }, () => {
      if(this.state.skillRequestModal) {
        this.setState({
          requestModal :!this.state.requestModal
        });
        confirmAlert({
          closeOnClickOutside: false,
          customUI: ({ onClose }) => {
            return (
              <div className='custom-ui'>
                <h1>Are you sure?</h1>
                <p>{`You want to request for "${get(this.state, 'selectedSkill.skill_name', '')}" nugget?`}</p>
                <button onClick={() => {
                    this.sendNuggetRequest()
                    onClose()
                }}>Yes</button>
                <button onClick={onClose}>No</button>
              </div>
            )
          }
        });
      }
    });
  }

  render() {
    const { resetSaveContactDetails } = this.props;
    if(!isEmpty(get(this.props, "saveContactData", null))) {
      if (get(this.props, "saveContactData.meta.success", false)) {
        toast.success("Request subbmitted successfully. Admin will approve your request shortly.", TOAST_CONFIGURATION);
      } else {
        toast.error(get(this.props, "saveContactData.meta.message", "Something went wrong. Please try again."), TOAST_CONFIGURATION);
      }
      resetSaveContactDetails();
    }
    return (
      <div className="page-container quest-wrapper outlook-quest-wrapper">
        <div className="container-fluid mob-container out-look-recent-section">
          {
            this.state.loading ?
              <SyncLoader
                css={override}
                      size={20}
                      color={"#0b6eb6"}
                  loading={this.state.loading}
                />
            : 
              <div className="main-container">
                <div className="row">
                  <h2 className="outlook-page-title">
                    <span> Popular Nuggets (
                      {this.props.popularSkillsData
                          ? this.props.popularSkillsData.length
                          : 0
                      })
                    </span>
                  </h2>
                </div>
                <div className="row scroll-container-inner">
                  {
                    this.props.popularSkillsData
                      ? 
                        this.props.popularSkillsData.length > 0
                          ? 
                            this.props.popularSkillsData.map((item, index) => {
                              return (
                                item.assigned ? (
                                  /*------------------------------------------------------------------------------*/
                                  <div key={index} className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 p-2">
                                    <div className="main-div">
                                        <div className="card custom-card">
                                          <div className="card-body">
                                            <div className="row">
                                             
                                              <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                              
                                                {
                                                  item.skill_icon 
                                                    ? 
                                                      <img
                                                        src={config.api.server_url.request_url.base_url + "getUploadedFile/" + item.skill_icon}
                                                        className="nudge_image"
                                                        alt={item.skill_name}
                                                      />
                                                    : 
                                                      <img
                                                        className="nudge_image"
                                                        src={DefaultImg}
                                                        alt='Img'
                                                      />
                                                }
                                              </div>
                                              <div className="col-7 col-sm-7 col-md-7 col-lg-7 col-xl-9">
                                              <div className="nugget-desc" key={index}>
                                                <div className="nuggets_name_show" title={item.skill_name}>
                                                  <span className="nuggets-title">{item.skill_name}</span>
                                                </div>
                                            
                                                <div className="nugget_description" title={item.skill_description}>
                                                  <span className="nuggets-text" title={item.skill_description}>{item.skill_description}</span>
                                                </div>
                                              </div>
                                            
                                              </div>
                                                <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                               { 
                                                item.isNewNugget  
                                                  ? 
                                                    <div className="new_flag">
                                                      <img 
                                                        src={ require("../../learnerDashboard/dashboardImg/new-orange.png") }
                                                        title="New Nugget" 
                                                        alt="New Nugget" 
                                                      />
                                                    </div> 
                                                  : 
                                                    item.isPrivateNugget ? (
                                                      <div className="new_flag">
                                                        <i className="fa fa-unlock-alt lock-green" aria-hidden="true"  title="Assigned Nugget"></i>
                                                        {/* <img src={require("./dashboardImg/flag-green.png")} title="Assigned Nugget"/> */}
                                                      </div>
                                                    ) : ""
                                              }
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                  </div>
                                  /*------------------------------------------------------------------------------*/
                                ) 
                                : (
                                  <div key={index} className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 p-2" onClick={e => this.requestToggle(e, item)}>
                                    <div className="main-div">
                                        <div className="card custom-card">
                                          <div className="card-body">
                                            <div className="row">
                                           
                                              <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                              
                                                {
                                                  item.skill_icon 
                                                    ? 
                                                      <img
                                                        src={config.api.server_url.request_url.base_url + "getUploadedFile/" + item.skill_icon}
                                                        className="nudge_image"
                                                        alt={item.skill_name}
                                                      />
                                                    : 
                                                      <img
                                                        className="nudge_image"
                                                        src={DefaultImg}
                                                        alt='Img'
                                                      />
                                                }
                                              </div>
                                               <div className="col-7 col-sm-7 col-md-7 col-lg-7 col-xl-9">
                                              <div className="nugget-desc" key={index}>
                                                <div className="nuggets_name_show" title={item.skill_name}>
                                                  <span className="nuggets-title">{item.skill_name}</span>
                                                </div>
                                            
                                                <div className="nugget_description" title={item.skill_description}>
                                                  <span className="nuggets-text">{item.skill_description}</span>
                                                </div>
                                              </div>
                                          
                                              </div>
                                               <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                                     { item.isNewNugget 
                                                ? <div className="new_flag">
                                                    <img 
                                                      src={ require("../../learnerDashboard/dashboardImg/new-orange.png") }
                                                      title="New Nugget" 
                                                      alt="New Nugget" 
                                                    />
                                                  </div> 
                                                : <div className="categoryPrivate">
                                                    <i className="fa fa-lock lock-red" aria-hidden="true"  title="Private Nugget"></i>
                                                </div>
                                              }
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                  </div>
                                )
                              )
                            }
                            )
                          : 
                            "" 
                      : 
                        ""
                  }
                </div>
              </div>
          }
        </div>
        <Modal className="request_model"
            isOpen={this.state.requestModal}
            toggle={this.requestToggle}>
            <ModalHeader toggle={this.requestToggle}>
              Request Nugget
            </ModalHeader>
            <ModalBody className="request_model_body">
                This nugget is not assigned to you. 
                You can raise request to admin<br/>
                <Button color="primary" onClick={this.skillRequestModal}>click here</Button>
            </ModalBody>
        </Modal>
      </div>
    );
  }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getPopularSkills,
            getContactReasons,
            saveContactDetails,
            resetSaveContactDetails,
        },
        dispatch
    );
}

const mapStateToProps = state => {
    return {
        popularSkillsData: state.popularSkillReducer.popularSkillsData || null,
        getContactReasonsData: state.headerReducer.getContactReasonsData || null,
        saveContactData:state.headerReducer.saveContactData || null,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PopularSkills);