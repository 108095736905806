import React, { Component } from "react";
import Slider from "react-slick";
import MagicSliderDots from 'react-magic-slider-dots';
import config from '.././config/config'
import { Link } from 'react-router-dom';
import {Modal, ModalBody, ModalHeader} from 'reactstrap';
import DefaultImg from '../lessons/lessonImg/default_img_upload.png';

export default class PopularNuggets extends Component {
   constructor(props) {
        super(props);
        this.props.setActiveClass('dashboard');
        this.state = {
            allNuggetsData:this.props.allPopularNuggetsData,
            requestModal:false
           }
      
    }
   
    requestToggle =()=> {
        this.setState({
            requestModal :!this.state.requestModal
        })
    }

    render() {
        if(this.state.allNuggetsData){
              var settings = {
            dots: true,
            arrows: true,
            infinite: false,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll:4,
            appendDots: (dots) => {
                return <MagicSliderDots dots={dots} numDotsToShow={5} dotWidth={30} />
            },
            responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 768,
          settings:{
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
        };
        }
      
        return (
            <div>
            <Slider {...settings}>
                {this.state.allNuggetsData.map((nudge, index) => {
                 return (
                   nudge.assigned ?(
                       <Link key={index}
                              to={{
                                pathname: `/skills/${nudge._id}`,
                                 icon_id: nudge.skill_icon,
                                skill_name:nudge.skill_name
                              }}
                              onClick={e => this.props.setActiveClass('skills')}>

                        <div className="recent-nuggets" key={index}>
                          {nudge.isNewNugget ?<div className="new_flag">
                               <img src={require("./dashboardImg/new-orange.png")} title="New Nugget" alt="" /></div> :
                               nudge.isPrivateNugget ?(<div className="new_flag">
                                   <i className="fa fa-unlock-alt lock-green" aria-hidden="true"  title="Assigned Nugget"></i></div>):""
                               }
                        <div className="nugget-img">
                        {
                          nudge.skill_icon && nudge.skill_icon!="" ? <img
                            src={config.api.server_url.request_url.base_url + "getUploadedFile/" + nudge.skill_icon}
                              alt = ""/>
                            : <img
                            src={DefaultImg}
                            alt=""/>
                        }
                        </div>
                        <div className="nugget-desc">
                            <div className="popular_skill_name" title={nudge.skill_name}>
                              <span className="nuggets-title">{nudge.skill_name}</span>
                              </div>
                             <div className="viewed_by"> 
                               <span style={{"color":"#28a745"}}> Attempted by:</span><span className="reviewd_by"> {nudge.count}</span>
                               </div>
                        <div className="popular_nugget_dec" title={nudge.skill_description}><span className="nuggets-text">{nudge.skill_description}</span></div>
                        </div>
                    </div>
                    </Link>
                   ):
                        <div key={index}>
                        <div className="recent-nuggets" key={index} onClick={this.requestToggle}>
                             {nudge.isNewNugget ?<div className="new_flag">
                               <img src={require("./dashboardImg/new-orange.png")} title="New Nugget" alt=""/></div> :
                                  <div className="categoryPrivate">
                                        <i className="fa fa-lock lock-red" aria-hidden="true"  title="Private Nugget"></i>
                                  </div>
                                   }
                        <div className="nugget-img">
                        {
                          nudge.skill_icon && nudge.skill_icon!="" ? <img
                            src={config.api.server_url.request_url.base_url + "getUploadedFile/" + nudge.skill_icon}
                              alt = ""/>
                            : <img
                            src={DefaultImg}
                            alt=""/>
                        }
                        
                        </div>
                        <div className="nugget-desc">
                            <div className="popular_skill_name" title={nudge.skill_name}>
                              <span className="nuggets-title">{nudge.skill_name}</span>
                              </div>
                             <div className="viewed_by"> 
                               <span style={{"color":"#28a745"}}>Attempted by:</span><span className="reviewd_by"> {nudge.count}</span>
                               </div>
                        <div className="popular_nugget_dec" title={nudge.skill_description}><span className="nuggets-text">{nudge.skill_description}</span></div>
                        </div>
                    </div>
                    </div>
                        );
                    })
                    }
                </Slider>
                 <Modal className="request_model"
                            isOpen={this.state.requestModal}
                            toggle={e => this.requestToggle(e)}>
                            <ModalHeader toggle={e => this.requestToggle(e)}>
                            Note
                            </ModalHeader>
                            <ModalBody className="request_model_body">
                                This nugget is not assigned to you. 
                                You can raise request to admin<br/>
                                Please click on Contact Admin menu.
                        </ModalBody>
                       </Modal>
            </div>
        );
    }
}