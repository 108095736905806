import React, {Component} from 'react';
import {connect} from "react-redux";
import {getTopicsByLessonId, postUserLesson} from "./topicsActions";
import 'react-magic-slider-dots/dist/magic-dots.css';
import NullView from "./NullView";
import shortid from "shortid";
import { css } from "@emotion/core";
import SyncLoader from "react-spinners/SyncLoader";



const override = css`
  display: block;
  margin: 200px auto;
  width: 100px;
  padding: '25%'
`;


class TopicsComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            componentName:"",
            component:"",
            loading: false
        }
    }


    async componentDidMount() {
        if (!localStorage.getItem('token')) {
           // alert("User Not Logged In!!!");
            this.props.history.push('/');
        }
        
        this.setState({loading: true})
        const {dispatch} = this.props;
        await dispatch(getTopicsByLessonId(this.props.match.params.id));
        const {result} = this.props;
        if (!this.props.location.lessonsTheme && this.props.result && this.props.result.data && this.props.result.data.component) {
            localStorage.setItem("lessonsTheme", this.props.result.data.component.name);
        }

        this.setState({componentName : (this.props.location.lessonsTheme ?
                this.props.location.lessonsTheme : localStorage.getItem("lessonsTheme"))},()=>{
            import(`../SliderThemes/${this.state.componentName}/${this.state.componentName}.js`)
                .then(Component => {
                    this.setState({
                        component:
                            <Component.default
                                key={shortid.generate()}
                                sliderPages={result.data}
                                saveUserLesson={data => this.saveUserLesson(data)}
                                id={this.props.match.params.skill_id}
                                bg={this.props.result.data.backgroundImage ? this.props.result.data.backgroundImage._id : ""}
                            />
                    });
                })
                .catch(error => {
                this.setState({
                        component: (
                            <NullView key={shortid.generate()} />
                        )
                    });
                });
        })

        if(this.props.result) {
            this.setState({loading: false})
        }
    }

    async saveUserLesson(data) {
        const {dispatch} = this.props;
        await dispatch(postUserLesson(data));
    }

    render() {
     
        const { component } = this.state;
        return ( <div className="views" id="views">

            {component === "" ? (
                <SyncLoader
                    css={override}
                    size={20}
                    color={"#0b6eb6"}
                    loading={this.state.loading} />
        ) : (
                component
            )
            }
        </div>)
    }
}

const mapStateToProps = state => {
    let result = {};
    if (state.topicReducer.result !== undefined) {
        result = state.topicReducer;
        return result;
    } else {
        return {result};
    }
};

export default connect(mapStateToProps)(TopicsComponent);


