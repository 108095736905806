import * as actionTypes from './reportsActionType';
import axios from 'axios';
import config from "../../config/config";

export function getUserSkillsReports() {
    return function (dispatch) {
        return axios.get(config.api.server_url.request_url.base_url + "/getUserSkillsReports/" + localStorage.getItem('mail'), {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('mail')
            }
        }).then((res) => {

            if (res.data) {
                dispatch({ type: actionTypes.FETCH_SKILLS_REPORTS_SUCCESS, payload: res.data.data });
            } else {
                alert("something went wrong...");

            }
        }).catch(error => {
            alert(JSON.stringify(error.error));
            dispatch({ type: actionTypes.FETCH_SKILLS_REPORTS_FAILURE, error: error });
        });
    };
}


export function getUserRecentPendingSkillsData() {

    return function (dispatch) {
        return axios.get(config.api.server_url.request_url.base_url + "/getRecentPendingSkills/" + localStorage.getItem('mail'), {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('mail')
            }
        }).then((res) => {

            if (res.data) {
                dispatch({ type: actionTypes.FETCH_USER_PENDING_SKILLS_SUCCESS_OUTLOOK, payload: res.data.data });
            } else {
                alert("something went wrong...");

            }
        }).catch(error => {
            alert(JSON.stringify(error.error));
            dispatch({ type: actionTypes.FETCH_USER_PENDING_SKILLS_FAILURE_OUTLOOK, error: error });
        });
    };
}
