import * as actionTypes from './outlookHeaderActionType';
import axios from 'axios';
import config from "../../config/config";

export function getUserNotifications() {

    return function (dispatch) {
        return axios.get(config.api.server_url.request_url.base_url + "notification/getUserNotifications/" + localStorage.getItem('userId'), {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        }).then((res) => {
            dispatch({type: actionTypes.FETCH_NOTIFICATIONS_SUCCESS, payload: res.data.data});
        }).catch(error => {
            dispatch({type: actionTypes.FETCH_NOTIFICATIONS_FAILURE, error: error});
        });
    };
}

export function setUserNotificationsAsRead() {

    let data = {
        "userId": localStorage.getItem('userId')
    };

    return function (dispatch) {
        return axios.post(config.api.server_url.request_url.base_url + "notification/setUserNotificationsAsRead", data, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        }).then((res) => {
            dispatch({type: actionTypes.READ_NOTIFICATIONS_SUCCESS, payload: res.data});
        }).catch(error => {
            dispatch({type: actionTypes.READ_NOTIFICATIONS_FAILURE, error: error});
        });
    };
}