import React, { Component } from "react";
import { Button, Form } from "reactstrap";
class TextToSpeech extends Component {
  constructor(props) {
    super(props);
    this.state = {
      voiceText: this.props.voiceText,
      setTextFlag: false,
      slideNumber: this.props.slideNumber,
      redirect: this.props.redirect ? this.props.redirect : false,
      isBasicQuiz: this.props.isBasicQuiz ? this.props.isBasicQuiz : false,
    };
    this.changeContent = this.changeContent.bind(this);
    this.startVoice = this.startVoice.bind(this);
    window.onbeforeunload = function (e) {
      return window.speechSynthesis.cancel();
    };
  }
  startTextConversion() {
    window.speechSynthesis.cancel();
    var multipartText = [];
    var values = [];
    var CHARACTER_LIMIT = this.state.characterLimit;
    var tmptxt = this.state.voiceText;

    if (this.state.voiceText.length > CHARACTER_LIMIT) {
      while (tmptxt.length > this.state.characterLimit) {
        values = this.correctText(tmptxt);
        tmptxt = values[0];
        multipartText.push(values[1]);
      }
      //Add the remaining text
      if (tmptxt.length > 0) {
        while (tmptxt.length > 0) {
          values = this.correctText(tmptxt);
          tmptxt = values[0];
          multipartText.push(values[1]);
        }
      }
    } else {
      while (tmptxt.length > 0) {
        values = this.correctText(tmptxt);
        tmptxt = values[0];
        multipartText.push(values[1]);
      }
    }

    //Play multipart text
    this.textToSpeech(multipartText);
  }

  textToSpeech(multipartText) {
    for (var i = 0; i < multipartText.length; i++) {
      var msg = new SpeechSynthesisUtterance();
      msg.volume = 1; // 0 to 1
      msg.rate = 1; // 0.1 to 10
      msg.pitch = 1; //0 to 2*/
      msg.text = multipartText[i];
      msg.speak = multipartText;
      msg.lang = this.state.lang;
      msg.onend = this.OnFinishedPlaying;
      msg.onerror = function (e) {
        console.log("Error");
        console.log(e);
      };
      speechSynthesis.speak(msg);
    }
  }

  correctText(tmptxt) {
    var value = [];
    //Split by common phrase delimiters
    var p = tmptxt.search(/[\n:!?.;]+/);
    var part = "";

    //Coludn't split by priority characters, try commas
    if (p == -1 || p >= this.state.characterLimit) {
      p = tmptxt.search(/[,]+/);
    }

    //Couldn't split by normal characters, then we use spaces
    if (p == -1 || p >= this.state.characterLimit) {
      var words = tmptxt.split(" ");

      for (var i = 0; i < words.length; i++) {
        if (part.length + words[i].length + 1 > this.state.characterLimit)
          break;

        part += (i != 0 ? " " : "") + words[i];
      }
    } else {
      part = tmptxt.substr(0, p + 1);
    }

    tmptxt = tmptxt.substr(part.length, tmptxt.length - part.length);
    value[0] = tmptxt;
    value[1] = part.replace(/[()\.]/g, " ");
    return value;
  }
  changeContent(newContent) {
    this.setState({
      voiceText: newContent,
    });
  }
  startVoice(e) {
    e.preventDefault();
    if (!this.state.setTextFlag) {
      this.setState({
        setTextFlag: true,
      }, () => {
        this.startTextConversion();
      });
    }
    else {
      this.setState({
        setTextFlag: false
      }, () => {
        window.speechSynthesis.cancel();
      });
    }
  }
  componentWillReceiveProps = (nextProps) => {
    if (
      nextProps.voiceText !== "" &&
      this.state.voiceText !== nextProps.voiceText
    ) {
      this.setState(
        {
          voiceText: nextProps.voiceText,
          slideNumber: nextProps.slideNumber,
          redirect: nextProps.redirect ? nextProps.redirect : false,
        },
        () => {
          if (this.state.setTextFlag) {
            this.startTextConversion();
          }
        }
      );
    }
    // else {
    //   window.speechSynthesis.cancel();
    // }
    if (this.state.slideNumber !== nextProps.slideNumber) {
      window.speechSynthesis.cancel();
    }
    if (nextProps.redirect) {
      window.speechSynthesis.cancel();
    }
    if (nextProps.voiceText === "") {
      this.setState({ voiceText: "" });
    }
  };
  render() {
    const playIcon = this.state.isBasicQuiz
      ? require("../../images/backgroundImages/volume-black.png")
      : require("../../images/backgroundImages/volume.png");
  
    const pauseIcon = this.state.isBasicQuiz
      ? require("../../images/backgroundImages/mute-black.png")
      : require("../../images/backgroundImages/mute.png");
  
    const iconSrc = this.state.setTextFlag ? playIcon : pauseIcon ;
    const buttonAlt = this.state.setTextFlag ? "Play" :  "Pause";
    return (
      <Form onSubmit={this.startVoice}>
        <Button
          id="voiceButton"
          style={{
            backgroundColor: "initial",
            color: "white",
            border: "none",
            boxShadow: "none",
            paddingTop: "0px",
            paddingBottom: "10px"
          }}
        >
        <img
            id="voiceIcon"
            style={{ width: "23px" }}
            src={iconSrc}
            alt={buttonAlt}
          />
        </Button>
      </Form>
    );
  }
}

export default TextToSpeech;
