import React, { Component } from "react";
import { connect } from "react-redux";
import { getSkills } from "./skillAction";
import { FormGroup, Input, Row, Col } from "reactstrap";
import SkillCardComponent from './skillCardComponent';
import Breadcrumbs from "../../Breadcrumbs";
import { css } from "@emotion/core";
import SyncLoader from "react-spinners/SyncLoader";
import arrowImg from '../../images/select_arrow.png';


const override = css`
  display: block;
  margin: 200px auto;
  width: 100px;
  padding: '25%'
`;

class SkillsComponent extends Component {
  constructor(props) {
    super(props);
    this.props.homeVisibility(true);
    this.state = {
      filterText: "",
      selectedTag: "",
      loading: false
    };
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleTagChange = this.handleTagChange.bind(this);
  }

  handleFilterChange(event) {
    this.setState({
      filterText: event.target.value
    });
  }

  handleTagChange(event) {
    this.setState({
      selectedTag: event.target.value
    });
  }

  getSearchtags(tagsArray) {
      for (let j=0; j<tagsArray.length; j++) {
          if (tagsArray[j].toLowerCase().match(this.state.filterText.toLowerCase())) return true;
      }
      return false;
  }

  componentDidMount = async () => {
    if (!localStorage.getItem("token")) {
      //alert("User Not Logged In!!!");
      this.props.history.push("/");
    }

    this.setState({ loading: true })
    await this.props.skills();

    if (this.props.resultSkill) {
      let loadingTime = this;
      setTimeout(function () {
        loadingTime.setState({ loading: false });
      }, 200);
    }
    window.speechSynthesis.cancel();
  }

  render() {
    const { resultSkill } = this.props;

    let tagList = new Array();
    let tagListSet = new Set();

    let count = 0;
    let check=false;

    if (this.props.resultSkill.data) {

      this.props.resultSkill.data.map((item, index) => {
        item.tags.map((tag, index) => tagListSet.add(tag));

        if ((this.state.selectedTag === "" ||
          item.tags.includes(this.state.selectedTag)) && (this.state.filterText === "" || item.name.toLowerCase().includes(this.state.filterText.toLowerCase()) || this.getSearchtags(item.tags))) {
          count++;
       }
       if(count == 0){
        check=true;
      }
      else{
        check=false;
      }

      }
      );
      tagList = [...tagListSet].sort();


    }

    let options = [];

    tagList.forEach(item => {
      if(item !== ""){
        options.push(<option value={item}> {item}</option>);
      }
    });

    return (
      <div>
        <Breadcrumbs />
        <div className="inner-courses-wrapper report-table-wrapper mt-0">
          <div className="container-fluid mob-container lesson-content-scroll">

            {this.state.loading ?
              <SyncLoader
                css={override}
                size={20}
                color={"#0b6eb6"}
                loading={this.state.loading} />
              : <div className="main-container">
                <h2 className="page-title">
                  <span>
                    My Nuggets (
                  {this.props.resultSkill.data
                      ? count
                      : null}
                    ){" "}
                  </span>
                </h2>
                <br></br>
                <Row className="">
                <Col lg={10} md={7}>

                    <div className="search-box float-right">
                      <div className="form-group has-search">
                        <span className="fa fa-search form-control-feedback"></span>
                        <Input
                          className="form-control"
                          type="text"
                          placeholder="Search"
                          maxLength="20"
                          value={this.state.filterText}
                          ref="filterTextInput"
                          style={{ minHeight: "38px" }}
                          onChange={this.handleFilterChange}
                        ></Input>
                      </div>
                    </div>
                  </Col>

                  <Col lg={2} md={5}>
                    <FormGroup className="">
                      <Input
                        type="select"
                        name="select"
                        id="categorySelect"
                        className="categorySelect"
                        style={{ 
                        minHeight: "auto", 
                        float: "right",
                        background : "url("+`${arrowImg}`+")",
                        backgroundRepeat : 'no-repeat',backgroundPositionX : '135px', backgroundPositionY : '20px',
                        WebkitAppearance : 'none' 
                      }}
                        onChange={this.handleTagChange}
                      >
                        <option value="">All Nuggets</option>
                        {options}
                      </Input>
                    </FormGroup>
                  </Col>


                </Row>

                <div className="row">
                  {this.props.resultSkill.data
                    ? this.props.resultSkill.data.map((item, index) =>
                      ((this.state.selectedTag === "" ||
                        item.tags.includes(this.state.selectedTag)) && (this.state.filterText === "" || item.name.toLowerCase().includes(this.state.filterText.toLowerCase()) || this.getSearchtags(item.tags))) ?
                        (
                          <SkillCardComponent item={item}></SkillCardComponent>
                        ) : null
                    )
                    : ""}
                    {check == true ?<div className="noResults">There are No Records To Display</div>:null}
                </div>
              </div>}
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    skills: () => dispatch(getSkills("skills"))
  };
};

const mapStateToProps = state => {
  return {
    resultSkill: state.skillReducer.resultSkill || []
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SkillsComponent);