import * as actionTypes from './completedSkillsActionType';
import axios from 'axios';
import config from "../../config/config";

export function getCompletedSkills() {

    return function (dispatch) {

        return axios.get(config.api.server_url.request_url.base_url +"getCompletedSkills/"+localStorage.getItem("mail"), {
            headers: {
                Username : localStorage.getItem('mail'),
            }
        }).then((res) => {
                dispatch({ type: actionTypes.FETCH_COMPLETED_SKILLS_SUCCESS, payload: res.data.data});

        }).catch(error=>{
            dispatch({ type: actionTypes.FETCH_COMPLETED_SKILLS_FAILURE, error: error });
        })
    };
}
