import "./scss/global.scss";

import * as React from "react";
import { isBrowser } from "react-device-detect";
import { Provider } from "react-redux";
import { Redirect } from "react-router";
import { Route, Router, Switch } from "react-router-dom";

import { interceptors } from "./axiosInterceptor";
import Breadcrumbs, { loadLessonData } from "./Breadcrumbs";
import configureStore from "./configureStore";
import Footer from "./containers/Footer/Footer";
import NewHeader from "./containers/Header/newHeader";
import LandingPage from "./containers/landingPage/landingpage";
import LdapLandingPage from "./containers/landingPage/ldapLandingpage";
import SkillsLandingPage from "./containers/landingPage/skillsLandingPage";
import TeamsLandingPage from "./containers/landingPage/teamsLandingpage";
import Dashboard from "./containers/learnerDashboard/dashboard";
import LessonsComponent from "./containers/lessons/lesson";
import AllSkillsComponent from "./containers/outlook/allSkills/allSkills";
import CompletedSkillsComponent from "./containers/outlook/completedSkills/completedSkills";
import OutlookFeedbackComponent from "./containers/outlook/outlookFeedback/outlookFeedback";
import OutlookHeader from "./containers/outlook/outlookHeader/outlookHeader";
import OutlookHomeComponent from "./containers/outlook/outlookHome/outlookHome";
import OutlookLandingComponent from "./containers/outlook/outlookLanding/outlookLanding";
import OutlookLoginComponent from "./containers/outlook/outlookLogin/outlookLogin";
import PendingSkillsComponent from "./containers/outlook/pendingSkills/pendingSkills";
import OutlookPopularNuggetsComponent from "./containers/outlook/popularSkills/popularSkills";
import OutlookRecentlyAddedNuggetsComponent from "./containers/outlook/recentlyAddedSkills/recentlyAddedSkills";
import ReportsComponent from "./containers/outlook/reports/reports";
import MyProfile from "./containers/profile/profile";
import QuestionsComponent from "./containers/quiz/quiz";
import RatingComponent from "./containers/rating/rating";
import Reports from "./containers/reports/reports";
import Sidebar from "./containers/sideBar/sideBar";
import SignUpPage from "./containers/signUpPage/signUpPage";
import SkillsComponent from "./containers/skills/skill";
import LongSlider from "./containers/SliderThemes/longSlider/longSlider";
import FullScreenTopicsComponent from "./containers/topics/fullScreenTopic";
import TopicsComponent from "./containers/topics/topic";
import history from "./history";
import { messaging } from "./init-fcm";

/*For outlook Integration changes*/
const location = window;

const regex = /([A-Z0-9])\w/g;
const regex1 = /([^/][A-Z0-9])\w/g;
const store = configureStore();
class App extends React.Component {
  constructor(props) {
    super(props);
    interceptors();
    this.state = {
      title: "",
      homeVisibility: false,
      displayHeader: false,
      displayFooter: true,
      sideBarVisibilty: isBrowser ? true : false,
      active:
        window.location.pathname === ""
          ? "dashboard"
          : window.location.pathname.substr(1),
      displayOutlookHeader: false,
    };
    this.sideBarToggle = this.sideBarToggle.bind(this);
  }

  componentDidMount() {
    if (messaging) {
      messaging
        .requestPermission()
        .then(async function () {
          messaging
            .getToken()
            .then((currentToken) => {
              if (currentToken) {
                sendTokenToServer(currentToken);
              } else {
                // Show permission UI.
                setTokenSentToServer(false);
              }
            })
            .catch((err) => {
              setTokenSentToServer(false);
            });
        })
        .catch(function (err) {console.log(err);});

      // [START refresh_token]
      // Callback fired if Instance ID token is updated.
      messaging.onTokenRefresh(() => {
        messaging
          .getToken()
          .then((refreshedToken) => {
            // Indicate that the new Instance ID token has not yet been sent to the
            // app server.
            setTokenSentToServer(false);
            // Send Instance ID token to app server.
            sendTokenToServer(refreshedToken);
            // [START_EXCLUDE]
            // Display new Instance ID token and clear UI of all previous messages.
            // [END_EXCLUDE]
          })
          .catch((err) => {
            console.log("Unable to retrieve refreshed token ", err);
          });
      });
      // [END refresh_token]

      // [START receive_message]
      // Handle incoming messages. Called when:
      // - a message is received while the app has focus
      // - the user clicks on an app notification created by a service worker
      //   `messaging.setBackgroundMessageHandler` handler.
      messaging.onMessage((payload) => {
        // [START_EXCLUDE]
        // Update the UI to include the received message.
        // [END_EXCLUDE]
      });
      // [END receive_message]
    }

    // Send the Instance ID token your application server, so that it can:
    // - send messages back to this app
    // - subscribe/unsubscribe the token from topics
    function sendTokenToServer(currentToken) {
      if (!isTokenSentToServer()) {
        // TODO(developer): Send the current token to your server.
        localStorage.setItem("notificationToken", currentToken);
        fetch(`${process.env.REACT_APP_API_URL}notification/save_endpoint`, {
          method: "post",
          headers: {
            "content-type": "application/json;charset=UTF-8",
            "sec-fetch-mode": "cors",
          },
          body: JSON.stringify({ token: currentToken }),
          mode: "cors",
        });
        setTokenSentToServer(true);
      }
    }

    function isTokenSentToServer() {
      return localStorage.getItem("sentToServer") === "1";
    }

    function setTokenSentToServer(sent) {
      localStorage.setItem("sentToServer", sent ? "1" : "0");
    }
  }

  sideBarToggle = () => {
    this.setState({
      sideBarVisibilty: !this.state.sideBarVisibilty,
    });
  };

  footerVisibility = (flag) => {
    if (this.state.displayFooter !== false) {
      this.setState({ displayFooter: flag });
    }
  };
  setActiveClass = (currentState) => {
    this.setState({
      active: currentState,
    });
    if (window.innerWidth >= 320 && window.innerWidth <= 768) {
      // this.isSidebarToggle();
    }
  };

  homeVisibility = (homeVisibility) => {
    if (homeVisibility) {
      console.log(
        window.location.pathname,
        window.location.pathname.includes("/lessons/"),
        this.state.displayHeader,
        this.state.displayFooter,
        this.state.sideBarVisibilty
      );
      if (homeVisibility !== this.state.homeVisibility) {
        this.setState({ displayHeader: false });
        this.setState({ homeVisibility }, function () {
          this.setState({ displayHeader: true });
          this.setState({ displayFooter: true });
          this.setState({ displayOutlookHeader: false });
        });
      }
    }

    if (!homeVisibility) {
      if (homeVisibility !== this.state.homeVisibility) {
        //  this.setState({sideBarVisibilty:false})
        this.setState({ displayOutlookHeader: false });
        this.setState({ homeVisibility: homeVisibility }, function () {
          this.setState({ displayOutlookHeader: false });
          this.setState({ displayHeader: false });
          this.setState({ displayFooter: false });
        });
      }
    }
  };
  classRenderer = () => {
    const allowedPaths = ["/", "/login", "/sign-up", "/teams", "/goToSkill"];
    if (
      this.state.sideBarVisibilty &&
      !allowedPaths.includes(window.location.pathname)
    ) {
      if (
        window.location.pathname.includes("/lessons/") ||
        window.location.pathname.includes("/skills/quiz/") ||
        window.location.pathname.includes("/rating/")
      ) {
        return "content-wrapper-lb";
      }
      if (window.location.pathname.includes("outlook")) {
        return "outlook-content-wrapper";
      }

      return "content-wrapper";
    } else if (
      !allowedPaths.includes(window.location.pathname)
    ) {
      if (
        window.location.pathname.includes("/lessons/") ||
        window.location.pathname.includes("/skills/quiz/") ||
        window.location.pathname.includes("/rating/")
      ) {
        return "lesson-margin-reduce";
      }
      return "margin-reduce";
    }

    return "login-signup-wrapper";
  };

  render() {
    if (window.location.pathname.includes("skills")) {
      localStorage.setItem("previousUrl", window.location.pathname);
      if(!localStorage.getItem("token")){
        window.location.replace(process.env.REACT_APP_REDIRECT_URL);
      }
    }

    return (
      <Provider store={store}>
        <Router history={history}>
          <div className="main-wrapper">
            {window.location.pathname !== "/" &&
            window.location.pathname !== "/login" &&
            window.location.pathname !== "/sign-up" &&
            window.location.pathname !== "/teams" &&
            window.location.pathname !== "/goToSkill" &&
            !window.location.pathname.includes("/lessons/") &&
            !window.location.pathname.includes("/skills/quiz/") &&
            !window.location.pathname.includes("/rating/") ? (
              this.state.displayHeader ? (
                <NewHeader
                  homeVisibility={this.state.homeVisibility}
                  sideBarprops={this.sideBarToggle}
                  history={history}
                />
              ) : (
                ""
              )
            ) : null}
            {window.location.pathname !== "/" &&
            window.location.pathname !== "/login" &&
            window.location.pathname !== "/sign-up" &&
            window.location.pathname !== "/teams" &&
            !window.location.pathname.includes("outlook") &&
            window.location.pathname !== "/goToSkill" &&
            !window.location.pathname.includes("/lessons/") &&
            !window.location.pathname.includes("/skills/quiz/") &&
            !window.location.pathname.includes("/rating/") ? (
              <Sidebar
                showsidebarVisibleState={this.state.sideBarVisibilty}
                sidebarToggleHandler={(e) => this.sideBarToggle()}
                active={this.state.active}
                setActiveClass={this.setActiveClass}
              />
            ) : null}
            {window.location.pathname !== "/" &&
            window.location.pathname !== "/login" &&
            window.location.pathname !== "/sign-up" &&
            window.location.pathname !== "/teams" &&
            window.location.pathname !== "/goToSkill" &&
            !window.location.pathname.includes("/lessons/") &&
            !window.location.pathname.includes("/skills/quiz/") &&
            !window.location.pathname.includes("/rating/") ? (
              this.state.displayFooter ? (
                <Footer />
              ) : (
                ""
              )
            ) : null}
            {window.location.pathname !== "/" &&
            window.location.pathname !== "/login" &&
            window.location.pathname !== "/sign-up" &&
            window.location.pathname !== "/teams" &&
            window.location.pathname !== "/goToSkill" &&
            !window.location.pathname.includes("/lessons/") &&
            !window.location.pathname.includes("/skills/quiz/") &&
            !window.location.pathname.includes("/rating/") ? (
              this.state.displayOutlookHeader ? (
                <OutlookHeader homeVisibility={this.state.homeVisibility} />
              ) : (
                ""
              )
            ) : null}
            {window.location.pathname !== "/" &&
            window.location.pathname !== "/login" &&
            window.location.pathname !== "/sign-up" &&
            window.location.pathname !== "/teams" &&
            window.location.pathname !== "/goToSkill" &&
            window.location.pathname !== "/outlook-landing" &&
            window.location.pathname !== "/outlook-login" &&
            !window.location.pathname.includes("/lessons/") &&
            !window.location.pathname.includes("/skills/quiz/") &&
            !window.location.pathname.includes("/rating/") ? (
              <Breadcrumbs />
            ) : null}

            <div className={`${this.classRenderer()}`}>
              <Switch>
                <Route
                  exact
                  path="/"
                  render={(props) => (
                    <LandingPage
                      loadLessonData={loadLessonData}
                      key={location.pathname}
                      {...props}
                    />
                  )}
                />

                <Route
                  path="/login"
                  render={(props) => (
                    <LdapLandingPage
                      loadLessonData={loadLessonData}
                      key={location.pathname}
                      {...props}
                    />
                  )}
                />
                <Route
                  path="/lessons/:skill_id/:id"
                  key={location.pathname}
                  component={(props) => {
                    if (props.location.lessonsTheme !== undefined)
                      localStorage.setItem(
                        "lessonsTheme",
                        props.location.lessonsTheme
                      );
                    return (
                      <FullScreenTopicsComponent
                        //   sideBarprops={() => this.sideBarToggle()}
                        //  sideBarVisibilty={this.state.sideBarVisibilty}
                        homeVisibility={this.homeVisibility}
                        timestamp={new Date().toString()}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/teams"
                  render={(props) => (
                    <TeamsLandingPage
                      loadLessonData={loadLessonData}
                      key={location.pathname}
                      {...props}
                    />
                  )}
                />

                <Route
                  exact
                  path="/goToSkill"
                  render={(props) => (
                    <SkillsLandingPage
                      loadLessonData={loadLessonData}
                      key={location.pathname}
                      {...props}
                    />
                  )}
                />

                <Route
                  exact
                  path="/sign-up"
                  render={(props) => (
                    <SignUpPage key={location.pathname} {...props} />
                  )}
                />

                <Route
                  path="/dashboard"
                  render={(props) => {
                    return (
                      <Dashboard
                        homeVisibility={this.homeVisibility}
                        key={location.pathname}
                        setActiveClass={this.setActiveClass}
                        {...props}
                      />
                    );
                  }}
                />

                <Route
                  path="/skills/quiz/:skill_id/:id"
                  key={location.pathname}
                  component={(props) => (
                    <QuestionsComponent
                      homeVisibility={this.homeVisibility}
                      timestamp={new Date().toString()}
                      {...props}
                    />
                  )}
                />

                <Route
                  path="/lessons/quiz/:skill_id_new/:id"
                  key={location.pathname}
                  component={(props) => (
                    <LongSlider
                      //homeVisibility={this.homeVisibility}
                      timestamp={new Date().toString()}
                      {...props}
                    />
                  )}
                />

                {/*This route is defined to handel breadCrums of Quiz*/}
                <Route
                  path="/skills/quiz/:skill_id"
                  key={location.pathname}
                  component={(props) => (
                    <Redirect to={`/skills/${props.match.params.skill_id}`} />
                  )}
                />

                <Route
                  path="/skills/:skill_id/:id"
                  key={location.pathname}
                  component={(props) => {
                    if (props.location.lessonsTheme !== undefined)
                      localStorage.setItem(
                        "lessonsTheme",
                        props.location.lessonsTheme
                      );
                    return (
                      <TopicsComponent
                        //   sideBarprops={() => this.sideBarToggle()}
                        //  sideBarVisibilty={this.state.sideBarVisibilty}
                        //  homeVisibility={this.homeVisibility}
                        timestamp={new Date().toString()}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  path="/skills/:skill_id"
                  key={location.pathname}
                  component={(props) => {
                    if (
                      props.location.icon_id !== undefined ||
                      props.location.skill_name !== undefined
                    ) {
                      localStorage.setItem("icon_id", props.location.icon_id);
                      localStorage.setItem(
                        "skill_name",
                        props.location.skill_name
                      );
                    }
                    return (
                      <LessonsComponent
                        homeVisibility={this.homeVisibility}
                        key={location.pathname}
                        {...props}
                      />
                    );
                  }}
                />

                <Route
                  path="/skills"
                  render={(props) => {
                    return (
                      <SkillsComponent
                        homeVisibility={this.homeVisibility}
                        key={location.pathname}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  path="/rating/:skill_id/:id"
                  key={location.pathname}
                  component={(props) => (
                    <RatingComponent
                      homeVisibility={this.homeVisibility}
                      {...props}
                    />
                  )}
                />
                <Route
                  path="/reports"
                  render={(props) => {
                    return (
                      <Reports
                        homeVisibility={this.homeVisibility}
                        key={location.pathname}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  path="/my-profile"
                  key={location.pathname}
                  component={(props) => (
                    <MyProfile
                      homeVisibility={this.homeVisibility}
                      timestamp={new Date().toString()}
                      {...props}
                    />
                  )}
                />
                {/*----------------------------------For outlook integration-------------------------------------------*/}
                <Route
                  exact
                  path="/outlook-landing"
                  render={(props) => (
                    <OutlookLandingComponent
                      key={location.pathname}
                      {...props}
                    />
                  )}
                />

                <Route
                  path="/outlook-login"
                  render={(props) => (
                    <OutlookLoginComponent key={location.pathname} {...props} />
                  )}
                />

                <Route
                  path="/outlook-all-skills"
                  render={(props) => (
                    <AllSkillsComponent
                      homeVisibility={this.homeVisibility}
                      key={location.pathname}
                      {...props}
                    />
                  )}
                />

                <Route
                  path="/outlook-home"
                  render={(props) => (
                    <OutlookHomeComponent
                      homeVisibility={this.homeVisibility}
                      key={location.pathname}
                      {...props}
                    />
                  )}
                />

                <Route
                  path="/outlook-feedback"
                  render={(props) => (
                    <OutlookFeedbackComponent
                      homeVisibility={this.homeVisibility}
                      key={location.pathname}
                      {...props}
                    />
                  )}
                />

                <Route
                  path="/outlook-completed-skills"
                  render={(props) => (
                    <CompletedSkillsComponent
                      homeVisibility={this.homeVisibility}
                      key={location.pathname}
                      {...props}
                    />
                  )}
                />

                <Route
                  path="/outlook-pending-skills"
                  render={(props) => (
                    <PendingSkillsComponent
                      homeVisibility={this.homeVisibility}
                      key={location.pathname}
                      {...props}
                    />
                  )}
                />

                <Route
                  path="/outlook-popular-nuggets"
                  render={(props) => (
                    <OutlookPopularNuggetsComponent
                      homeVisibility={this.homeVisibility}
                      key={location.pathname}
                      {...props}
                    />
                  )}
                />

                <Route
                  path="/outlook-recently-added-nuggets"
                  render={(props) => (
                    <OutlookRecentlyAddedNuggetsComponent
                      homeVisibility={this.homeVisibility}
                      key={location.pathname}
                      {...props}
                    />
                  )}
                />

                <Route
                  path="/outlook-reports"
                  render={(props) => (
                    <ReportsComponent
                      homeVisibility={this.homeVisibility}
                      key={location.pathname}
                      {...props}
                    />
                  )}
                />
                {/*----------------------------------For outlook integration-------------------------------------------*/}
              </Switch>
            </div>
          </div>
        </Router>
      </Provider>
    );
  }
}

export default App;
