import React, {Component} from 'react';
import signUpService from './signUp-service';
import {connect} from 'react-redux';

import {bindActionCreators} from "redux";
import './../landingPage/login.css';
import {Link} from "react-router-dom";
import {Col, Row} from "reactstrap";
import {createUser, getAllOrganizations} from "../signUpPage/signUpPageAction";
import jwt from "jsonwebtoken";
const platoLogo = require("../../images/landingPage/platoLogo.png");

class SignUpPage extends Component {
    constructor(props) {
        super(props);
        this.state = this.initialState();

    }

    initialState = () => {
        return ({
            firstName: '',
            lastName: '',
            username: '',
            password: '',
            mobile: '',
            profilePic: '',
            confirmPassword: '',
            test_account: true,
            isSignUp: true,
            organizationId: '0',
            organizationName: '',
            errors: {
                firstNameError: '',
                lastNameError: '',
                usernameError: '',
                passwordError: '',
                confirmPasswordError: '',
                mobileError: '',
                organizationNameError: ''
            }
        });
    };


    componentDidMount() {
        this.props.getAllOrganizations();
    }

    validate = () => {
        let isError = false;
        let tempError = this.state.errors;
        let strongRegex = new RegExp("^(?=.*[A-Z])(?=.*[!@#\$%\^&\*])");
        
        if ((this.state.firstName.length) === 0) {
            isError = true;
            tempError.firstNameError = "Firstname should not be empty";
        } else {
            tempError.firstNameError = "";
        }
        if ((this.state.lastName.length) === 0) {
            isError = true;
            tempError.lastNameError = "Lastname should not be empty";
        } else {
            tempError.lastNameError = "";
        }

        if ((this.state.username.length) === 0) {
            isError = true;
            tempError.usernameError = "Username should not be empty";
        } else {
            const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
            if (expression.test(this.state.username.toLowerCase())) {
                tempError.usernameError = "";

                if (this.state.username.toLowerCase().includes("@harbingergroup.com")) {
                    isError = true;
                    tempError.usernameError = "Can not register with harbinger domain!";
                }
            } else {
                isError = true;
                tempError.usernameError = "Please enter valid mail id";
            }
        }

        if ((this.state.password.length) === 0) {
            isError = true;
            tempError.passwordError = "Password should not be empty";
        } else if ((this.state.password.length) < 8) {
            isError = true;
            tempError.passwordError = "Password must be at least 8 characters long";
        }else if (!strongRegex.test(this.state.password)) {
            isError = true;
            tempError.passwordError = "Password should contain at least 1 uppercase and 1 special character";
        } else {
            tempError.passwordError = "";
        }

        if ((this.state.confirmPassword.length) === 0) {
            isError = true;
            tempError.confirmPasswordError = "Confirm Password should not be empty";
        } else {
            if (this.state.password === this.state.confirmPassword) {
                tempError.confirmPasswordError = "";
            } else {
                isError = true;
                tempError.confirmPasswordError = "Password mismatch";
            }
        }
        /* if ((this.state.mobile.length) === 0) {
             isError = true;
             tempError.mobileError = "Phone number should not be empty";
         } else if (isNaN(this.state.mobile) || (this.state.mobile.length) !== 10) {
             isError = true;
             tempError.mobileError = "invalid phone number";
         } else {
             tempError.mobileError = "";
         }*/


        if ((this.state.organizationName.length) === 0) {
            isError = true;
            tempError.organizationNameError = "Organization should not be empty";
        } else {
            tempError.organizationNameError = "";
        }

        if (isError) {
            this.setState({
                errors: tempError
            });
        }
        return isError;
    };

    handleBlurOrganizationName = () => {
        let tempError = this.state.errors;
        tempError.usernameError = "";
        tempError.organizationNameError = "";
        if ((this.state.username.length) === 0) {
            tempError.usernameError = "Username should not be empty";

        } else if (this.state.username.toLowerCase().includes("@harbingergroup.com")) {
            tempError.usernameError = "Can not register with harbinger domain!";
        } else {
            const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
            if (expression.test(this.state.username.toLowerCase())) {
                tempError.usernameError = "";
                let orgFoundFlag = false;
                let domain = this.getDomain(this.state.username);

                let {organizationList} = this.props;
                organizationList = jwt.verify(organizationList, process.env.REACT_APP_JWT_KEY).result;
                for (let i = 0; i < organizationList.length; i++) {
                    if (domain === organizationList[i].organizationDomain) {
                        orgFoundFlag = true;
                        this.setState({
                            organizationName: organizationList[i].organizationName,
                            organizationId: organizationList[i]._id
                        });
                        break;
                    } else {
                        continue;
                    }
                }
                if (orgFoundFlag) {
                    tempError.organizationNameError = "";
                } else {
                    tempError.organizationNameError = "Organization not configured, contact Admin";
                }
            } else {
                tempError.usernameError = "Please enter valid mail id";
            }
        }

        this.setState({
            errors: tempError
        });

    };

    getDomain = s => {
        return s.includes('@') && s.substr(s.lastIndexOf('@') + 1).split(' ')[0];
    };

    handleChange = e => {
        let target = e.target;
        let value = target.type == 'checkbox' ? target.checked : target.value;
        let name = target.name;
        let tempError=this.state.errors;

        if(name == 'firstName')
        {
            tempError.firstNameError="";

        }else if(name == 'lastName')
        {
            tempError.lastNameError="";

        }else if(name == 'username')
        {
            tempError.usernameError="";

        }else if(name == 'password')
        {
            tempError.passwordError="";

        }else if(name == 'confirmPassword')
        {
            tempError.confirmPasswordError="";
        }
        else
        {
            tempError=this.state.error;
        }

        this.setState({
            [name]: value,
            errors:tempError
        });
    };

    handleConfirmPasswordBlur = () => {
        let tempError = this.state.errors;
        if (this.state.password === this.state.confirmPassword) {
            tempError.confirmPasswordError = "";
        } else {
            tempError.confirmPasswordError = "password mismatch";
            this.setState({
                errors: tempError
            });
        }
    };

    handleSubmit = async e => {
        e.preventDefault();
        const validateError = this.validate();
        if (!validateError) {

            await this.setState({
                password: signUpService(this.state.password),
                username: signUpService(this.state.username),
                organizationId: signUpService(this.state.organizationId),
                confirmPassword: signUpService(this.state.confirmPassword)
            });

            await this.props.createUser(this.state, this.props);
            await this.setState(
                this.initialState()
            );


        }
    };

    render() {
        return (
            <div className="app">
                <div className='container signUp-container'>

                    <div className='login-header'>
                        <img className='login-image' src={platoLogo}
                             alt='SprinkleZone Logo'/>
                    </div>
                    <form onSubmit={this.handleSubmit} className="FormFields mt-3 signUp-form">
                        <Row>
                            <Col lg={12}>

                                <Row>
                                    <Col lg={6}>
                                        <div className="input-wrapper">
                                            <i className="icon-user icon"/>
                                            <span className="icon-seperator"/>
                                            <input type='text' id='firstName' name='firstName' placeholder='FIRST NAME'
                                                   value={this.state.firstName} onChange={this.handleChange}/>
                                        </div>
                                        <span className="Span SignUpErrors">{this.state.errors.firstNameError}</span>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="input-wrapper">
                                            <i className="icon-user icon"/>
                                            <span className="icon-seperator"/>
                                            <input type='text' id='lastName' name='lastName' placeholder='LAST NAME'
                                                   value={this.state.lastName} onChange={this.handleChange}/>
                                        </div>
                                        <span className="Span SignUpErrors">{this.state.errors.lastNameError}</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={6}>
                                        <div className="input-wrapper">
                                            <i className="icon-envelope icon"/>
                                            <span className="icon-seperator"/>
                                            <input title="Please enter email id as user name" type='text' id='username'
                                                   name='username' placeholder='USERNAME'
                                                   value={this.state.username} onChange={this.handleChange}
                                                   onBlur={this.handleBlurOrganizationName}/>
                                        </div>
                                        <span className="Span SignUpErrors">{this.state.errors.usernameError}</span>
                                    </Col>

                                    {/*for organisation*/}
                                    <Col lg={6}>
                                        <div className="input-wrapper">
                                            <i className="icon-sitemap icon"/>
                                            <span className="icon-seperator"/>
                                            <input title="Organization name" type='text' id='organizationName'
                                                   name='organizationName' placeholder='ORGANIZATION'
                                                   value={this.state.organizationName}
                                                   readOnly={true}/>
                                        </div>
                                        <span className="Span SignUpErrors">{this.state.errors.organizationNameError}</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={6}>
                                        <div className="input-wrapper">
                                            <i className="icon-lock icon"/>
                                            <span className="icon-seperator"/>
                                            <input type='password' id='password' name='password' placeholder='PASSWORD'
                                                   value={this.state.password} onChange={this.handleChange}/>
                                        </div>
                                        <span className="Span SignUpErrors">{this.state.errors.passwordError}</span>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="input-wrapper">
                                            <i className="icon-lock icon"/>
                                            <span className="icon-seperator"/>
                                            <input type='password' id='confirmPassword' name='confirmPassword'
                                                   placeholder='CONFIRM PASSWORD'
                                                   value={this.state.confirmPassword} onChange={this.handleChange}
                                                   onBlur={this.handleConfirmPasswordBlur}
                                            />
                                        </div>
                                        <span className="Span SignUpErrors">{this.state.errors.confirmPasswordError}</span>


                                    </Col>
                                </Row>
                                {/*<Row>
                                        <Col lg={6}>
                                            <div className="input-wrapper">
                                                <i className="icon-phone icon" />
                                                <span className="icon-seperator" />
                                                <input type='text' id='mobile' name='mobile' placeholder='PHONE NO'
                                                       maxLength={10}
                                                       value={this.state.mobile} onChange={this.handleChange} />
                                            </div>
                                            <span className="Span">{this.state.errors.mobileError}</span>
                                    </Col>
                                </Row>*/}
                                {/* <div>
                                    <button className='login-button' onClick={this.handleSubmit}>SIGN UP</button>
                                    <Link to="/" className="formField-link">I'm already member</Link>
                                </div>*/}
                                <Row className="custom-align">
                                    <Col lg={5} xs={12}>
                                        <button className='login-button' onClick={this.handleSubmit}>SIGN UP</button>

                                    </Col>

                                </Row>
                                <br/>
                                <Row className="custom-align">
                                    <Col lg={7} xs={12} className="custom-font">
                                        You have an account?<Link to="/" className="formField-link">Login</Link>here
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </form>
                </div>
            </div>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            createUser: createUser,
            getAllOrganizations: getAllOrganizations
        },
        dispatch
    );
}

const mapStateToProps = state => {
    return {
        userCreate: state.signUpPageReducer.userCreate || {},
        organizationList: state.signUpPageReducer.organizationList || {}
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUpPage);
