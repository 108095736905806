const crypto = require('crypto');

const publicKey=`-----BEGIN PUBLIC KEY-----
MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAu7YS9aIaSQkRl2h6MUyJ
f29mVG5g2bXXOsPQCbtUhvWCleEPXxoL/2E8gETJDZ+RMIzomggab6Kx5txOJKlc
0uikJLYS3j4mgexntR/7WO7LnNDOcWy3M0SCqL6i84wwu4f/NO1yRQIgvpH+ZMR/
d3VAddFJ9dN8xI1oN75vGCqcLAC5c5bk4VFRQuW5Jqb4CQygkS1hzt8cwo8bryDh
Tp35YpPX7E4cq5mGNOwJaDkjxiXO+bBUkYsNwNeSaN3iKNhojrgs/ThxJKqdAKXD
Dv0kYhFNsdlLtokpiDNOn8m4vRuknxeyOjb/Kpbd/6pyrH9OI0qx6MBq6EM9Z3XV
Kk93rXEr5jXEBaDdpB0jlC51zzbyct4lQNHc8+xc91Hn38uzzpQbt/LvxsQS8Cpf
h7PK7lLsC2YarX+Rg5Uel9ZXr5W00nbaxkJMaL+G3oogbWHwYqAgfT9JjOMximGv
R7wx9SbyluJdn5cDjMptpgV0t2f6fzHOkbHchSHfiNs6zT1jhs33q9t/SFoMskuo
wZvS5YXb21n6T1FfXKKpVDoVwBR/+VjNyxikheFu+wjbToMnILBZwdd5GiViTBL2
VjKI3qsq+B8ekwncYwqFtTFIWNq3Dp0mkHdaO+u/1NtikMGD2fpy4maZ1x+f30Iz
US9FPwP3DmFR5G+03sn9HCkCAwEAAQ==
-----END PUBLIC KEY-----`;

const signUpService= (data) => {
    return crypto.publicEncrypt(publicKey, Buffer.from(JSON.stringify(data), 'utf8'));
};


export default signUpService;
