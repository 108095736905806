import "./login.css";

import axios from "axios";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";

import { loginService } from "./login-service";
import validate from "./validate";

const platoLogo = require("./../../images/landingPage/platoLogo.png");
const LoadingSpinner = () => (
  <div id="overlay">
    <div>
      <div className="spinner"> </div>
    </div>
  </div>
);

class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  componentDidMount() {
    const search = this.props.location.search;
    const params = new URLSearchParams(search);
    const code = params.get("code");
    if (!code) {
      this.setState({ loading: true });
      window.open(
        "https://harbingergroup.onelogin.com/oidc/2/auth?client_id=" +
          process.env.REACT_APP_ONELOGIN_CLIENT_ID +
          "&redirect_uri=" +
          process.env.REACT_APP_REDIRECT_URL +
          "&response_type=" +
          process.env.REACT_APP_RESPONSE_TYPE +
          "&scope="+process.env.REACT_APP_ONELOGIN_RESPONSE_SCOPE,
        "_self"
      );
    }
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.get("isTeamsApp") && queryParams.get("username") && !code) {
      this.handleRequest();
    }
    if (code) {
      this.handleRequest();
    }
  }

  userIdforTokenMapping = async (userId) => {
    let data = {
      token: localStorage.getItem("notificationToken"),
      userId: userId,
    };
    if (data.token === null) return;
    await axios({
      method: "put",
      url: `${process.env.REACT_APP_API_URL}notification/updateUserIdForToken`,
      headers: {
        "Content-Type": "application/json",
      },
      data: { data },
    }).then((res) => {});
  };

  handleRequest = async () => {
    let username;
    let password;
    let teamsFlag = false;
    let oneLogin = false;

    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.get("isTeamsApp") && queryParams.get("username")) {
      username = queryParams.get("username").toLowerCase();
      password = "Test@1234"; // This won't be used at backend
      teamsFlag = true;
    } else if (queryParams.get("code")) {
      oneLogin = true;
    } else {
      username = document.getElementById("username").value.toLowerCase();
      password = document.getElementById("password").value;
    }

    if (oneLogin) {
      this.setState({ loading: true });
      axios({
        method: "post",
        url:
          process.env.REACT_APP_API_URL +
          "login?code=" +
          queryParams.get("code"),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          axios({
            method: "post",
            url:
              process.env.REACT_APP_API_URL +
              "checkToken?isOneLogin=true&username=" +
              res.data.data.username,
            headers: {
              "Content-Type": "application/json",
            },
          }).then((res) => {
            this.setState({ loading: false });
            localStorage.setItem("token", res.data.data.token);
            localStorage.setItem("oneLoginToken", res.data.data.oneloginToken);
            localStorage.setItem("mail", res.data.data.username);
            localStorage.setItem(
              "organization",
              res.data.data.organization.organizationName
            );
            if (res.data.data.organization) {
              localStorage.setItem(
                "organizationLogoId",
                res.data.data.organization.organizationLogoId
              );
              localStorage.setItem(
                "headerColor",
                res.data.data.organization.headerColor
              );
              localStorage.setItem(
                "unameFont",
                res.data.data.organization.fontColor
              );
              localStorage.setItem(
                "organizationMobileLogoId",
                res.data.data.organization.organizationMobileLogoId
              );
            } else {
              localStorage.setItem("organizationLogoId", "");
              localStorage.setItem("headerColor", "");
            }
            this.props.loadLessonData();
            if (localStorage.getItem("token")) {
              if (res.data.data.userId) {
                this.userIdforTokenMapping(res.data.data.userId);
              }

              if (localStorage.getItem("previousUrl") !== null) {
                this.props.history.push(localStorage.getItem("previousUrl"));
                localStorage.removeItem("previousUrl");
              } else {
                this.props.history.push("/dashboard");
              }
            }
          });
        })
        .catch((error) => {
          document.getElementById("error").innerHTML =
            "<font color='red'><b><center>" +
            error.errors.message +
            "</center></b></font>";
        });
    } else {
      const status = validate(username, password);
      var str = "@harbingergroup.com";
      if (status === true) {
        if (!username.includes("@")) {
          if (username.endsWith(str) === false) {
            username += str;
          }
        }
        const data = loginService(username, password);
        axios({
          method: "post",
          url: teamsFlag
            ? process.env.REACT_APP_API_URL +
              "login?isTeamsApp=true&username=" +
              queryParams.get("username").toLowerCase()
            : process.env.REACT_APP_API_URL + "login",
          headers: {
            "Content-Type": "application/json",
          },
          data: { data },
        })
          .then((res) => {
            axios({
              method: "post",
              url: teamsFlag
                ? process.env.REACT_APP_API_URL +
                  "checkToken?isTeamsApp=true&username=" +
                  queryParams.get("username").toLowerCase()
                : process.env.REACT_APP_API_URL + "checkToken",
              headers: {
                "Content-Type": "application/json",
              },
              data: { data },
            }).then((res) => {
              localStorage.setItem("token", res.data.data.token);
              localStorage.setItem("mail", res.data.data.username);
              localStorage.setItem(
                "organization",
                res.data.data.organization.organizationName
              );
              if (res.data.data.organization) {
                localStorage.setItem(
                  "organizationLogoId",
                  res.data.data.organization.organizationLogoId
                );
                localStorage.setItem(
                  "headerColor",
                  res.data.data.organization.headerColor
                );
                localStorage.setItem(
                  "unameFont",
                  res.data.data.organization.fontColor
                );
                localStorage.setItem(
                  "organizationMobileLogoId",
                  res.data.data.organization.organizationMobileLogoId
                );
              } else {
                localStorage.setItem("organizationLogoId", "");
                localStorage.setItem("headerColor", "");
              }
              this.props.loadLessonData();
              if (localStorage.getItem("token")) {
                if (res.data.data.userId) {
                  this.userIdforTokenMapping(res.data.data.userId);
                }

                if (localStorage.getItem("previousUrl") !== null) {
                  this.props.history.push(localStorage.getItem("previousUrl"));
                  localStorage.removeItem("previousUrl");
                } else {
                  this.props.history.push("/dashboard");
                }
              }
            });
          })
          .catch((error) => {
            document.getElementById("error").innerHTML =
              "<font color='red'><b><center>" +
              error.errors.message +
              "</center></b></font>";
          });
      } else {
        document.getElementById("error").innerHTML =
          "<font color='red'><b><center> Username or password is incorrect </center></b></font>";
      }
    }
  };

  render() {
    return (
      <div className="app">
        <div className="container login-container">
          {this.state.loading ? <LoadingSpinner /> : null}
          <div className="login-header">
            <img
              className="login-image"
              src={platoLogo}
              alt="SprinkleZone Logo"
            ></img>
          </div>
          <div className="FormFields mt-3 login-form">
            <Row>
              <Col lg={12}>
                <Row className="custom-align">
                  <Col lg={7} xs={10}>
                    <div className="input-wrapper">
                      <i className="icon-user icon"></i>
                      <span className="icon-seperator"></span>
                      <input
                        type="text"
                        id="username"
                        placeholder="USERNAME ONELOGIN"
                        onKeyDown={(event) => {
                          if (event.key === "Enter") {
                            this.handleRequest();
                          }
                        }}
                      />
                    </div>
                  </Col>
                </Row>

                <Row className="custom-align">
                  <Col lg={7} xs={10}>
                    <div className="input-wrapper">
                      <i className="icon-lock icon"></i>
                      <span className="icon-seperator"></span>
                      <input
                        type="password"
                        id="password"
                        placeholder="PASSWORD"
                        onKeyDown={(event) => {
                          if (event.key === "Enter") {
                            this.handleRequest();
                          }
                        }}
                      />
                    </div>
                  </Col>
                </Row>

                <div>
                  <p id="error" color="danger"></p>
                </div>

                <Row className="custom-align">
                  <Col lg={7} xs={10}>
                    <button
                      className="login-button"
                      onClick={this.handleRequest}
                    >
                      LOG IN
                    </button>
                  </Col>
                </Row>
                <br />
                <Row className="custom-align">
                  <Col lg={7} xs={10} className="custom-font">
                    Don't have an account?
                    <Link to="/sign-up" className="formField-link">
                      {" "}
                      Sign up{" "}
                    </Link>
                    here
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

export default LandingPage;
