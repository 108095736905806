import * as actionTypes from './headerActionType';
import axios from 'axios';
import config from "./../config/config";
import {confirmAlert} from 'react-confirm-alert';
import jwt_decode from "jwt-decode";

export function logout() {

    return function (dispatch) {
        return axios.get(config.api.server_url.request_url.base_url + 'logout', {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            }
        }).then((res) => {
            dispatch({type: actionTypes.LOG_OUT, payload: res});
        }).catch(error => {
            dispatch({type: actionTypes.LOG_OUT, payload: error});
        });
    };
}


export function getUserNotifications() {
    let user = jwt_decode(localStorage.getItem("token"));
    return function (dispatch) {
        return axios.get(config.api.server_url.request_url.base_url + "notification/getUserNotifications/" + user._id, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        }).then((res) => {
            
            dispatch({type: actionTypes.FETCH_NOTIFICATIONS_SUCCESS, payload: res.data.data});
        }).catch(error => {
            dispatch({type: actionTypes.FETCH_NOTIFICATIONS_FAILURE, error: error});
        });
    };
}

export function setUserNotificationsAsRead() {
    let user = jwt_decode(localStorage.getItem('token'));
    let data = {
        "userId": user._id
    };

    return function (dispatch) {
        return axios.post(config.api.server_url.request_url.base_url + "notification/setUserNotificationsAsRead", data, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        }).then((res) => {
            dispatch({type: actionTypes.READ_NOTIFICATIONS_SUCCESS, payload: res.data});
        }).catch(error => {
            dispatch({type: actionTypes.READ_NOTIFICATIONS_FAILURE, error: error});
        });
    };
}
export function setIndividualNotificationsAsRead(data) {
    let user = jwt_decode(localStorage.getItem('token'));
    

    return function (dispatch) {
        return axios.post(config.api.server_url.request_url.base_url + "notification/setIndividualNotificationsAsRead", data, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        }).then((res) => {
            dispatch({type: actionTypes.READ_NOTIFICATION_SUCCESS, payload: res.data});
        }).catch(error => {
            dispatch({type: actionTypes.READ_NOTIFICATION_FAILURE, error: error});
        });
    };
}
export function getContactReasons() {

    return function (dispatch) {
        return axios.get(config.api.server_url.request_url.base_url + "getContactReasons", {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        }).then((res) => {
           
            dispatch({type: actionTypes.GET_CONTACT_REASONS_SUCCESS, payload: res.data.data});
        }).catch(error => {
            dispatch({type: actionTypes.GET_CONTACT_REASONS_FAILURE, error: error});
        });
    };
}
export function saveContactDetails(data) {
 return function (dispatch) {
        return axios.post(config.api.server_url.request_url.base_url + "/saveContactAdminDetails", data, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        }).then((res) => {
            
            dispatch({type: actionTypes.SAVE_CONTACT_ADMIN_DETAILS_SUCCESS, payload: res.data});
        }).catch(error => {
            dispatch({type: actionTypes.SAVE_CONTACT_ADMIN_DETAILS_FAILURE, error: error});
        });
    };
}

export function resetSaveContactDetails() {
 return function (dispatch) {
        dispatch({ type: actionTypes.RESET_SAVE_CONTACT_ADMIN_DETAILS });
    };
}
 export function changePassword(data) {
     
    return function(dispatch) {
        return axios.post(config.api.server_url.request_url.base_url +'/changePassword',data, {
            headers:{
                Authorization: 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            }
        }).then((res)=>{
           dispatch({ type: actionTypes.CHANGEPASSWORD_SUCCESS, payload: res });
        }).catch(error=>{
            confirmAlert({
                message: error.message,
                buttons: [
                    {
                        label: 'Ok',
                        onClick: () => {
    
                        }
                    }
                ]
            });
            dispatch({type: actionTypes.CHANGEPASSWORD_FAILURE, error: error});
        });
     };
}