function validate(username,password)
{
    if(username.length>0 && password.length>0)
{
    return true;
}

return false;
}
export default validate;
