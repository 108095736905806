import * as actionTypes from './skillActionTypes';

const skillReducer = (state = {}, action) => {
    switch (action.type) {
        case actionTypes.FETCH_SKILL_SUCCESS:
            return Object.assign({}, state, {
                resultSkill: action.payload,
            });
        case actionTypes.FETCH_UNASSIGNED_SKILLS_SUCCESS:
            return Object.assign({}, state, {
                unassignedData: action.payload,
            });
        default:
            return state;
    }
}
export default skillReducer;