import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import config from "../../config/config";
import DefaultImg from '../../lessons/lessonImg/default_img_upload.png';
import outlook from './../outlook.scss';
import { jsonMenu } from "./jsonMenuData";
import { Link } from "react-router-dom";
import { css } from "@emotion/core";
import SyncLoader from "react-spinners/SyncLoader";
import Avatar from 'react-avatar';
const override = css`
  display: block;
  margin: 200px auto;
  width: 100px;
  padding: '25%'
`;
class OutlookHome extends Component {
    constructor(props) {

        let urlParams = new URLSearchParams(window.location.search);
        let userName;

        if(!urlParams.get("mail")) {
            userName = localStorage.getItem("mail");
        }
        else
        {
            userName = urlParams.get("mail").toLowerCase();
        }
        localStorage.setItem("mail", userName);
        localStorage.setItem('userId', userName);


        super(props);
        this.props.homeVisibility(false);

        this.state = {
            loading: false,
            showMore: false,
            show: false,
            userName: localStorage.getItem("mail")
        };
    }

    componentDidMount = async () => {
        this.getUserName();
    };

    getUserName = () => {
        let n = this.state.userName.split('@');
        let name = n[0];
        let res;
        let result;
        if (name.includes('.')) {
            res = name.split('.');
            result = (res[0])[0].toUpperCase() + res[0].slice(1) + " " + (res[1])[0].toUpperCase() + res[1].slice(1);
        } else if (name.includes('_')) {
            res = name.split('_');
            result = (res[0])[0].toUpperCase() + res[0].slice(1) + " " + (res[1])[0].toUpperCase() + res[1].slice(1);
        } else {
            result = name[0].toUpperCase() + name.slice(1);
        }
        this.setState({ userName: result });
    };

    handleShowMore = (event) => {
        this.setState({ show: !this.state.show });
        event.stopPropagation();
        event.preventDefault();

    };

    render() {
        return (
        <div className="page-container quest-wrapper outlook-quest-wrapper">
                <div className="container-fluid mob-container">
                    {this.state.loading ?
                        <SyncLoader
                            css={override}
                            size={20}
                            color={"#0b6eb6"}
                            loading={this.state.loading} />
                        : <div className="main-container">
                         <div className="row" style={{justifyContent:'center'}}>
                               <div className="uname-circle"> 
                                   <Avatar name={this.state.userName} size="100" textSizeRatio={1.50} round={true}/>
                                   </div>
                            </div>
                            <br />
                            <div className="row" style={{ justifyContent: 'center', color: '#125296' }}>
                                <h5>{'Welcome ' + this.state.userName + "!"}</h5>
                            </div>
                            <div className="row scroll-container">

                                {
                                    jsonMenu.data
                                        ? jsonMenu.data.map((item, index) =>

                                            /*------------------------------------------------------------------------------*/

                                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 p-2">
                                                <div className="main-div">
                                                    <Link to={{
                                                        pathname: item.pathName
                                                    }}>
                                                        <div className="card custom-card" style={{ backgroundColor: item.backgroundColor }}>
                                                            <div className="card-body">
                                                                <div className="row">
                                                                    <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                                                        {
                                                                            item.image ? <img
                                                                                src={require("./../outlookImages/" + item.image)}
                                                                                className="nudge_image"
                                                                                alt={item.name} />
                                                                                : <img
                                                                                    className="nudge_image"
                                                                                    src={DefaultImg}
                                                                                    alt='Img' />
                                                                        }

                                                                    </div>
                                                                    <div className="col-7 col-sm-7 col-md-7 col-lg-7 col-xl-7">
                                                                        <div className="card-txt">
                                                                            <h3>
                                                                                <span>{item.name}</span>
                                                                            </h3>
                                                                            <div>
                                                                                {
                                                                                    item.description.length > 100 && !this.state.show ?
                                                                                        <span> <span>{item.description.substring(0, 100)}</span>
                                                                                            <span className="showmore"
                                                                                                onClick={this.handleShowMore}> More&gt;&gt;</span>
                                                                                        </span>
                                                                                        : <span> <span>{item.description}</span>
                                                                                            {item.description.length > 100 ?
                                                                                                <span className="showmore"
                                                                                                    onClick={this.handleShowMore}> Less&lt;&lt;</span>
                                                                                                : null}
                                                                                        </span>
                                                                                }

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                                                        <i className="fa fa-angle-right fa-3x pull-right"
                                                                            aria-hidden="true" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                            /*------------------------------------------------------------------------------*/
                                        )
                                        : ""}
                            </div>
                        </div>}
                </div>
            </div>

        );
    }


}


function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            //getSkills: getSkills,
        },
        dispatch
    );
}

const mapStateToProps = state => {
    return {
        //skillListData: state.outlookSkillReducer.skillListData || {}
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OutlookHome);