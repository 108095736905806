import React, { Component } from "react";
import {Col, PopoverBody, Modal, ModalBody, ModalHeader, ModalFooter, Button, Form, FormGroup, Label, Input,UncontrolledPopover
} from 'reactstrap';
import { logout, changePassword } from "./headerAction";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PasswordStrengthBar from 'react-password-strength-bar';
import {toast} from 'react-toastify';
import * as constant from '../../utils/constants';
import {changePasswordService} from './changePasswordService';
import jwt_decode from "jwt-decode";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCog } from "@fortawesome/free-solid-svg-icons";

const LoadingSpinner = () => (
    <div id="overlay">
        <div>
            <div className="spinner"> </div>
        </div>
    </div>

);

class SettingPopover extends Component {
    constructor(props) {
        super(props);
        let user = jwt_decode(localStorage.getItem("token"));
        // this.props.homeVisibility(true);
        this.state = {
            userName: user.username,
            passModalIsOpen: false,
            oldPassword: '',
            confirmPassword: '',
            inputValue: '',
            userDomain:false,
            loading: false,
            settingPopoveIsOpen:this.props.openstate,
            error: {
                oldPasswordError: '',
                newPasswordError: '',
                confirmPasswordError: ''
            }
             }
      
           

    }


        componentDidMount() {
        let name = this.state.userName.split('@');
        let n = name[0].split('.');
        // let result = name[0] + " " + n[0];
        this.setState({ userName: n[0] })
        if (name[1].split('.')[0] === "harbingergroup") {
            this.setState({ userDomain: false });
        } else {
        this.setState({ userDomain: true });
        }
        
        
        //document.addEventListener('mousedown', this.settinghandleClickOutside);
    }
    handleRequest = async () => {
        this.setState({ loading: true });
        await this.props.logOut();
        let keepKeys = ["sentToServer", "notificationToken", "appVersion"];
        let data = await this.keepSomelocalStorage(keepKeys);
        await localStorage.clear();
        await this.resetlocalStorage(keepKeys, data);
        window.location.replace("https://harbingergroup.onelogin.com");
    };

    keepSomelocalStorage = async (keepKeys) => {
        let data = [];
        keepKeys.forEach(async (value, index) => {
            data[index] = localStorage.getItem(value);
        });
        return data;
    };
    resetlocalStorage = (keepKeys, data) => {
        keepKeys.forEach(async (value, index) => {
        localStorage.setItem(value, data[index]);
        });
    };

    onClickProfile = async() => {
        this.props.history.push(
            {
                pathname: `/my-profile`,
            });
    }


    ChangePasswordtoggle = () => {
        this.props.settingsTogglePopover();
        this.setState({
        passModalIsOpen: !this.state.passModalIsOpen
        });
        this.clearFields();
    }
    validatePasswordForm = () => {
        let isError = false;
        let checkError=false;
        let tempError = this.state.error;

        let strongRegex = new RegExp("^(?=.*[A-Z])(?=.*[!@#\$%\^&\*])");

        if (this.state.oldPassword.length === 0) {
            isError = true;
            checkError=true;
            tempError.oldPasswordError = 'Old Password should not be empty';
        } else {
            isError = false;
            tempError.oldPasswordError = '';
        }

        if (this.state.confirmPassword.length === 0) {
            isError = true;
            tempError.confirmPasswordError = 'Confirm Password should not be empty.';
        } else if (this.state.confirmPassword !== this.state.inputValue) {
            isError = true;
            tempError.confirmPasswordError = "The passwords you entered do not match";
        } else if (this.state.inputValue.length === 0) {
            isError = true;
            checkError=true;
            tempError.newPasswordError = 'New Password should not be empty';
        } else if (this.state.oldPassword === this.state.inputValue) {
            isError = true;
            checkError=true;
            tempError.newPasswordError = "Old Password and New Password is same.";
        } else if (this.state.inputValue.length < 8 || this.state.inputValue.trim().length <8) {
            isError = true;
            checkError=true;
            tempError.newPasswordError = "Password must be at least 8 characters long";
        } else if (!strongRegex.test(this.state.inputValue)) {
            isError = true;
            checkError=true;
            tempError.newPasswordError = "Password should contain at least 1 uppercase and 1 special character";
        } else {
            isError = false;
            tempError.newPasswordError = '';
        }

        if (this.state.confirmPassword.length === 0) {
            isError = true;
            checkError=true;
            tempError.confirmPasswordError = 'Confirm Password should not be empty.';
        } else if (this.state.confirmPassword !== this.state.inputValue) {
            isError = true;
            checkError=true;
            tempError.confirmPasswordError = "The passwords you entered do not match";
        } else {
            isError = false;
            tempError.newPasswordError="";
            tempError.confirmPasswordError="";
        }

        this.setState({
            error: tempError
        });
        return checkError;
    };
       componentDidUpdate(prevProps) {
        if (prevProps.updatePassword !== this.props.updatePassword) {
            toast.success(this.props.updatePassword.message, constant.TOAST_CONFIGURATION);
            this.clearFields();
        }
    }



    SavePassword = () => {
        let user = jwt_decode(localStorage.getItem("token"));
        const validateError = this.validatePasswordForm();
        if (!validateError) {
            const data = changePasswordService(user._id, this.state.oldPassword,this.state.inputValue);
            // const data = {
            //     userName: localStorage.getItem('userId'),
            //     password: this.state.oldPassword,
            //     newPassword: this.state.inputValue
            // };
            
             this.props.changePassword(data);
             this.ChangePasswordtoggle();
        }
    };
    OnPropertyChange = e => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    };
   
    clearFields = () => {
        this.setState({
            oldPassword: '',
            confirmPassword: '',
            inputValue: '',
            error: {
                oldPasswordError: '',
                newPasswordError: '',
                confirmPasswordError: ''
            }
        });

    }
    render() {
        const { inputValue } = this.state;
        return (
            <div>
                 {this.state.loading ? <LoadingSpinner /> : null}
                <UncontrolledPopover id="setting-popover-element"
                    className="setting-popover"
                    placement="top"
                    trigger="focus"
                    isOpen={this.props.openstate}
                    target={this.props.id}
                    toggle={this.props.settingsTogglePopover}
                >
                    <PopoverBody id="setting-popover-element" className="setting-popover-body">
                    <span className="popovermenu" onClick={this.onClickProfile}><FontAwesomeIcon icon={faUserCog} /> View Profile</span>
                       {this.state.userDomain ?<a onClick={this.ChangePasswordtoggle}> <span className="popovermenu">
                            <i className="fa fa-key" aria-hidden="true"></i> Change password</span></a>:""} 
                        <span className="popovermenu"><a onClick={this.handleRequest}><i className="fa fa-sign-out" aria-hidden="true"></i> Logout</a></span>
                    </PopoverBody>
                </UncontrolledPopover>
                {/*Theme Modal*/}
                <Modal className="password_model"
                    isOpen={this.state.passModalIsOpen}
                    toggle={e => this.ChangePasswordtoggle(e)}>
                    <ModalHeader toggle={e => this.ChangePasswordtoggle(e)}>
                        Change Password
                             </ModalHeader>
                    <ModalBody className="contact_model_body">
                        <Form>
                            <FormGroup row>
                                <Label for="resonSelect" sm={4}>Old Password *</Label>
                                <Col sm={6}>
                                    <Input type="password" name="oldPassword" id="oldPassword" placeholder=""
                                        value={this.state.oldPassword}
                                        onChange={this.OnPropertyChange}/>
                                    <span className="Span">{this.state.error.oldPasswordError}</span>
                                </Col>
                            </FormGroup>
                            <Label style={{ fontSize: '13px', padding: '6px 0 6px 0', color: 'darkgray' }}>To conform with our strong password policy, you are required to use a sufficiently strong password,
                                                              Password must be more than 8 characters, contain 1 uppercase and 1 special character.</Label>
                            <FormGroup row>
                                <Label for="newPassword" sm={4}>New Password *</Label>

                                <Col sm={6}>
                                    <Input type="password" name="newPassword" id="newPassword" placeholder=""
                                        value={inputValue}
                                        onChange={e => {
                                            this.setState({
                                                inputValue: e.target.value,
                                            });
                                        }} />
                                    <PasswordStrengthBar
                                        password={inputValue}
                                        minLength={1}
                                        minScore={1}
                                    />
                                    <span className="Span">{this.state.error.newPasswordError}</span>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label for="exampleText" sm={4}>Confirm Password *</Label>
                                <Col sm={6}>
                                    <Input type="password" name="confirmPassword" id="confirmPassword" placeholder=""
                                        value={this.state.confirmPassword}
                                        //name="confirmPassword"
                                        onChange={this.OnPropertyChange} />
                                    <span className="Span">{this.state.error.confirmPasswordError}</span>
                                </Col>
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" className="contactBtn" onClick={this.SavePassword}>Save</Button>{' '}
                        <Button color="secondary" className="contactBtn" onClick={this.clearFields}>Clear</Button>{' '}
                    </ModalFooter>
                </Modal>
            </div>

        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            logOut: logout,
            changePassword: changePassword
        },
        dispatch
    );
}


const mapStateToProps = state => {
    return {

        status: state.headerReducer.status || [],
        updatePassword: state.headerReducer.updatePassword || []
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingPopover);


