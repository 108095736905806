import React from "react";
import {Link} from "react-router-dom";
import withBreadcrumbs from "react-router-breadcrumbs-hoc";
import config from "../src/containers/config/config";
import axios from 'axios';

let idToSkillArray = {}
let idToLessonArray = {}

const loadLessonData = () => {
    axios.get(config.api.server_url.request_url.base_url + "getAllSkillsAndLessons")
        .then(async (res) => {
            idToSkillArray = res.data.data.skills;
            idToLessonArray = res.data.data.lessons;
            localStorage.setItem("skill", JSON.stringify(idToSkillArray))
            localStorage.setItem("lesson", JSON.stringify(idToLessonArray))
        })
}

const getSkillNameById = ({match}) => (
    <span>{idToSkillArray[match.params.skill_id]}</span>
);
const getLessonNameById = ({match}) => (
    <span>{idToLessonArray[match.params.id]}</span>
);
const routes = [
    {path: '/', breadcrumb: ""},
    {path: '/lessons/quiz/:skill_id/:id'},
    {path: '/lessons/quiz/:skill_id', breadcrumb: getSkillNameById},
    {path: '/lessons/quiz/'},
    {path: '/skills/:skill_id/:id'},
    {path: '/skills/:skill_id', breadcrumb: getSkillNameById},
    {path: '/skills', breadcrumb: "My Nuggets"},
    {path: '/rating/:skill_id/:id', breadcrumb: getLessonNameById},
    {path: '/rating/:skill_id', breadcrumb: getSkillNameById},
    {path: '/rating', breadcrumb: "My Nuggets"},
    {path: '/outlook-home', breadcrumb: "Home"},
    {path: '/outlook-all-skills', breadcrumb: "Back"},
    {path: '/outlook-completed-skills', breadcrumb: "Back"},
    {path: '/outlook-pending-skills', breadcrumb: "Back"},
    {path: '/outlook-recently-added-nuggets', breadcrumb: "Back"},
    {path: '/outlook-popular-nuggets', breadcrumb: "Back"},
    {path: '/outlook-feedback', breadcrumb: "Back"},
    {path: '/outlook-reports', breadcrumb: "Back"}


];
const PureBreadcrumbs = ({breadcrumbs}) => {
    idToSkillArray = localStorage.getItem("skill") ? JSON.parse(localStorage.getItem("skill")) : {};
    idToLessonArray = localStorage.getItem("lesson") ? JSON.parse(localStorage.getItem("lesson")) : {};

    return (
        <div className="breadcrumb">
            {breadcrumbs.map(({breadcrumb, match}, index) => (
                <div className="breadMain" key={match.url}>

                    <Link to={caseMapping(match)}>{breadcrumb}</Link>
                    {index < breadcrumbs.length - 1 && " > "}
                </div>
            ))}
        </div>
    )
};

const caseMapping = (matchURL) => {
    switch (matchURL.path) {
        case "/":
        case "/login":
        case "/skills":
        case "/rating":
            return "/skills";
        case "/rating/:skill_id/:id":
        case "/rating/:skill_id":
            return replacePath(matchURL.url, "rating", "skills");
        case "/outlook-all-skills":
            return replacePath(matchURL.url, "outlook-all-skills", "outlook-home");
        case "/outlook-completed-skills":
            return replacePath(matchURL.url, "outlook-completed-skills", "outlook-home");
        case "/outlook-pending-skills":
            return replacePath(matchURL.url, "outlook-pending-skills", "outlook-home");
        case "/outlook-feedback":
            return replacePath(matchURL.url, "outlook-feedback", "outlook-home");
        case "/outlook-reports":
            return replacePath(matchURL.url, "outlook-reports", "outlook-home");
        case "/outlook-recently-added-nuggets":
            return replacePath(matchURL.url, "outlook-recently-added-nuggets", "outlook-home");
        case "/outlook-popular-nuggets":
            return replacePath(matchURL.url, "outlook-popular-nuggets", "outlook-home");
        default:
            return matchURL.url;
    }
}

const replacePath = (url, from, to) => {
    return url.replace(from, to);
}
export default withBreadcrumbs(routes, {disableDefaults: true})(PureBreadcrumbs,);

export {
    loadLessonData
}