import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getUserSkillsReports, getUserRecentPendingSkillsData } from "./reportsAction";
import './../outlook.scss';
import { css } from "@emotion/core";
import SyncLoader from "react-spinners/SyncLoader";
import MyLearningProgressOutlook from './myLearningsOutllook';


const override = css`
  display: block;
  margin: 200px auto;
  width: 100px;
  padding: '25%'
`;

class Reports extends Component {
    constructor(props) {
        super(props);
        this.props.homeVisibility(false);
        this.state = {
            loading: false
        };
    }

    componentDidMount = async () => {
        if (!localStorage.getItem("mail")) {
           // alert("User Not Logged In!!!");
            this.props.history.push("/");
        }
        this.setState({ loading: true });
        await this.props.getUserSkillsReports();
        await this.props.getUserRecentPendingSkillsData();

        if (this.props.skillsReportData) {
            let loadingTime = this;
            setTimeout(function () {
                loadingTime.setState({ loading: false });
            }, 200);
        }
        window.speechSynthesis.cancel();
    };

    render() {

        let recentPendingSkills;

        if (!this.props.OutlookRecentPendingData.length) {
            return false;
        }

        recentPendingSkills = this.props.OutlookRecentPendingData;
          return (
            <div className="page-container quest-wrapper outlook-quest-wrapper">
                <div className="container-fluid mob-container">
                    {this.state.loading ?
                        <SyncLoader
                            css={override}
                            size={20}
                            color={"#0b6eb6"}
                            loading={this.state.loading} />
                        : <div className="main-container">
                            <div className="outlook-courses-wrapper">
                                <div className="row scroll-container-inner">

                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 p-2">
                                        <div className="outlook-nugget-status-wrapper">
                                            <div className="row" style={{ "margin-top": "6%" }}>
                                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-4">
                                                    <div className="outlook-assigned-bg status-circle">
                                                        {this.props.skillsReportData ? this.props.skillsReportData.total_skills : 0}
                                                    </div>
                                                    <div className="outlook-status-text">
                                                        My Total Nuggets
                                                        </div>
                                                </div>

                                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-4">
                                                    <div className="outlook-completed-bg status-circle">
                                                        {this.props.skillsReportData ? this.props.skillsReportData.completed_skills : 0
                                                        }</div>

                                                    <div className="outlook-status-text">Completed Nuggets</div>
                                                </div>

                                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-4">
                                                    <div className="outlook-in-progress-bg status-circle">
                                                        {this.props.skillsReportData ? this.props.skillsReportData.incomplete_skills : 0}
                                                    </div>

                                                    <div className="outlook-status-text">Incomplete Nuggets</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <MyLearningProgressOutlook myLearningData={recentPendingSkills} />

                                </div>
                            </div>

                        </div>
                    }

                </div>
            </div>

        );
    }


}


function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getUserSkillsReports: getUserSkillsReports,
            getUserRecentPendingSkillsData: getUserRecentPendingSkillsData
        },
        dispatch
    );
}

const mapStateToProps = state => {
    return {
        skillsReportData: state.reportsReducer.skillsReportData || null,
        OutlookRecentPendingData: state.reportsReducer.OutlookRecentPendingData || []
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Reports);