import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import AddRating from "./ratingActions";
import {
  getNextLearningBite,
} from "../topics/topicsActions";
import "./rating.css";
import ReactStars from "react-rating-stars-component";
import { Card, Row, Col } from "reactstrap";
import jwt_decode from "jwt-decode";
import FailAssesmentView from "./failAssesmentView";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import CircularProgressBar from "../SliderThemes/ChangingProgressProvider";

class RatingComponent extends Component {
  constructor(props) {
    super(props);

    this.props.homeVisibility(false);
    this.ratingChanged = this.ratingChanged.bind(this);

    this.state = {
      counter: 0,
      numberOfStars: 5,
      currentValues: [
        { isChecked: "checked", value: 0 },
        { isChecked: "false", value: 1 },
        { isChecked: "false", value: 2 },
        { isChecked: "false", value: 3 },
        { isChecked: "false", value: 4 },
      ],
      checked: "false",
      rating: 0,
      comment: "",
      redirect: false,
      userScore: Math.round(
        (localStorage.getItem("userResult") /
          localStorage.getItem("totalQuestions")) *
          100
      ),
      correctAnswerCount: localStorage.getItem("userResult"),
      totalquestions: localStorage.getItem("totalQuestions"),
      passingScore: Math.round(
        (localStorage.getItem("passingResult") /
          localStorage.getItem("totalQuestions")) *
          100
      ),
      endTime: new Date().getTime(),
      nextLearningBite: "",
      skillId: this.props.match.params.skill_id,
      isNextLearningBite: false,
      isSubmitted: false,
    };
  }

  async componentDidMount() {
    this.setState({
      userScore: Math.round(
        (localStorage.getItem("userResult") /
          localStorage.getItem("totalQuestions")) *
          100
      ),
      correctAnswerCount: localStorage.getItem("userResult"),
      totalquestions: localStorage.getItem("totalQuestions"),
      passingScore: Math.round(
        (localStorage.getItem("passingResult") /
          localStorage.getItem("totalQuestions")) *
          100
      ),
    });

    let lesson_id = this.props.match.params.id;
    await this.props.getNextLearningBite(lesson_id);
    if (this.props.lessonData !== "") {
      const lessons = this.props.lessonData;
      this.setState({
        nextLearningBite: lessons._id,
      });
    }
  }

  getUserName() {
    if (localStorage.getItem("token")) {
      let user = jwt_decode(localStorage.getItem("token"));
      let name = "";
      if (user.firstName && user.firstName != "") {
        if (user.lastName && user.lastName != "") {
          name = user.firstName + " " + user.lastName;
        } else {
          name = user.firstName;
        }
      }
      return name.toUpperCase();
    } else {
      return "";
    }
  }

  commentChecker = (e) => {
    this.setState({ comment: e.target.value });
  };

  ratingChanged(newRating) {
    this.setState({ rating: newRating });
  }
  ratingChecked(e) {
    this.setState({
      rating: e.target.getAttribute("name"),
      isChecked: this.state.currentValues.isChecked === "checked",
    });
  }
  // handleClick = () => {
  //   let user = jwt_decode(localStorage.getItem("token"));
  //   let skillId = this.props.match.params.skill_id;
  //   if(localStorage.getItem("nudgeId")!=="undefined" && localStorage.getItem("nudgeId")!=="" && localStorage.getItem("nudgeId")!== null){
  //     let data = {
  //       userId: user.username,
  //       nudgeId: localStorage.getItem("nudgeId")
  //     }
  //     this.props.OptOutNudge(data);
  //   }
  //   this.props.history.push(`/skills/${skillId}`)
  // };
  renderRedirect = () => {
    setTimeout(() => {
      if (this.state.redirect) {
        this.props.history.push(`/skills/${this.state.skillId}`)
      }
  }, 3000);
    
  };
  onClickCancel = () => {
    this.props.history.push(`/skills/${this.state.skillId}`);
  }
  submitRating = (e) => {
    e.preventDefault();
    const { dispatch } = this.props;
    const rating = this.state.rating;
    const comment = this.state.comment;

    const experiences = {
      learner_id: localStorage.getItem("learner_id"),
      lesson_id: localStorage.getItem("lesson_id"),
      rating: rating,
      feedback: comment,
    };

   // if (rating > 0 || comment.trim().length > 0) {
    if (!this.state.isSubmitted) {
      this.props.AddRating(experiences);
    }
    this.setState({
      rating: 0,
      comment: "",
      isSubmitted:true
    });
    if(this.state.nextLearningBite === "" || this.state.nextLearningBite === undefined){
      this.setState({
        redirect: true
      });
    }
    return;
  };

  render() {
    return (
      <div>
        {/* <Breadcrumbs /> */}
        <div className="kniferating-quest-wrapper">
          {this.renderRedirect()}
          <form onSubmit={this.submitRating} className="height-100">
            <div className="basic-content-wrapper">
              <section className="">
                <div className="review-txt">
                  <div>
                    {/* <br /> */}
                    <Row className="display">
                      <Card className="rating">
                        <div className='Div_Border_Sad'>

                          <Row>
                            <Col lg={12}>
                              <Row>
                                <Col lg={6} className="center_image">
                                  {this.state.userScore >=
                                  this.state.passingScore ? (
                                    <img
                                      src={require("../../images/quizResult/Successful.png")}
                                      alt=""
                                      className="img-fluid img-thumbnail  my-3"
                                      // style={{ width: "70%" }}
                                    ></img>
                                  ) : (
                                    <img
                                      src={require("../../images/quizResult/Failure.PNG")}
                                      alt=""
                                      className="img-fluid img-thumbnail  my-3"
                                      // style={{ width: "70%" }}
                                    ></img>
                                  )}
                                </Col>

                                <Col lg={6}>
                                  <div className="card-align middle-center">
                                    {this.state.userScore >=
                                    this.state.passingScore ? (
                                      <div>
                                        {" "}
                                        Congratulations !! <br />{" "}
                                        {this.getUserName()}! <br /> You have
                                        successfully cleared the quiz with
                                        <div className="score">
                                          {" "}
                                          {this.state.userScore}%{" "}
                                        </div>
                                      </div>
                                    ) : (
                                      <FailAssesmentView
                                        userName={this.getUserName()}
                                        score={this.state.userScore}
                                      />
                                    )}

                                    {/* <div>  Your score </div> */}
                                    <div className="passingScore">
                                      {" "}
                                      Passing score {this.state.passingScore}% .
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </div>
                        <div className="rate-exp card-align pass_message">
                          Rate your experience
                        </div>
                        <div className="rating-stars">
                          <center>
                            <fieldset className="rating">
                              <ReactStars
                                count={5}
                                onChange={this.ratingChanged}
                                size={50}
                                half={true}
                                emptyIcon={<i className="far fa-star"></i>}
                                halfIcon={
                                  <i className="fa fa-star-half-alt"></i>
                                }
                                fullIcon={<i className="fa fa-star"></i>}
                                onClick={(newRating) => {
                                  this.setState({ rating: newRating });
                                }}
                                value={this.state.rating}
                              />
                            </fieldset>
                          </center>
                        </div>
                        <div className="form-group">
                          <div className="card-align pass_message">
                            Please send us your feedback on topic and its
                            learning bites
                          </div>
                          <label
                            htmlFor="exampleFormControlTextarea1"
                            className="rating-label p-3 pass_message"
                          >
                            Add a comment <span>(optional)</span>
                          </label>
                          <div className="p-3">
                            <textarea
                              className="form-control"
                              id="exampleFormControlTextarea1"
                              rows="3"
                              placeholder="Type your feedback"
                              onKeyUp={this.commentChecker}
                            />
                          </div>
                        </div>
                        <div className="w3-display-bottommiddle" onClick={this.submitRating}>
                          <div />
                          <div className="submit-btn-new margin-bottom-12px">
                            <button type="submit" className="btn btn-success" disabled={((this.state.rating === 0) && (this.state.comment.trim().length === 0)) || this.state.isSubmitted}>
                              Submit
                            </button>
                            {/* {this.state.isOptOutAllowed ?
                              <button type="" className="btn btn-success-rating"
                                style={{
                                  color: " #1e7e34",
                                  backgroundColor: "white",
                                  border: "2px solid #1e7e34",
                                  marginLeft: "10px"

                                }} onClick={this.handleClick}>
                                {" "}
                                Opt out Next schedules
                              </button>
                              : */}

                            {(this.state.nextLearningBite !== "" && this.state.nextLearningBite !== undefined)?(
                              <div >
                                <br></br>
                                <div className="nextbtn-center">
                                  {<p>Up Next</p>}
                                </div>
                                <CircularProgressBar skill_Id={this.state.skillId}
                                  nextLearningBite={this.state.nextLearningBite} />

                                <div className="nextbtn-center">
                                    <div className="cancelBtnBlock" onClick={e => (this.onClickCancel())}>
                                      <div>
                                        <button className="nextbtn-center">Cancel</button>
                                      </div>
                                    </div>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </Card>
                    </Row>
                  </div>
                </div>
              </section>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

RatingComponent.propTypes = {
  result: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      AddRating: AddRating,
      getNextLearningBite: getNextLearningBite,
    },
    dispatch
  );
}
const mapStateToProps = (state) => {
  return {
    result: state.ratingReducer.result,
    lessonData: state.topicReducer.lessonData || [],
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RatingComponent);
