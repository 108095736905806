import React, { Component } from 'react';
import { connect } from "react-redux";
import { getTopicsByLessonId, postUserLesson, getNudgeData, postUserNudgeLesson, getNudge,getNextLearningBite } from "./topicsActions";
import { getQuizData } from '../quiz/quizActions';
import 'react-magic-slider-dots/dist/magic-dots.css';
import NullView from "./NullView";
import shortid from "shortid";
import { css } from "@emotion/core";
import SyncLoader from "react-spinners/SyncLoader";
import jwt_decode from "jwt-decode";
const jwt = require("jsonwebtoken");
const override = css`
  display: block;
  margin: 200px auto;
  width: 100px;
  padding: '25%'
`;


class FullScreenTopicsComponent extends Component {
    constructor(props) {
        super(props);
        let user = jwt_decode(localStorage.getItem("token"));
        this.props.homeVisibility(false)
        this.state = {
            data: [],
            componentName: "",
            component: "",
            loading: false
        }
        this.username = user.username;
    }


    async componentDidMount() {
        if (!localStorage.getItem('token')) {
          //  alert("User Not Logged In!!!");
            this.props.history.push('/');
        }
        this.setState({ loading: true })
        const { dispatch } = this.props;
        
        await dispatch(getTopicsByLessonId(this.props.match.params.id));
        await dispatch(getNextLearningBite(this.props.match.params.id));
        await dispatch(getQuizData(this.props.match.params.id));
       
        const { result,  lessonData, quizData } = this.props;
        let skills_data = "";
        const queryParams = new URLSearchParams(window.location.search);
        if (queryParams.get("data")) {
            skills_data = jwt.verify(queryParams.get("data"), "myuserdatabase");
            localStorage.setItem("nudgeId",skills_data.nudgeId);
            localStorage.setItem("scheduleDate",new Date(skills_data.scheduleDate));
            localStorage.setItem("iteration",skills_data.iteration);
        }
        if(localStorage.getItem("nudgeId")){
           let lessonData = {
            lessonId: this.props.match.params.id,
            userId: this.username,
            nudgeId: localStorage.getItem("nudgeId"),
            isAttempted: true,
            attemptedDate: new Date(),
          };
          await dispatch(postUserNudgeLesson(lessonData));
        }
        if (!this.props.location.lessonsTheme && this.props.result && this.props.result.data && this.props.result.data.component) {
            localStorage.setItem("lessonsTheme", this.props.result.data.component.name);
        } else {
            localStorage.setItem("lessonsTheme", "basicSlider");
        }
        
       
        this.setState({
            componentName: (this.props.location.lessonsTheme ?
                this.props.location.lessonsTheme : localStorage.getItem("lessonsTheme"))
        }, () => {
            import(`../SliderThemes/${this.state.componentName}/${this.state.componentName}.js`)
                .then(Component => {
                    this.setState({
                        component:
                            <Component.default
                                key={shortid.generate()}
                                sliderPages={result.data}
                                saveUserLesson={data => this.saveUserLesson(data)}
                                saveUserNudgeLesson={data => this.saveUserNudgeLesson(data)}
                                lessons={lessonData}
                                history={this.props.history}
                                id={this.props.match.params.skill_id}
                                lessonId={this.props.match.params.id}
                                checkIfQuizGivenToday={quizData && quizData.data ? quizData.data.is_quiz_given_today : false}
                                bg={this.props.result.data.backgroundImage ? this.props.result.data.backgroundImage._id : ""}
                            />
                    });
                })
                .catch(error => {
                    this.setState({
                        component: (
                            <NullView key={shortid.generate()} />
                        )
                    });
                });
        })

        if (this.props.result) {
            this.setState({ loading: false })
        }
    }

    async saveUserLesson(data) {
        const { dispatch } = this.props;
        await dispatch(postUserLesson(data));
    }

    async saveUserNudgeLesson(data) {
        const { dispatch } = this.props;
        await dispatch(postUserNudgeLesson(data));
    }

    render() {

        const { component } = this.state;
        return (

            <div className="views" id="views">
                {component === "" ? (
                    <SyncLoader
                        css={override}
                        size={20}
                        color={"#0b6eb6"}
                        loading={this.state.loading} />
                ) : (
                    component
                )
                }

            </div>)
    }
}

const mapStateToProps = state => {

    return {
        result: state.topicReducer.result || [],
        nudge: state.topicReducer.nudge || [],
        lessonData: state.topicReducer.lessonData || [],
        quizData: state.questionReducer.quizData,
    }
};

export default connect(mapStateToProps)(FullScreenTopicsComponent);


