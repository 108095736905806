import React, { Component } from "react";
import { Link } from "react-router-dom";
import config from "../config/config";
import DefaultImg from './../lessons/lessonImg/default_img_upload.png'


class SkillCardComponent extends Component {
    constructor(props) {
        super(props);
       
        this.state = {
            showMore: false,
            show: false
        };
        this.handleShowMore = this.handleShowMore.bind(this);
    }

    handleShowMore(event) {
        this.setState({ show: !this.state.show });
        event.stopPropagation();
        event.preventDefault();

    }
    componentDidMount() {
        if (this.props.item && this.props.item.description.length > 100) {
            this.setState({ showMore: true });
        }
    }

    render() {
        return (
            <div
                className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
            >
                <Link
                    to={{
                        pathname: `/skills/${this.props.item._id}`,
                        icon_id: this.props.item.image,
                        skill_name:this.props.item.name
                    }}
                >
                    <div className="card my-nugget">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                    {this.props.item.image ? <img
                                        src={config.api.server_url.request_url.base_url + "getUploadedFile/" + this.props.item.image}
                                        className="nudge_image"
                                        alt={this.props.item.name}

                                    /> : <img
                                            className="nudge_image"
                                            src={DefaultImg}
                                            alt='Img' />}

                                </div>
                                <div className="col-7 col-sm-7 col-md-7 col-lg-7 col-xl-9">
                                    <div className="card-txt">
                                        <h3>
                                            <span>{this.props.item.name}</span>
                                        </h3>
                                        <div>
                                            {
                                                this.state.showMore && !this.state.show ?
                                                    <span> <span>{this.props.item.description.substring(0, 100)}</span>
                                                        <span className="showmore" onClick={this.handleShowMore}> More&gt;&gt;</span>
                                                    </span>
                                                    :
                                                    <span> <span>{this.props.item.description}</span>
                                                        {this.props.item.description.length > 100 ?
                                                            <span className="showmore" onClick={this.handleShowMore}> Less&lt;&lt;</span>
                                                            : null}
                                                    </span>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Link>
            </div >
        );
    }
}

export default SkillCardComponent;