import React, { Component } from "react";
import {
  logout,
  getUserNotifications,
  setUserNotificationsAsRead,
  getContactReasons,
  saveContactDetails,
  setIndividualNotificationsAsRead
} from "./headerAction";
import { connect } from "react-redux";
import { Effect } from "react-notification-badge";
import NotificationBadge from "react-notification-badge";
import { bindActionCreators } from "redux";
import { Tooltip } from "reactstrap";
import {
  Row,
  Col,
  Popover,
  PopoverHeader,
  PopoverBody,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import config from "./../config/config";
import { isBrowser } from "react-device-detect";
import SettingPopover from "./settingPopover";
import { socketConfig } from "../../socketConfig";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
let socket = socketConfig(process.env.REACT_APP_BASE_URL);

toast.configure();
const TOAST_CONFIGURATION = {
  autoClose: 2000,
  hideProgressBar: true,
  position: "bottom-right",
};

class NewHeader extends Component {
  constructor(props) {
    super(props);
    let user = jwt_decode(localStorage.getItem("token"));
    this.state = {
      headerText: "",
      homeVisibility: "",
      userName: user.username,
      isOpen: false,
      notificationCount: 0,
      isOpenPopover: false,
      isSettingsPopoverOpen: false,
      contactAdminOpen: false,
      contactreasons: [],
      selectreasonId: "",
      reasondecription: "",
      contacttooltipOpen: false,
      settingstooltipopen: false,
      showSideBar: isBrowser ? true : false,
      errors: {
        contactForReason: "",
        contactDescriptions: "",
      },
    };
    this.handleReasonChange = this.handleReasonChange.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.showSideBarToggler = this.showSideBarToggler.bind(this);
  }

  componentDidMount() {
    let n = this.state.userName.split("@");
    let user = jwt_decode(localStorage.getItem("token"));
    //Receive notifications sent recently
    socket.on("Notification-" + user._id, () => {
      this.getNotifications();
    });

    let name = n[0];
    let res;
    let result;
    if (name.includes(".")) {
      res = name.split(".");
      result = res[0] + " " + res[1];
    } else if (name.includes("_")) {
      res = name.split("_");
      result = res[0] + " " + res[1];
    } else {
      result = name;
    }
    this.setState({ userName: result });

    //For get user notifications
    this.getNotifications();
    // this.timerID = setInterval(
    //     this.getNotifications, constant.INTERVAL_TIME);
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentDidUpdate(prevProps) {
    const { status } = this.props;
    if (prevProps.status !== status) {
      if (status === 200) {
        window.location.href = "/";
      }
    }
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillMount() {
    this.setState({
      headerText: this.props.title,
      homeVisibility: this.props.homeVisibility,
    });
    //For clear ineteval timer.
    // clearInterval(this.timerID);
    // document.removeEventListener('mousedown', this.handleClickOutside);
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  toggleToolTip = () => {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen,
    });
  };
  toggleSettingToolTip = () => {
    this.setState({
      tooltipSettingOpen: !this.state.tooltipSettingOpen,
    });
  };

  showSideBarToggler = () => {
    this.props.sideBarprops();
    this.setState({
      showSideBar: !this.state.showSideBar,
    });
  };

  getNotifications = async () => {
    await this.props.getUserNotifications();
    let count = 0;
    const { notificationData } = this.props;
    for (let i = 0; i < notificationData.notifications.length; i++) {
      if (!notificationData.notifications[i].readStatus) {
        count++;
      }
    }
    this.setState({
      notificationCount: count,
    });
  };

  //
  togglePopover = async () => {
    if (this.state.isOpenPopover) {
      await this.setReadNotification();
      //For get user notifications
      await this.getNotifications();
    }

    this.setState({
      isOpenPopover: !this.state.isOpenPopover,
      isSettingsPopoverOpen: false,
    });
  };

  settingsTogglePopover = () => {
    this.setState({
      isSettingsPopoverOpen: !this.state.isSettingsPopoverOpen,
      isOpenPopover: false,
    });
  };

  handleClickOutside = async (event) => {
    let id = event.target.id;
    if (id !== "popover-element") {
      if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
        if (this.state.isSettingsPopoverOpen) {
          await this.settingsTogglePopover();
        }
        if (this.state.isOpenPopover) {
            this.togglePopover();
            // this.setState({
            //     isOpenPopover: false
            // });

            // await this.setReadNotification();
            // //For get user notifications
            // await this.getNotifications();
        }
      }
    }
  };
  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  readNotification = async(e,item, index) =>{
    if(!item.readStatus){
      await this.props.setIndividualNotificationsAsRead(item);
      await this.getNotifications();
    }
  }

  //For read user notifications
  setReadNotification = async () => {
    await this.props.setUserNotificationsAsRead();
  };

  formatDate = (string) => {
    let options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(string).toLocaleDateString([], options);
  };

  contactAdminModalToggle = () => {
    this.getContactReason();
    this.setState({
      contactAdminOpen: !this.state.contactAdminOpen,
      isSettingsPopoverOpen: false,
      isOpenPopover: false,
    });
  };
  getContactReason = async () => {
    await this.props.getContactReasons();
    const { getContactReasonsData } = this.props;
    this.setState({
      contactreasons: getContactReasonsData,
    });
  };
  handleReasonChange = (event) => {
    const tempError = this.state.errors;
    tempError.contactForReason = "";
    this.setState({
      selectreasonId: event.target.value,
      errors: tempError,
    });
  };
  handleDescriptionChange = (event) => {
    const tempError = this.state.errors;
    tempError.contactDescriptions = "";
    this.setState({
      reasondecription: event.target.value,
      errors: tempError,
    });
  };
  validate = () => {
    let isError = false;
    let tempError = this.state.errors;
    if (
      this.state.selectreasonId === "" ||
      this.state.selectreasonId === "Select reason"
    ) {
      isError = true;
      tempError.contactForReason = "Please select reason for contact";
    } else {
      tempError.contactForReason = "";
    }
    if (this.state.reasondecription.trim() === "") {
      isError = true;
      tempError.contactDescriptions = "Please give reason description";
    } else {
      tempError.contactDescriptions = "";
    }
    this.setState({
      errors: tempError,
    });

    return isError;
  };
  contactToAdmin = event => {
    event.preventDefault();
    const validateError = this.validate();
    if (!validateError) {
      let contactDetails = {
        contactReason: this.state.selectreasonId,
        description: this.state.reasondecription,
      };
      this.props.saveContactDetails(contactDetails);
      this.contactAdminModalToggle();
      this.setState({
        selectreasonId: "",
        reasondecription: "",
      });
      toast.success(
        "Request subbmitted successfully. Admin will contact you shortly.",
        TOAST_CONFIGURATION
      );
    }
  };

  render() {
    let organizationLogoId = localStorage.getItem("organizationLogoId");
    let textFont = localStorage.getItem("unameFont");

    let notificationCountStyle = {
      top: "",
      right: "",
    };
    var reasonoptions = [];
    if (this.state.contactreasons.length > 0) {
      reasonoptions = this.state.contactreasons.map((item, index) => {
        return (
          <option value={item._id} key={index}>
            {" "}
            {item.reason}
          </option>
        );
      });
    }

    return (
      <div
        className="header-wrapper"
        style={{ background: localStorage.getItem("headerColor") }}
      >
        <div className="headerBlock">
          <div className="logo-wrapper">
            {organizationLogoId ? (
              <div>
                <div className="menu-icon" onClick={this.showSideBarToggler}>
                  <img
                    src={require("../../images/dashBoardimages/menu-icon.svg")}
                    alt=""
                  />
                </div>
                <img
                  className=""
                  src={
                    config.api.server_url.request_url.base_url +
                    "getUploadedFile/" +
                    organizationLogoId
                  }
                  alt="Plato Logo"
                />
              </div>
            ) : (
              ""
            )}
          </div>

          <div className=" order2 mobileheader webheader float-right">
            {/* {isMobile ? (
              <div>
                <div
                  className="float-left mobile-menu-icon"
                  onClick={this.showSideBarToggler}
                >
                  <img
                    src={require("../../images/dashBoardimages/menu-icon.svg")}
                    alt=""
                  />
                </div>
                <img
                  className="float-left mobile-menu-logo"
                  src={
                    config.api.server_url.request_url.base_url +
                    "getUploadedFile/" +
                    organizationMobileLogoId
                  }
                  alt="Plato Logo"
                />
              </div>
            ) : (
              ""
            )} */}

            <div className="float-right icon-section">
              <div
                className="white-bg logout-icon"
                onClick={this.contactAdminModalToggle}
                id="contactToAdmin"
              >
                <img
                  src={require("../../images/dashBoardimages/mail.svg")}
                  alt=""
                />
              </div>
              <Tooltip
                placement="bottom"
                isOpen={this.state.tooltipOpen}
                target="contactToAdmin"
                toggle={this.toggleToolTip}
              >
                Contact Administrator{" "}
              </Tooltip>

              <div
                ref={this.setWrapperRef}
                id="popoverNotification"
                className="notification-icon white-bg"
                title={this.state.notificationCount + " new notification"}
              >
                <i
                  className="fa fa-bell-o fa-2x bell-icon"
                  aria-hidden="true"
                  style={{ color: textFont }}
                >
                  <NotificationBadge
                    className="notification-count"
                    count={this.state.notificationCount}
                    effect={Effect.SCALE}
                    frameLength={15.0}
                    style={notificationCountStyle}
                  />
                </i>
              </div>
              <Popover
                id="popover-element"
                className="notification-popover top-space"
                placement="top"
                isOpen={this.state.isOpenPopover}
                target="popoverNotification"
                toggle={this.togglePopover}
              >
                <PopoverHeader id="popover-element">
                  <Row id="popover-element">
                    <Col id="popover-element" lg={10} xs={10}>
                      <span
                        id="popover-element"
                        className="popover-title top-space "
                      >
                        Notifications
                      </span>
                    </Col>
                    {/* <Col lg={2} xs={2}>
                      <span
                        onClick={this.togglePopover}
                        className="fa fa-times span-remove"
                      ></span>
                    </Col> */}
                  </Row>
                </PopoverHeader>
                <PopoverBody
                  id="popover-element"
                  className="notification-popover-body"
                >
                  {this.props.notificationData ? (
                    this.props.notificationData.notifications.length > 0 ? (
                      this.props.notificationData.notifications.map(
                        (item, index) => {
                          let lineBreak = "";
                          if (index !== 0) {
                            lineBreak = <hr id="popover-element"></hr>;
                          }
                          return (
                            <div id="popover-element" onClick={e=> this.readNotification(e,item,index)}>
                              {lineBreak}
                              <span
                                id="popover-element"
                                className={item.readStatus ? "" : "font-bold"}
                                style={{ fontSize: "larger" }}
                              >
                                {item.title}
                              </span>
                              <br />
                              {/* <span id="popover-element"
                                                            className={item.readStatus ? "" : "font-bold"}>{item.text}</span> */}

                              <span
                                id="popover-element"
                                className={
                                  item.readStatus
                                    ? "notification-text"
                                    : "font-bold notification-text"
                                }
                                dangerouslySetInnerHTML={{
                                  __html: item.text,
                                }}
                              ></span>
                              <br />
                              <span
                                id="popover-element"
                                className={item.readStatus ? "" : "font-bold"}
                              >
                                {this.formatDate(item.notificationDate)}
                              </span>
                            </div>
                          );
                        }
                      )
                    ) : (
                      <div id="popover-element">No Data</div>
                    )
                  ) : (
                    <div id="popover-element">No Data</div>
                  )}
                </PopoverBody>
              </Popover>
              <div
                className="white-bg logout-icon"
                id="settings"
                onClick={this.settingsTogglePopover}
              >
                <img
                  src={require("../../images/dashBoardimages/gear.svg")}
                  alt=""
                />
              </div>
              <SettingPopover
                openstate={this.state.isSettingsPopoverOpen}
                settingsTogglePopover={this.settingsTogglePopover}
                id="settings"
                history={this.props.history}
              />
              <Tooltip
                placement="bottom"
                isOpen={this.state.tooltipSettingOpen}
                target="settings"
                toggle={this.toggleSettingToolTip}
              >
                Settings{" "}
              </Tooltip>
            </div>
          </div>
          {/*Contact Modal*/}
          <Modal
            className="contact_model"
            isOpen={this.state.contactAdminOpen}
            toggle={(e) => this.contactAdminModalToggle(e)}
            backdrop={true}
          >
            <ModalHeader toggle={(e) => this.contactAdminModalToggle(e)}>
              Contact Administrator
            </ModalHeader>
            <ModalBody className="contact_model_body">
              <Form>
                <FormGroup row>
                  <Label
                    for="reasonSelect"
                    className="col-sm-4 col-md-3  col-lg-3 my-1"
                  >
                    Reason For Contact
                  </Label>
                  <Col className="col-sm-8 col-md-7  col-lg-6">
                    <Input
                      type="select"
                      name="reasonSelect"
                      id="reasonSelect"
                      onChange={this.handleReasonChange}
                    >
                      <option>Select reason</option>
                      {reasonoptions}
                    </Input>
                    <span className="Span">
                      {this.state.errors.contactForReason}
                    </span>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label
                    for="exampleText"
                    className="col-sm-4 col-md-3  col-lg-3 my-1"
                  >
                    Description
                  </Label>
                  <Col className="col-sm-8 col-md-7  col-lg-6">
                    <Input
                      type="textarea"
                      name="descriptionText"
                      id="descriptionText"
                      value={this.state.reasondecription}
                      onChange={this.handleDescriptionChange}
                    />
                    <span className="Span">
                      {this.state.errors.contactDescriptions}
                    </span>
                  </Col>
                </FormGroup>
              </Form>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                className="contactBtn"
                onClick={(e) => this.contactToAdmin(e)}
              >
                Send
              </Button>{" "}
            </ModalFooter>
          </Modal>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logOut: logout,
      getUserNotifications: getUserNotifications,
      setUserNotificationsAsRead: setUserNotificationsAsRead,
      setIndividualNotificationsAsRead:setIndividualNotificationsAsRead,
      getContactReasons: getContactReasons,
      saveContactDetails: saveContactDetails,
    },
    dispatch
  );
}

const mapStateToProps = (state) => {
  return {
    result: state.headerReducer.result || [],
    status: state.headerReducer.status || [],
    notificationData: state.headerReducer.notificationData || null,
    notificationReadData: state.headerReducer.notificationReadData || null,
    readData: state.headerReducer.readData || null,
    getContactReasonsData: state.headerReducer.getContactReasonsData || null,
    saveContactData: state.headerReducer.saveContactData || null,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewHeader);
