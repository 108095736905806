import * as actionTypes from './allSkillsActionType';

const allSkillsReducer = (state = {}, action) => {
    switch (action.type) {
        case actionTypes.FETCH_SKILL_SUCCESS:
            return Object.assign({}, state, {
                skillListData: action.payload,
            });
        default:
            return state;
    }
}
export default allSkillsReducer;