import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const CircularProgressBar = (props) => {
  const [progress, setProgress] = useState(0);
  const [skill_Id, setSkillId] = useState(props.skill_Id);
  const [nextLearningBite, setNextLearningBite] = useState(props.nextLearningBite);
  const history = useHistory();

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => prevProgress + 1);
    }, 1000);

    // Clear interval after 1 minute (60 seconds)
    setTimeout(() => {
      clearInterval(interval);
      redirect();
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const redirect = () => {
    //history.push(`/lessons/${skill_Id}/${nextLearningBite}`);
    window.location.href = `/lessons/${skill_Id}/${nextLearningBite}`;
   // window.location.reload(false);
  };


  return (
    <div className="circulerPrgressbar" onClick={redirect}> 
      <CircularProgressbarWithChildren
        value={progress}
        maxValue={60}
        styles={buildStyles({
          pathTransitionDuration: 0.15,
          pathColor: "#000000",
          trailColor: "#B9B8B7",
        })}
      >
        <img
          src={require("./play-icon-black.png")}
          className="img"
        />
      </CircularProgressbarWithChildren>
    </div>
  );
};

export default CircularProgressBar;
