import * as actionTypes from './lessonActionTypes';
import axios from 'axios';
import config from "./../config/config";

export function getLessons(url) {
    return function (dispatch) {
        return axios.get(config.api.server_url.request_url.base_url + url,{
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        })
            .then((res) => {
                dispatch({ type: actionTypes.FETCH_LESSON_SUCCESS, payload: res.data });
            })
    };
}

export function getNudge(url) {
    return function (dispatch) {
        return axios.get(config.api.server_url.request_url.base_url + url,{
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token'),
            }
        }).then((res) => {
            dispatch({ type: actionTypes.FETCH_NUDGE_SUCCESS, payload: res.data });
        })
    };
}
