import * as actionTypes from './quizActionTypes';
import axios from 'axios';
import config from "./../config/config";

export function getQuestionByLessonId(lessonId) {
    return async (dispatch) => {
        const res = await axios.get(config.api.server_url.request_url.question_url + lessonId,{
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        });
        dispatch({ type: actionTypes.FETCH_QUESTIONS_SUCCESS, payload: res.data });
    };
}
export function postQuizResult(data) {
    return async function (dispatch) {
        const res = await axios.post(config.api.server_url.request_url.base_url + "qresult/createquizResult", data, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        });
        dispatch({ type: actionTypes.STORE_RESULT_SUCCESS, payload: res.data });
    };
}
export function getQuizData(lessonId) {
    return async (dispatch) => {
        const res = await axios.get(config.api.server_url.request_url.quiz_url + lessonId,{
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        });
        dispatch({ type: actionTypes.FETCH_QUIZ_DATA_SUCCESS, payload: res.data });
    };
}

export function getUnassignedSkillsData(skillId) {
    return async (dispatch) => {
        const res = await axios.get(config.api.server_url.request_url.assigned_url + skillId,{
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        });
        dispatch({ type: actionTypes.FETCH_UNASSIGNED_SKILLS_SUCCESS, payload: res.data });
    };
}

export function postNudgeResult(data) {
    return async function (dispatch) {
        const res = await axios.post(config.api.server_url.request_url.base_url + "qresult/createNudgeResult", data, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        });
        dispatch({ type: actionTypes.STORE_NUDGE_RESULT_SUCCESS, payload: res });
    };
}