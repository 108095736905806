import * as actionTypes from './popularSkillsActionType';

const popularSkillsReducer = (state = {}, action) => {
    switch (action.type) {
        case actionTypes.FETCH_POPULAR_SKILLS_SUCCESS:
            return Object.assign({}, state, {
                popularSkillsData: action.payload,
            });

        case actionTypes.FETCH_POPULAR_SKILLS_FAILURE:
            return Object.assign({}, state, {
                popularSkillsData: action.error,
            });
        default:
            return state;
    }
}
export default popularSkillsReducer;