import React, { Component } from "react";
import {
    Navbar, NavbarToggler, Nav,
    NavItem,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    NavbarText,
    ButtonDropdown,
    UncontrolledTooltip
} from 'reactstrap';
import { Link } from "react-router-dom";
import { isBrowser } from 'react-device-detect';
import Avatar from 'react-avatar';
import jwt_decode from "jwt-decode";

class Sidebar extends Component {
    constructor(props) {
        let user = jwt_decode(localStorage.getItem("token"));
        super(props);
        this.state = {
            isOpen: false,
            dropdownOpen: false,
            userName: user.username,
            firstName: user.firstName,
            lastName: user.lastName,
            active: (window.location.pathname === "") ? 'dashboard' :window.location.pathname.substr(1)
        }
    }


    componentDidMount() {
        // let n = this.state.userName.split('@');
        // let name = n[0];
        // let res;
        // let result;
        // if (name.includes('.')) {
        //     res = name.split('.');
        //     result = res[0] + " " + res[1];
        // }
        // else if (name.includes('_')) {
        //     res = name.split('_');
        //     result = res[0] + " " + res[1];
        // }
        // else {
        //     result = name;
        // }
        let name = '';
        if(this.state.firstName && this.state.firstName != "") {
            if(this.state.lastName && this.state.lastName != "") {
                name = this.state.firstName + " " + this.state.lastName;
            } else {
                name = this.state.firstName;
            }
        }
        this.setState({ userName: name });
    }
    toggle = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    NavbarToggler = () => {
        this.setState({
            isOpen: !this.state.isOpen,

        });
    }

    isSidebarToggle = () => {
        this.props.sidebarToggleHandler();

    }
    

    render() {
        let user = jwt_decode(localStorage.getItem("token"));
        return (
            <div className={this.props.showsidebarVisibleState ? "sidebar" : "sidebar closesidebar"}>
                <div className="left-panel">
                    <div className="profile-wrapper">
                        <Avatar name={this.state.userName} size="60" textSizeRatio={1.50} round={true} className="usernamedisplay" />
                        <div className={this.props.showsidebarVisibleState ? "profile-name" : "closediv"}>
                            {this.state.userName}
                        </div>
                        <div className={this.props.showsidebarVisibleState ? "select-role" : "closediv"}>
                            {user.role !== "Learner" ?
                                <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                                    <DropdownToggle caret>
                                        <span className="fa fa-user"></span>
                                        <span style={{ "marginLeft": "5px" }}>Learner</span>

                                    </DropdownToggle>
                                    <DropdownMenu right className="adminbtn">
                                      {
                                      //isBrowser ? 
                                      (user.role == "Super Admin" || user.role == "Admin"
                                            ?
                                            <DropdownItem >{<a href="/admin/dashboard">
                                                <span className="fa fa-user"> </span><span style={{ "marginLeft": "5px" }}>Admin </span>
                                            </a>}</DropdownItem>
                                            :
                                            user.role == "Author" ?
                                                <DropdownItem>{<a href="/admin/dashboard">
                                                   <span className="fa fa-user"> Author </span>
                                                   </a>}</DropdownItem>
                                                : "") 
                                                //: ""
                                        }
                                    </DropdownMenu>
                                </ButtonDropdown> : null}

                        </div>
                    </div>

                    <div>
                        <div className="nav-panel">
                            <Navbar>
                                <Nav navbar>
                                    <NavItem className={this.props.active === 'dashboard' ? 'nav-active' : ''}
                                        onClick={e => this.props.setActiveClass('dashboard')}>

                                        <Link to="/dashboard" className="nav-link" style={{color: this.props.active ==='dashboard' &&'#222D56'}}>
                                        <img src={require("../../images/dashBoardimages/dashboard.svg")} className="nav-icon" alt="" id="dashboard" />
                                            <NavbarText className={this.props.showsidebarVisibleState ? "nav-label" : "closediv"}>
                                                Dashboard
                                    </NavbarText>
                                        </Link>
                                    </NavItem>
                                    <NavItem className={this.props.active === 'skills' ? 'nav-active' : ''}
                                        onClick={e => this.props.setActiveClass('skills')}>
                                        <Link to="/skills" className="nav-link" style={{color: this.props.active ==='skills' &&'#222D56'}}>
                                        <img src={require("../../images/dashBoardimages/all-nuggets.svg")} className="nav-icon" alt="" id="myNuggets" />

                                            <NavbarText className={this.props.showsidebarVisibleState ? "nav-label" : "closediv"}>
                                                My Nuggets
                                    </NavbarText>
                                        </Link>
                                    </NavItem>
                                    <NavItem className={this.props.active === 'reports' ? 'nav-active' : ''}
                                        onClick={e => this.props.setActiveClass('reports')}>
                                        <Link to="/reports" className="nav-link" style={{color: this.props.active ==='reports' &&'#222D56'}}>
                                            <img src={require("../../images/dashBoardimages/reports.png")} className="nav-icon" alt="" id="reports" />

                                            <NavbarText className={this.props.showsidebarVisibleState ? "nav-label" : "closediv"}>
                                                Reports
                                    </NavbarText>
                                        </Link>
                                    </NavItem>
                                </Nav>
                            </Navbar>
                            {!this.props.showsidebarVisibleState && <>
                                <UncontrolledTooltip placement="right" target="dashboard" autohide={true}>
                                    Dashboard
                                </UncontrolledTooltip>
                                <UncontrolledTooltip placement="right" target="myNuggets">
                                    My Nuggets
                                </UncontrolledTooltip>
                                <UncontrolledTooltip placement="right" target="reports">
                                  Reports
                                </UncontrolledTooltip>
                            </>}

                        </div>
                    </div>

                </div>
            </div>

        );
    }
}
export default Sidebar;


