import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getUserReport } from './reportsAction';
import DataTable from "react-data-table-component";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Row, Col, FormGroup } from 'reactstrap';
import {get} from "lodash";
import './reports.scss';
import config from "./../config/config";
import {
    isBrowser, isMobile
} from 'react-device-detect';
import moment from "moment";
import { css } from "@emotion/core";
import SyncLoader from "react-spinners/SyncLoader";

import styled from "styled-components";

const StyledCell = styled.div`
  &.report-fail {
    color: red;
  }
  &.report.none {
    color: black;
  }
`;

const override = css`
  display: block;
  margin: 200px auto;
  width: 100px;
  padding: '25%'
`;

class Reports extends Component {
    constructor(props) {
        super(props);

        this.props.homeVisibility(true);
        this.state = {
            reportsData: '',
            searchText: '',
            loading: false
        }

    }

    componentDidMount = async () => {
        if (!localStorage.getItem("token")) {
          //  alert("User Not Logged In!!!");
            this.props.history.push("/");
        }
        this.setState({ loading: true })
        await this.props.getUserReport();

        if (this.props.userReports) {
            let loadingTime = this;
            setTimeout(function () {
                loadingTime.setState({ loading: false });
            }, 200);
        }

    }


    componentDidUpdate(prevProps) {
        const { userReports } = this.props;

        if (prevProps.userReports !== userReports) {
            this.setSearchData()
            this.setState({ loading: false });
        }
    }
    setSearchData = async () => {
        const { userReports } = this.props;
        let searchText = this.state.searchText;
        if (!this.props.userReports.length) {
            return false;
        }
        if (userReports) {
            var searchData = await userReports.filter(function (item) {
                return (JSON.stringify(item).toLowerCase().indexOf(searchText.toLowerCase()) !== -1)
            })
        }
        this.setState({ reportsData: searchData })
    }

    handleSearchChange = async (e) => {
        let value = e.target.value;
        let name = e.target.name;
        await this.setState({
            [name]: value
        });
        this.setSearchData()
    }


    render() {
        let { searchText } = this.state;
        const data = [];

        if (this.state.reportsData) {
            for (let i = 0; i < this.state.reportsData.length; i++) {
                data.push({
                    srNo: i + 1,
                    skillName: this.state.reportsData[i].users_data.skill ? this.state.reportsData[i].users_data.skill.skill_name ? this.state.reportsData[i].users_data.skill.skill_name : '-' : '',
                    lessonName: this.state.reportsData[i].users_data.lesson ? this.state.reportsData[i].users_data.lesson.lesson_title ? this.state.reportsData[i].users_data.lesson.lesson_title : '-' : '',
                    completionDate: this.state.reportsData[i].users_data.quiz_result.assessmentDate ? 
                    moment(this.state.reportsData[i].users_data.quiz_result.assessmentDate).format("DD-MMM-YYYY") : 
                    this.state.reportsData[i].users_data.lesson.lesson_status.completionDate ?
                    moment(this.state.reportsData[i].users_data.lesson.lesson_status.completionDate).format("DD-MMM-YYYY") : '-',
                    score: this.state.reportsData[i].users_data.quiz_result ? this.state.reportsData[i].users_data.quiz_result.userScore || this.state.reportsData[i].users_data.quiz_result.userScore == 0 ? this.state.reportsData[i].users_data.quiz_result.userScore : '-' : '',
                    quizResult: this.state.reportsData[i].users_data.quiz_result ? this.state.reportsData[i].users_data.quiz_result.result ? this.state.reportsData[i].users_data.quiz_result.result : '-' : '',
                    timeSpend: this.state.reportsData[i].users_data.quiz_result ? this.state.reportsData[i].users_data.quiz_result.timeTaken ? this.state.reportsData[i].users_data.quiz_result.timeTaken : '-' : '',
                })
            }
        }

        function getCssClass(value) {
            if (value && value.toLowerCase() == 'fail') {
                return "report-fail";
            } else {
                return "report-none";
            }
        }
        

        const dateSort = (rowA,rowB) =>{
            const a = rowA.completionDate;
            const b = rowB.completionDate;

            return new Date(b)- new Date(a);
        }
        
        const columns = [
            {
                name: "Sr. No",
                selector: "srNo",
                sortable: true,
                style: {
                    fontSize: '16px',
                },
                width: "6%",
            },

            {
                name: "Nugget",
                selector: "skillName",
                sortable: true,
                style: {
                    textTransform: 'capitalize',
                    fontSize: '16px',
                },
                width: "25%"
            },
            {
                name: "Learning bite",
                selector: "lessonName",
                sortable: true,
                style: {
                    fontSize: '16px',
                },
                width: "25%"
            },
            {
                name: "Completion Date",
                selector: "completionDate",
                sortable: true,
                sortFunction : dateSort,
                style: {
                    fontSize: '16px',
                },
                width: "13%",

            },
            {
                name: "Score",
                selector: "score",
                sortable: true,
                style: {
                    fontSize: '16px',
                },
                width: "7%",
                right: true,
            },
            {
                name: "Quiz Result",
                selector: "quizResult",
                sortable: true,
                style: {
                    fontSize: '16px'
                },
                width: "10%",
                cell: (row) => (
                    <StyledCell className={getCssClass(row.quizResult)}>
                      {row.quizResult}
                    </StyledCell>
                )
            },
            {
                name: "Time Spent",
                selector: "timeSpend",
                sortable: true,
                style: {
                    fontSize: '16px',
                },
                width: "9%",
            }
        ];
        return (
            <div className="report-margin">

                {this.state.loading ?
                    <SyncLoader
                        css={override}
                        size={20}
                        color={"#0b6eb6"}
                        loading={this.state.loading}
                    /> :
                    <div className="inner-courses-wrapper report-table-wrapper" >
                        <div className="lesson-content-scroll">

                            <FormGroup>
                                <Row>
                                    <Col lg={12} md={12}>
                                        <h3>Reports</h3>
                                        <div className="report-search-box float-right">
                                            <span className="form-search-icon"><FontAwesomeIcon
                                                icon={faSearch} /></span>
                                            <input type="text" id="searchText"
                                                className="form-search-input"
                                                placeholder="Search"
                                                name="searchText" value={searchText}
                                                onChange={e => this.handleSearchChange(e)}
                                                autoComplete="off" />
                                        </div>
                                    </Col>
                                </Row>
                            </FormGroup>

                            <DataTable className="reports_table"
                                noHeader={true}
                                columns={columns}
                                data={data}
                                pagination
                                paginationPerPage={10}
                                paginationTotalRows={data.length}
                                striped={true}
                                highlightOnHover={true}
                                responsive={true}
                                style={{
                                    border: '1px solid #e9ecef',
                                    borderRadius: '0.25rem !important',
                                    fontSize: '15px',
                                    marginBottom: "20px",
                                    overflow: "scroll !important"
                                }}
                            />
                        </div>
                    </div>
                }
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getUserReport


        },
        dispatch
    );
}
const mapStateToProps = state => {
    return {
        userReports: state.reportReducer.userReports,

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Reports);



