import * as actionTypes from './dashboardActionTypes';

const dashboardReducer = (state = {}, action) => {
      
    switch (action.type) {

          case actionTypes.FETCH_RECENT_CREATED_SKILLS_SUCCESS:
            return Object.assign({}, state, {
                recentNuggets: action.payload,
            });

            case actionTypes.FETCH_RECENT_CREATED_SKILLS_FAILURE:
            return Object.assign({}, state, {
                recentNuggets: action.payload,
            });

            case actionTypes.FETCH_POPULAR_SKILLS_SUCCESS:
            return Object.assign({}, state, {
                popularNuggets: action.payload,
            });
            
            case actionTypes.FETCH_POPULAR_SKILLS_FAILURE:
            return Object.assign({}, state, {
                popularNuggets: action.payload,
            });
            
            case actionTypes.FETCH_USER_SKILLS_REPORT_SUCCESS:
            return Object.assign({}, state, {
                userSkillsReport: action.payload,
            });
            
            case actionTypes.FETCH_USER_SKILLS_REPORT_FAILURE:
            return Object.assign({}, state, {
                userSkillsReport: action.payload,
            });

             case actionTypes.FETCH_USER_PENDING_SKILLS_SUCCESS:
            return Object.assign({}, state, {
                userRecentPendingSkills: action.payload,
            });
            
            case actionTypes.FETCH_USER_PENDING_SKILLS_FAILURE:
            return Object.assign({}, state, {
                userRecentPendingSkills: action.payload,
            });
            
         default:
            return state;
    }
}
export default dashboardReducer;