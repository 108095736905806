import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux'
import { getQuestionByLessonId, getQuizData, getUnassignedSkillsData } from "./quizActions";
import NullView from "./NullView";
import shortid from "shortid";

import { css } from "@emotion/core";
import SyncLoader from "react-spinners/SyncLoader";
import jwt_decode from "jwt-decode";

const override = css`
  display: block;
  margin: 200px auto;
  width: 100px;
  padding: '25%'
`;


class QuestionsComponent extends Component {
    constructor(props) {
        super(props);
        QuestionsComponent.selectedOptions = [];
        this.props.homeVisibility(false);
        this.state = {
            pathname: `${this.props.history.location.pathname}`,
            quiz: [],
            timeShow: new Date().getTime().toString(),
            componentName: "",
            component: "",
            loading: true,
        };

    }

    async componentDidMount() {
        if (!localStorage.getItem('token')) {
           // alert("User Not Logged In!!!");
            this.props.history.push('/');
        };
        console.log("sprinklezone",this.props.history.location.pathname);
        const { dispatch } = this.props;
        await dispatch(getUnassignedSkillsData(this.props.match.params.skill_id));
        await dispatch(getQuizData(this.props.match.params.id));
        await dispatch(getQuestionByLessonId(this.props.match.params.id));

        const {result} = this.props;

        if(result !== undefined){
            let quiz = jwt_decode(result.data);
            if (Object.keys(result).length === 0 && result.constructor === Object)
            return;

        const componentName = "component" in quiz.result ? quiz.result.component.name : "quizBasic";
        this.setState({ componentName: componentName }, () => {
            import(`../quizTheme/${this.state.componentName}/${this.state.componentName}.js`)
                .then(Component => {
                    this.setState({
                        component:
                        <Component.default
                            key={shortid.generate()}
                            quiz_data={result.data}
                            quiz_path={this.props.history.location.pathname}
                            quiz_lessonId={this.props.match.params.id}
                            quiz_skillId={this.props.match.params.skill_id}
                            bg={quiz.result.backgroundImage ? quiz.result.backgroundImage._id : ""}
                        />
                    });
                })
                .catch(error => {
                    this.setState({
                        component: (
                            <NullView key={shortid.generate()} />
                        )
                    });
                });
        })

        if (this.props.result) {
            this.setState({ loading: false })
        }
       }
        
  }

    render() {
        localStorage.setItem('starttime', this.state.timeShow);
        console.log("Start",this.state.loading);
        const { component } = this.state;
            return (
                    this.props.unassignedResult ? (
                        alert("This Nugget is not assigned to you. Please ask Admin to assign it."),
                        <Redirect to={`/dashboard`} /> 
                        ) :(
                            this.props.resultToday ? (
                                alert("You have already given this assessment today. Come back tomorrow or later!"),
                                <Redirect to={`/dashboard`} />               
                            ):(
                                <div className="quizviews" id="quizviews">
                                {component === "" ? (
                                    <SyncLoader
                                        css={override}
                                        size={20}
                                        color={"#0b6eb6"}
                                        loading={this.state.loading} />
                                ) : (
                                        component
                                    )
                                }
                                </div>
                        )                       
                      )
                    )
            }
}

const mapStateToProps = state => {
    let result = {};
    let resultToday = {};
    let unassignedResult = {};
    if (state.questionReducer.result !== undefined) {
        if (state.questionReducer.unassignedData !== undefined){
            unassignedResult = state.questionReducer.unassignedData.data.isSkillUnassigned;
            if(unassignedResult === false){
                if(state.questionReducer.quizData != undefined){
                    resultToday = state.questionReducer.quizData.data.is_quiz_given_today;
                    if(resultToday === true){
                        result = {resultToday};
                        return result;
                    }
                    else{
                        result = state.questionReducer;
                        return result; 
                    }                    
                }
                else{
                    return { result };
                }
            }
            else{
                result = {unassignedResult};
                return result;
            }
        }
        else{
            return { result };
        } 
    }
    else{
        return { result };
    }
};

export default connect(mapStateToProps)(QuestionsComponent);