import React, {Component} from 'react';
import validate from './validate';
import {loginService} from './login-service';
import axios from 'axios';
import './login.css';
const teamsLanding = require("./../../images/landingPage/teamsLanding.png");
const jwt = require("jsonwebtoken");

class SkillsLandingPage extends Component {
    componentDidMount() {
        const queryParams = new URLSearchParams(window.location.search);
        if(queryParams.get("data")) {
            this.handleRequest();
        }
    }


    userIdforTokenMapping = async (userId) => {
        let data = {
            token: localStorage.getItem("notificationToken"),
            userId: userId
        }
        if (data.token === null) return;
        await axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_URL}notification/updateUserIdForToken`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: {data}
        }).then((res) => {

        })
    }

    
    handleRequest = async () => {
        let username;
        let password;
        let teamsFlag = false;
        const queryParams = new URLSearchParams(window.location.search);
        try {
            let skills_data = jwt.verify(queryParams.get("data"), "myuserdatabase");
            console.log(skills_data.lesson_data);
            username =  skills_data.username;
            password = "Test@1234"; // This won't be used at backend
            teamsFlag = true;
            
            const status = validate(username, password);
            var str = "@harbingergroup.com";
            if (status === true) {
                if (!username.includes("@")) {
                    if (username.endsWith(str) === false) {
                        username += str;
                    }
                }
                const data = loginService(username, password);
                axios({
                    method: 'post',
                    url: teamsFlag ? process.env.REACT_APP_API_URL + 'login?isTeamsApp=true&username=' + skills_data.username.toLowerCase(): process.env.REACT_APP_API_URL + 'login',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: {data}
                }).then(res => {
                    axios({
                        method: 'post',
                        url: teamsFlag ? process.env.REACT_APP_API_URL + 'checkToken?isTeamsApp=true&username=' + skills_data.username.toLowerCase(): process.env.REACT_APP_API_URL + 'checkToken',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        data: {data}
                    }).then(res => {
                        localStorage.setItem('token', res.data.data.token);
                        localStorage.setItem('mail', res.data.data.username);
                        // localStorage.setItem('userId', res.data.data.userId);
                        // localStorage.setItem('userRole', res.data.data.user_role);
                        localStorage.setItem('organization', res.data.data.organization.organizationName);
                        if(res.data.data.organization){
                            localStorage.setItem('organizationLogoId', res.data.data.organization.organizationLogoId);
                            localStorage.setItem('headerColor', res.data.data.organization.headerColor);
                            localStorage.setItem('unameFont', res.data.data.organization.fontColor);
                            localStorage.setItem('organizationMobileLogoId', res.data.data.organization.organizationMobileLogoId);

                        }else{
                            localStorage.setItem('organizationLogoId', "");
                            localStorage.setItem('headerColor', "");
                        }
                        this.props.loadLessonData();
                        if (localStorage.getItem('token')) {
                            if(res.data.data.userId) {
                                this.userIdforTokenMapping(res.data.data.userId);
                            }
                            // this.props.history.push('/skills');
                           // localStorage.setItem("lessonsTheme", skills_data.lessonTheme);
                            //localStorage.setItem("nudgeLearningbite",skills_data.lessonId);
                           // localStorage.setItem("isAutoschedule",skills_data.isAutoSchedule);
                            localStorage.setItem("nudgeId",skills_data.nudgeId);
                            localStorage.setItem("scheduleDate",new Date(skills_data.scheduleDate));
                            localStorage.setItem("iteration",skills_data.iteration);
                           // localStorage.setItem("lesson_data", JSON.stringify(skills_data.lesson_data));
                            setTimeout(() => {
                                this.props.history.push('/lessons/' + skills_data.skillId + '/' + skills_data.lesson_id);
                               //this.props.history.push('/skills/' + skills_data.skillId);
                            }, 3000);
                        }
                    })
                }).catch(error => {
                    document.getElementById("error").innerHTML = "<font color='red'><b><center>" + error.errors.message + "</center></b></font>"
                });
            } else {
                document.getElementById("error").innerHTML = "<font color='red'><b><center> Username or password is incorrect </center></b></font>"
            }
        } catch(error) {
            if(error.message == "jwt expired") {
                alert("Link Expired!");
                window.location = '/'
            } else if(error.message == "invalid signature") {
                alert("Inavlid Link!");
                window.location = '/'
            }
        }
        
    }

    render() {
        return (
            <img src={teamsLanding} alt='SprinkleZone Logo' height="100%" width = "100%"></img>         
        );
    }
}

export default SkillsLandingPage;