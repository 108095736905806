import * as actionTypes from './ratingActionTypes';

const ratingReducer = (state = {}, action) => {
    switch (action.type) {
        case actionTypes.CREATE_RATING_SUCCESS:
            return Object.assign({}, state, {
                result: action.payload,
            });
        default:
            return state;
    }
}
export default ratingReducer;