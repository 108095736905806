import * as actionTypes from './recentlyAddedSkillsActionType';

const recentlyAddedSkillsReducer = (state = {}, action) => {
    switch (action.type) {
        case actionTypes.FETCH_RECENTLY_ADDED_SKILLS_SUCCESS:
            return Object.assign({}, state, {
                recentlyAddedSkillsData: action.payload,
            });

        case actionTypes.FETCH_RECENTLY_ADDED_SKILLS_FAILURE:
            return Object.assign({}, state, {
                recentlyAddedSkillsData: action.error,
            });
        default:
            return state;
    }
}
export default recentlyAddedSkillsReducer;