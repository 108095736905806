import * as actionTypes from './dashboardActionTypes';
import axios from 'axios';
import config from "./../config/config";


export function getRecentlyAddedNuggets() {
    
  return function (dispatch) {
    return axios.get(config.api.server_url.request_url.base_url + "getRecentlyAddedSkills", {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        }).then((res) => {
            
        if(res.data){
                dispatch({ type: actionTypes.FETCH_RECENT_CREATED_SKILLS_SUCCESS, payload: res.data.data });
            }
        }).catch(error => {
            dispatch({type: actionTypes.FETCH_RECENT_CREATED_SKILLS_FAILURE, error: error});
        });
    };
}

export function getPopularNuggets() {
  return function (dispatch) {
    return axios.get(config.api.server_url.request_url.base_url + "/getPopularSkills", {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        }).then((res) => {
       if(res.data){
                dispatch({ type: actionTypes.FETCH_POPULAR_SKILLS_SUCCESS, payload: res.data.data });
            }
        }).catch(error => {
            dispatch({type: actionTypes.FETCH_POPULAR_SKILLS_FAILURE, error: error});
        });
    };
}

export function getUserSkillsReports() {
  return function (dispatch) {
    return axios.get(config.api.server_url.request_url.base_url + "/getUserSkillsReports", {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        }).then((res) => {
            
         if(res.data){
                dispatch({ type: actionTypes.FETCH_USER_SKILLS_REPORT_SUCCESS, payload: res.data.data });
            }
        }).catch(error => {
            dispatch({type: actionTypes.FETCH_USER_SKILLS_REPORT_FAILURE, error: error});
        });
    };
}

export function getUserRecentPendingSkills() {
  return function (dispatch) {
    return axios.get(config.api.server_url.request_url.base_url + "/getRecentPendingSkills", {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        }).then((res) => {
        if(res.data){
                dispatch({ type: actionTypes.FETCH_USER_PENDING_SKILLS_SUCCESS, payload: res.data.data });
            }
        }).catch(error => {
            dispatch({type: actionTypes.FETCH_USER_PENDING_SKILLS_FAILURE, error: error});
        });
    };
}



