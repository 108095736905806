import React, {Component} from "react";

class OutlookLanding extends Component {
    constructor(props) {
        super(props);
  }

    componentDidMount = async () => {
        let url = "https://login.microsoftonline.com/common/oauth2/v2.0/authorize?";
        url = url + "client_id=" + process.env.REACT_APP_CLIENT_ID;
        url = url + "&redirect_uri=" + process.env.REACT_APP_REDIRECT_URL;
        url = url + "&response_type=" + process.env.REACT_APP_RESPONSE_TYPE;
        url = url + "&response_mode=" + process.env.REACT_APP_RESPONSE_MODE;
        url = url + "&scope=" + process.env.REACT_APP_RESPONSE_SCOPE;

        window.open(url,"_self");
    };

    render() {
        return (
            <div>

            </div>
        );
    }
}

export default OutlookLanding;