import React, { Component } from "react";
import {
Progress
} from 'reactstrap';
import config from "../../config/config";
import Slider from "react-slick";
import MagicSliderDots from 'react-magic-slider-dots';


class MyLearningProgressOutlook extends Component {
    constructor(props) {
        super(props);
      this.state = {
            userRecentPendingSkillsCard: this.props.myLearningData
        }
    }

    render() {

    if(this.state.userRecentPendingSkillsCard){
        var settings = {
            dots: true,
            arrows: true,
            infinite: false,
            speed: 500,
            slidesToShow: 2,
            slidesToScroll:2,
            appendDots: dots => {
                return <MagicSliderDots dots={dots} numDotsToShow={5} dotWidth={30} />
            },
                responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
        };
        }
        return (
        <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12">
            <div className="outllook_pending_nugget-status-wrapper">
               <div className="quiz-title">My Learning</div>
          
             <div className="pending_details_card">
            <Slider {...settings}>
             
                    {this.state.userRecentPendingSkillsCard.map((skillDetails, index) => {
                     return ( <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 userpendingskillcard" key={index}>
                         <div className="skill_image">
                                               <img
                                                    src={config.api.server_url.request_url.base_url + "getUploadedFile/"
                                                        + skillDetails.image} alt="" />
                                                           </div>
                                                    <div className="viewSkillName" title={skillDetails.name}> {skillDetails.name}</div>
                                                   
                                                  
                                                   
                                                  <Progress id={"pr_" + index} color="success" value={skillDetails.completed_percentage} />
                                                  <span className="completion_ratio">{skillDetails.completed_count}</span>
                                        
                                           
                                        </div>
                    );
                    })}
               
                </Slider>
                 </div>
                  </div>
           
        </div>
        


        );
    }
}


export default MyLearningProgressOutlook;



