import * as actionTypes from './profileActionTypes';
import axios from 'axios';
import config from "../config/config";


export function getUserProfile(userId) {
  return function (dispatch) {
    return axios.get(config.api.server_url.request_url.base_url + "getProfileDetails/"+ userId, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        }).then((res) => {
        if(res.data){
                dispatch({ type: actionTypes.FETCH_USER_PROFILE_SUCCESS, payload: res.data });
            }else{
                alert("something went wrong...");
                
            }
        }).catch(error => {
            alert(JSON.stringify(error.error));
            dispatch({type: actionTypes.FETCH_USER_PROFILE_FAILURE, error: error});
        });
    };
}
