import React, { Component } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import RecentNuggets from './recentNuggetsSlider'
import PopularNuggets from './popularNuggets'


class AddNuggets extends Component {
  constructor(props) {
        super(props);
        this.props.setActiveClass('dashboard');
        this.state = {
            activeTab:'1',
            recentData:this.props.recentData,
            popularData:this.props.popularData
           }
      
    }
  isTabToggle = tab => {
        if (this.activeTab !== tab) {
            this.setState({
                activeTab: tab,
                
            })
        }
    };

   render() {
     
   return (
    <Row>
    <Col lg={12}>
    <div className="nuggets-section">
      <Nav tabs className="mb-3">
      <NavItem>
          <NavLink
           className={this.state.activeTab === '1' ? "nuggets_tab_active" : "custom_cursor"}
           onClick={() => {
                            this.isTabToggle('1');
                        }}
          >
            Popular Nuggets
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={this.state.activeTab === '2' ? "nuggets_tab_active" : "custom_cursor"} 
              onClick={() => { this.isTabToggle('2'); }}
          >
            Recently Added Nuggets
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={this.state.activeTab}>
       
        <TabPane tabId="1">
            <PopularNuggets allPopularNuggetsData ={this.state.popularData} setActiveClass={this.props.setActiveClass}/>  
        </TabPane>
        <TabPane tabId="2">
          <RecentNuggets allRecentNuggetsData ={this.state.recentData} setActiveClass={this.props.setActiveClass}/>
        </TabPane>
      </TabContent>
      </div>
      </Col>
    </Row>
        );
    }
}


export default AddNuggets;
