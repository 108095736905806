import * as actionTypes from './topicsActionTypes';
const topicReducer = (state = {}, action) => {
    switch (action.type) {
        case actionTypes.FETCH_TOPICS_SUCCESS:
            return Object.assign({}, state, {
                result: action.payload,
            });
        case actionTypes.FETCH_QUIZ_SUCCESS:
            return Object.assign({}, state, {
                result: action.payload,
            });
        case actionTypes.STORE_USER_LESSON_SUCCESS:
            return Object.assign({}, state, {
                userLesson: action.payload,
            });
        case actionTypes.FETCH_NUDGE_SUCCESS:
            return Object.assign({}, state, {
                nudge: action.payload,
            });
        case actionTypes.STORE_USER_NUDGE_LESSON_SUCCESS:
            return Object.assign({}, state, {
                userNudgeLesson: action.payload,
            });
        case actionTypes.FETCH_NUDGE_DETAILS_SUCCESS:
            return Object.assign({}, state, {

                nudgeData: action.payload,
            });
        case actionTypes.UPDATE_NUDGE_SUCCESS:
            return Object.assign({}, state, {
                updateNudgeData: action.payload,
            });
        case actionTypes.FETCH_LESSON_DETAILS_SUCCESS:
            return Object.assign({}, state, {

                lessonData: action.payload,
            });
        default:
            return state;
    }
}
export default topicReducer;