export const jsonMenu = {
    "meta": {"success": true, "message": "FETCH_HOME_MENU_SUCCESS"},
    data: [
        {
            name: 'My Nuggets',
            image:"all-nuggets.svg",
            description: 'Learn about All Nuggets.Learn about All Nuggets.',
            backgroundColor:'#FDF9FE',
            pathName:"/outlook-all-skills"
        },
        {
            name: 'Popular Nuggets',
            image:"popular-nuggets.svg",
            description: 'Learn about Popular Nuggets.Learn about Popular Nuggets.',
            backgroundColor:'#F8F9FF',
            pathName:"/outlook-popular-nuggets"
        },
        {
            name: 'Recently Added Nuggets',
            image:"recently-added-nuggets.svg",
            description: 'Learn about Recently Added Nuggets. Learn about Recently Added Nuggets.',
            backgroundColor:'#F8F9FF',
            pathName:"/outlook-recently-added-nuggets"
        },
        {
            name: 'Completed Nuggets',
            image:"completed-nuggets.svg",
            description: 'Learn about Completed Nuggets.Learn about Completed Nuggets.',
            backgroundColor:'#FFFCEF',
            pathName:"/outlook-completed-skills"
        },
        {
            name: 'Pending Nuggets',
            image:"pending-nuggets.svg",
            description: 'Learn about Pending Nuggets.Learn about Pending Nuggets.',
            backgroundColor:'#FFF1E8',
            pathName:"/outlook-pending-skills"
        },
        {
            name: 'Reports',
            image:"reports.svg",
            description: 'Learn about Reports.Learn about Reports.',
            backgroundColor:'#EFFFF9',
            pathName:"/outlook-reports"
        },
        {
            name: 'Feedback',
            image:"feedback.svg",
            description: 'Learn about Reports.Learn about Reports.',
            backgroundColor:'#F8F9FF',
            pathName:"/outlook-feedback"
        }
    ]
};