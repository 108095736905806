import * as actionTypes from './reportsActionType';

const reportsReducer = (state = {}, action) => {
    switch (action.type) {
        case actionTypes.FETCH_SKILLS_REPORTS_SUCCESS:
            return Object.assign({}, state, {
                skillsReportData: action.payload,
            });

        case actionTypes.FETCH_SKILLS_REPORTS_FAILURE:
            return Object.assign({}, state, {
                skillsReportData: action.error,
            });
               case actionTypes.FETCH_USER_PENDING_SKILLS_SUCCESS_OUTLOOK:
            return Object.assign({}, state, {
                OutlookRecentPendingData: action.payload,
            });
            
            case actionTypes.FETCH_USER_PENDING_SKILLS_FAILURE_OUTLOOK:
            return Object.assign({}, state, {
                OutlookRecentPendingData: action.payload,
            });
        default:
            return state;
    }
}
export default reportsReducer;