import { socketConfig } from '../../socketConfig'
import jwt_decode from "jwt-decode";
let socket = "";
const ActiveUser = () => {
        socket = socketConfig(process.env.REACT_APP_BASE_URL);
        let user = jwt_decode(localStorage.getItem("token"));
        socket.emit("userLoggedIn", user._id, localStorage.getItem("organization"));
};

export default ActiveUser;
