import { get } from "lodash";
import * as actionTypes from './outlookFeedbackActionType';

const initialState = {
    isFetching: false,
    feedback: {},
    error: {}
};

const feedbackReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CREATE_FEEDBACK_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case actionTypes.CREATE_FEEDBACK_SUCCESS:
            return {
                ...state,
                isFetching: false,
                feedback: get(action, "payload.data", {}),
            };
        case actionTypes.CREATE_FEEDBACK_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: get(action, "payload.error", {}),
            };
        default:
            return state;
    }
}
export default feedbackReducer;
