import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getRecentlyAddedNuggets, getPopularNuggets, getUserSkillsReports, getUserRecentPendingSkills } from './dashboardActions'
import AddNuggets from './showNuggets'
import MyLearningProgress from './myLearningProgress';
import { css } from "@emotion/core";
import SyncLoader from "react-spinners/SyncLoader";
import jwt_decode from "jwt-decode";

const override = css`
  display: block;
  margin: 200px auto;
  width: 100px;
  padding: '25%'
`;


class Dashboard extends Component {
    constructor(props) {
        super(props);
        let user = jwt_decode(localStorage.getItem("token"));

        this.props.setActiveClass('dashboard');
        this.props.homeVisibility(true);
        this.state = {
            userName: user.username,
            isOpen: false,
            loading: false
        }

    }

    componentDidMount = async () => {
        if (!localStorage.getItem("token")) {
           // alert("User Not Logged In!!!");
            this.props.history.push("/");
        }
        
         this.setState({loading: true})
        await this.props.getRecentlyAddedNuggets();
        await this.props.getPopularNuggets();
        await this.props.getUserSkillsReports();
        await this.props.getUserRecentPendingSkills();
        
        if(this.props.recentNuggets) {
      // this.setState({loading: false})
      
        let loadingTime = this;
        setTimeout(function () {
          loadingTime.setState({ loading: false });
        }, 200);
    }

    }

    render() {
        let recentdata, populardata, recentPendingSkills;
        /*if (!this.props.recentNuggets.length) {
            return false;
        }
        if (!this.props.popularNuggets.length) {
            return false;
        }
        if (!this.props.userRecentPendingSkills.length) {
            return false;
        }*/

        recentdata = this.props.recentNuggets;
        populardata = this.props.popularNuggets;
        recentPendingSkills = this.props.userRecentPendingSkills;


        return (
            
            <div className="dashboard-margin">
                <div className="courses-wrapper" id="effect-content">
                          
          { this.state.loading ? 
            <SyncLoader
            css={override}
            size={20}
            color={"#0b6eb6"}
            loading={this.state.loading}
          /> :
                    <div className="dashboard-content-scroll">
                        <div className="col-12">
                            <div className="row">
                            <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12">
                                    <div className="nugget-status-wrapper">
                                        <div className="row nuggets_status">
                                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 dashboard-border">
                                                 <div className="assigned-bg status-circle">
                                                     {this.props.userSkillsReport ? this.props.userSkillsReport.total_skills : 0}</div> 
                                                 <div className="status-text first-text text-center">My Total Nuggets</div>
                                                 </div>

                                                 <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 dashboard-border">
                                              <div className="completed-bg  status-circle">
                                                  {this.props.userSkillsReport ? this.props.userSkillsReport.completed_skills : 0}
                                                  </div> 
                                            
                                                <div className="status-text second-text text-center">Completed Nuggets</div>
                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 text-center">
                                             <div className="in-progress-bg  status-circle">
                                                 {this.props.userSkillsReport ? this.props.userSkillsReport.incomplete_skills : 0}
                                                 </div> 
                                             <div className="status-text text-center">Incomplete Nuggets</div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <MyLearningProgress myLearningData={recentPendingSkills} />
                            </div>
                        </div>
                        <AddNuggets recentData={recentdata} popularData={populardata} setActiveClass={this.props.setActiveClass} />
                    </div>}
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getRecentlyAddedNuggets,
            getPopularNuggets,
            getUserSkillsReports,
            getUserRecentPendingSkills


        },
        dispatch
    );
}
const mapStateToProps = state => {
    return {
        recentNuggets: state.dashboardReducer.recentNuggets || [],
        popularNuggets: state.dashboardReducer.popularNuggets || [],
        userSkillsReport: state.dashboardReducer.userSkillsReport || [],
        userRecentPendingSkills: state.dashboardReducer.userRecentPendingSkills || []

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);



