import * as actionTypes from './allSkillsActionType';
import axios from 'axios';
import config from "../../config/config";

export function getSkills(url) {

    return function (dispatch) {
        return axios.get(config.api.server_url.request_url.base_url + url, {
            headers: {
                Username : localStorage.getItem('mail'),
            }
        }).then((res) => {
            dispatch({ type: actionTypes.FETCH_SKILL_SUCCESS, payload: res.data });
        })
    };
}
