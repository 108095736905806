import * as actionTypes from './pendingSkillsActionType';
import axios from 'axios';
import config from "../../config/config";

export function getPendingSkills() {

    return function (dispatch) {

        return axios.get(config.api.server_url.request_url.base_url +"getPendingSkills/"+localStorage.getItem("userId"), {
            headers: {
                Username : localStorage.getItem('mail'),
            }
        }).then((res) => {
            dispatch({ type: actionTypes.FETCH_UNCOMPLETED_SKILLS_SUCCESS, payload: res.data.data});

        }).catch(error=>{
            dispatch({ type: actionTypes.FETCH_UNCOMPLETED_SKILLS_FAILURE, error: error });
        })
    };
}
