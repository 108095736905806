import * as firebase from "firebase/app";
import "firebase/messaging";



let initializedFirebaseApp = null;
let  messaging = null;

try {

    initializedFirebaseApp = firebase.initializeApp({
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_FIREBASE_APP_ID,
        measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
    });

    messaging = initializedFirebaseApp.messaging();
    messaging.usePublicVapidKey(
        // Project Settings => Cloud Messaging => Web Push certificates
        "BJSHHThm-0GzC24wNmI1Mu4JOBpbVJgxXpanesG0gvLg6-h3FPxW5ikJnwRVFFxrd_GoTSfurrECu4gqZ30AsWE");

} catch (error) {
    console.log("Unable to init firebase API's")
}

export { messaging };
