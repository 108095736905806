import * as actionTypes from './topicsActionTypes';
import axios from 'axios';
import config from "./../config/config";


export function getTopicsByLessonId(lessonId) {
    return async (dispatch) => {
        const res = await axios.get(config.api.server_url.request_url.get_topics + lessonId,{
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        });
        dispatch({ type: actionTypes.FETCH_TOPICS_SUCCESS, payload: res.data });
    };
}

export function getQuestionByLessonId(lessonId) {
    return async (dispatch) => {
        const res = await axios.get(config.api.server_url.request_url.question_url + lessonId,{
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        });
        dispatch({ type: actionTypes.FETCH_TOPICS_SUCCESS, payload: res.data });
    };
}

export function postUserLesson(data) {
    return async function (dispatch) {   
        const res = await axios.post(config.api.server_url.request_url.base_url + "userLesson", { data: data },{
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        });
        dispatch({ type: actionTypes.STORE_USER_LESSON_SUCCESS, payload: res.data });
    };
}

export function getNudgeData(lessonId) {
    return async (dispatch) => {
        const res = await axios.get(config.api.server_url.request_url.base_url + "qresult/getNudgeResultByUserId/"+ lessonId,{
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        });
        dispatch({ type: actionTypes.FETCH_NUDGE_SUCCESS, payload: res.data });
    };
}

export function postUserNudgeLesson(data) {
    return async function (dispatch) {   
        const res = await axios.post(config.api.server_url.request_url.base_url + "saveLessonAttempt", { data: data },{
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        });
        dispatch({ type: actionTypes.STORE_USER_NUDGE_LESSON_SUCCESS, payload: res.data });
    };
}

export function getNudge(nudgeId) {
    return async (dispatch) => {
        const res = await axios.get(config.api.server_url.request_url.base_url + "getUserNudge/"+ nudgeId,{
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        });
        dispatch({ type: actionTypes.FETCH_NUDGE_DETAILS_SUCCESS, payload: res.data.data });
    };
}

export function OptOutNudge(data) {
    return async function (dispatch) {   
        const res = await axios.put(config.api.server_url.request_url.base_url + "OptOutNudge", { data: data },{
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        });
        dispatch({ type: actionTypes.UPDATE_NUDGE_SUCCESS, payload: res.data });
    };
}

export function getNextLearningBite(lessonId) {
    return async (dispatch) => {
        const res = await axios.get(config.api.server_url.request_url.base_url + "lessons/getNextPublishedLesson/"+ lessonId,{
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        });
        dispatch({ type: actionTypes.FETCH_LESSON_DETAILS_SUCCESS, payload: res.data.data });
    };
}