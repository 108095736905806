import React, {Component} from "react";
import {getUserNotifications, setUserNotificationsAsRead} from "./outlookHeaderAction";
import {connect} from "react-redux";
import NotificationBadge from 'react-notification-badge';
import {Effect} from 'react-notification-badge';
import {bindActionCreators} from "redux";
import {
    Navbar,
    Nav,
    Popover,
    PopoverHeader,
    PopoverBody,
    Row,
    Col
} from 'reactstrap';
import * as constant from '../../../utils/constants';

class OutlookHeader extends Component {

    constructor(props) {
        super(props);

        this.state = {
            headerText: '',
            homeVisibility: '',
            userName: localStorage.getItem("mail"),
            isOpen: false,
            notificationCount: 0,
            isOpenPopover: false
        };

        this.changeContent = this.changeContent.bind(this);
    }

    toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    };

    componentDidMount() {

        //For get user notifications
        //this.getNotifications();
        this.timerID = setInterval(
            this.getNotifications, constant.INTERVAL_TIME);

        document.addEventListener('mousedown', this.handleClickOutside);
    }

    changeContent(newContent) {
        this.setState({
            headerText: newContent,
            homeVisibility: newContent,

        });
    }

    componentWillMount() {
        this.setState({
            headerText: this.props.title,
            homeVisibility: this.props.homeVisibility,

        });
        //For clear ineteval timer.
        clearInterval(this.timerID);
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    //For get user notifications
    getNotifications = async () => {
        await this.props.getUserNotifications();
        let count = 0;
        const {notificationData} = this.props;
        for (let i = 0; i < notificationData.notifications.length; i++) {
            if (!notificationData.notifications[i].readStatus) {
                count++;
            }
        }

        this.setState({
            notificationCount: count
        });
    };

    //
    togglePopover = async () => {

        if(this.state.isOpenPopover){
            await  this.setReadNotification();
            //For get user notifications
            await this.getNotifications();
        }

        this.setState({
            isOpenPopover: !this.state.isOpenPopover
        });


    };

    handleClickOutside = async event => {
        let id = event.target.id;


        if (id !== "popover-element") {
            if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
                if(this.state.isOpenPopover){
                    //this.togglePopover();
                    this.setState({
                        isOpenPopover: false
                    });

                    await this.setReadNotification();
                    //For get user notifications
                    await this.getNotifications();
                }
            }
        }

    };

    setWrapperRef = node => {
        this.wrapperRef = node;
    };

    //For read user notifications
    setReadNotification = async () => {
        await this.props.setUserNotificationsAsRead();
    };

    formatDate = string => {
        let options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(string).toLocaleDateString([],options);
    };

    render() {

        let notificationCountStyle = {
            top: '',
            right: '',
        };

        return (
            <div className="outlook-header-div">
            <header className="outlook-header">
                <Navbar dark expand="md">
                    <Nav className="ml-auto">

                        {/*For notification*/}
                        <div ref={this.setWrapperRef} id="popoverNotification" className="notification-icon"
                             title={this.state.notificationCount + " new notification"}>
                            <i className="fa fa-bell-o fa-2x" aria-hidden="true">
                                <NotificationBadge className="notification-count" count={this.state.notificationCount}
                                                   effect={Effect.SCALE}
                                                   frameLength={15.0}
                                                   style={notificationCountStyle}/>
                            </i>
                        </div>

                        <div>
                            <Popover id="popover-element"
                                     className="notification-popover"
                                     placement="top"
                                     isOpen={this.state.isOpenPopover}
                                     target="popoverNotification"
                                     toggle={this.togglePopover}
                            >
                                <PopoverHeader id="popover-element">
                                    <Row id="popover-element">
                                        <Col id="popover-element" lg={10} xs={10}>
                                            <span id="popover-element" className="popover-title">Notifications</span>
                                        </Col>
                                        <Col lg={2} xs={2}>
                                            <span onClick={this.togglePopover}
                                                  className="fa fa-times span-remove"/>
                                        </Col>
                                    </Row>
                                </PopoverHeader>
                                <PopoverBody id="popover-element" className="notification-popover-body">

                                    {
                                        this.props.notificationData ?
                                            (this.props.notificationData.notifications.length > 0 ?
                                                (this.props.notificationData.notifications.map((item, index) => {
                                                    let lineBreak="";
                                                    if(index!==0){
                                                        lineBreak = <hr id="popover-element"></hr>
                                                    }
                                                    return (<div id="popover-element">
                                                        {lineBreak}
                                                        <span id="popover-element"
                                                              className={item.readStatus ? "" : "font-bold"}
                                                              style={{fontSize: 'larger'}}>{item.title}</span>
                                                        <br/>
                                                        <span id="popover-element"
                                                              className={item.readStatus ? "" : "font-bold"}>{item.text}</span>
                                                        <br/>
                                                        <span id="popover-element"
                                                              className={item.readStatus ? "" : "font-bold"}>{this.formatDate(item.notificationDate)}</span>

                                                    </div>)
                                                })):<div id="popover-element">No Data</div>)
                                            :<div id="popover-element">No Data</div>
                                    }
                                </PopoverBody>
                            </Popover>
                        </div>

                        {/*End popover*/}

                        <div style={{marginRight: '6px',marginTop: '4px'}}>
                           Go{' '}<span className="header-text-font">{'SprinkleZone'+` `} </span>
                        </div>
                        <div>
                            <a href="https://sprinklezone.harbinger-systems.com" target="_blank"><i className="fa fa-angle-right fa-2x"/></a>
                        </div>
                    </Nav>
                </Navbar>

            </header>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getUserNotifications,
            setUserNotificationsAsRead

        },
        dispatch
    );
}


const mapStateToProps = state => {

    return {
        notificationData: state.outlookHeaderReducer.notificationData || null,
        notificationReadData : state.outlookHeaderReducer.notificationReadData || null


    }
};

export default connect(mapStateToProps, mapDispatchToProps)(OutlookHeader);

