import React, {Component} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {getPendingSkills} from "./pendingSkillsAction";
import config from "../../config/config";
import DefaultImg from '../../lessons/lessonImg/default_img_upload.png';
import outlook from './../outlook.scss';

import {css} from "@emotion/core";
import SyncLoader from "react-spinners/SyncLoader";

const override = css`
  display: block;
  margin: 200px auto;
  width: 100px;
  padding: '25%'
`;

class PendingSkills extends Component {
    constructor(props) {
        super(props);
   this.props.homeVisibility(false);
   this.state = {
            loading: false
        };
    }

    componentDidMount = async () => {
        if (!localStorage.getItem("mail")) {
           // alert("User Not Logged In!!!");
            this.props.history.push("/");
        }

        this.setState({loading: true});
        await this.props.getPendingSkills();
        if (this.props.uncompletedSkillsData) {
            let loadingTime = this;
            setTimeout(function () {
                loadingTime.setState({loading: false});
            }, 200);
        }
        window.speechSynthesis.cancel();
    };

    render() {

        return (
            <div className="page-container quest-wrapper outlook-quest-wrapper">
                <div className="container-fluid mob-container">
                    {this.state.loading ?
                        <SyncLoader
                            css={override}
                            size={20}
                            color={"#0b6eb6"}
                            loading={this.state.loading}/>
                        : <div className="main-container">
                            <div className="row">
                                <h2 className="outlook-page-title">
                                    <span> Pending Nuggets (
                                        {this.props.uncompletedSkillsData
                                            ? this.props.uncompletedSkillsData.incompleteSkills.length
                                            : 0
                                        })
                                    </span>
                                </h2>
                            </div>
                            <div className="row scroll-container-inner">
                                {this.props.uncompletedSkillsData
                                    ? this.props.uncompletedSkillsData.incompleteSkills.length > 0
                                        ? this.props.uncompletedSkillsData.incompleteSkills.map((item, index) =>

                                                /*------------------------------------------------------------------------------*/
                                                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 p-2">
                                                    <div className="main-div">
                                                        <div className="card custom-card">
                                                            <div className="card-body">
                                                                <div className="row">
                                                                    <div
                                                                        className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                                                        {
                                                                            item.image ? <img
                                                                                    src={config.api.server_url.request_url.base_url + "getUploadedFile/" + item.image}
                                                                                    className="nudge_image"
                                                                                    alt={item.name}/>
                                                                                : <img
                                                                                    className="nudge_image"
                                                                                    src={DefaultImg}
                                                                                    alt='Img'/>
                                                                        }

                                                                    </div>                                                                
                                                                <div className="col-7 col-sm-7 col-md-7 col-lg-7 col-xl-9">
                                                                    <div className="nugget-desc" key={index}>
                                                                        <div className="nuggets_name_show" title={item.name}>
                                                                            <span className="nuggets-title">{item.name}</span>
                                                                        </div>

                                                                        <div className="nugget_description" title={item.description}>
                                                                            <span className="nuggets-text">{item.description}</span>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            /*------------------------------------------------------------------------------*/
                                        )
                                        : "" : ""}
                            </div>
                        </div>}
                </div>
            </div>

        );
    }


}


function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getPendingSkills,
        },
        dispatch
    );
}

const mapStateToProps = state => {
    return {
        uncompletedSkillsData: state.pendingSkillsReducer.uncompletedSkillsData || null
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PendingSkills);