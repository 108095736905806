import * as actionTypes from './profileActionTypes';

const profileReducer = (state = {}, action) => {

    switch (action.type) {

          case actionTypes.FETCH_USER_PROFILE_SUCCESS:
            return Object.assign({}, state, {
                userDetails: action.payload,
            });

            case actionTypes.FETCH_USER_PROFILE_FAILURE:
            return Object.assign({}, state, {
                userDetails: action.payload,
            });
         default:
            return state;
    }
}
export default profileReducer;