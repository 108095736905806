import * as actionTypes from './signUpPageActionType';
import axios from 'axios/index';
import config from '../../containers/config/config';

import {confirmAlert} from "react-confirm-alert";

export function createUser(userData,props) {

    return function (dispatch) {
        return axios.post(config.api.server_url.request_url.base_url + "createUser", userData)
            .then((res) => {
                if (res.data.meta.success) {

                    confirmAlert({
                        message: res.data.meta.message,
                        buttons: [
                            {
                                label: 'Ok',
                                onClick: () => {
                                    props.history.push('/');
                                }
                            }
                        ]
                    });

                    dispatch({type: actionTypes.CREATE_USER_SUCCESS, payload: res.data});
                } else if (!(res.data.meta.success)) {

                    confirmAlert({
                        message: res.data.meta.message,
                        buttons: [
                            {
                                label: 'Ok',
                                onClick: () => {
                                    props.history.push('/sign-up');
                                }
                            }
                        ]
                    });

                    dispatch({type: actionTypes.CREATE_USER_ALREADY_EXIST, payload: res.data});
                } else {
                    alert("Something went wrong!");
                }
            }).catch(error => {

                confirmAlert({
                    message: error.message,
                    buttons: [
                        {
                            label: 'Ok',
                            onClick: () => {

                            }
                        }
                    ]
                });

                dispatch({type: actionTypes.CREATE_USER_FAILURE, error: error});
            });
    };
}


export function getAllOrganizations() {
    return function (dispatch) {
        return axios.get(config.api.server_url.request_url.base_url + "getAllOrganizationsForSignUp", {
            headers: {
                //Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        }).then((res) => {
            if(res.data.meta.success){
                dispatch({ type: actionTypes.FETCH_ALL_ORGANIZATION_SUCCESS, payload: res.data.data });
            }else{
                alert("something went wrong...");
            }
        }).catch(error => {
            dispatch({type: actionTypes.FETCH_ALL_ORGANIZATION_FAILURE, error: error});
        });
    };
}
