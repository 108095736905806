import * as actionTypes from './headerActionType';

const headerReducer = (state = {}, action) => {
    switch (action.type) {
        case actionTypes.LOG_OUT:
            return Object.assign({}, state, {
                result: action.payload.data,
                status: action.payload.status
            });

        case actionTypes.FETCH_NOTIFICATIONS_SUCCESS:
            return Object.assign({}, state, {
                notificationData: action.payload,
            });

        case actionTypes.FETCH_NOTIFICATIONS_FAILURE:
            return Object.assign({}, state, {
                notificationData: action.error,
            });

        case actionTypes.READ_NOTIFICATIONS_SUCCESS:
            return Object.assign({}, state, {
                notificationReadData: action.payload,
            });

        case actionTypes.READ_NOTIFICATIONS_FAILURE:
            return Object.assign({}, state, {
                notificationReadData: action.error,
            });
        case actionTypes.READ_NOTIFICATION_SUCCESS:
            return Object.assign({}, state, {
                readData: action.payload,
            });

        case actionTypes.READ_NOTIFICATION_FAILURE:
            return Object.assign({}, state, {
                readData: action.error,
            });

        case actionTypes.GET_CONTACT_REASONS_SUCCESS:
            return Object.assign({}, state, {
                getContactReasonsData: action.payload,
            });

        case actionTypes.GET_CONTACT_REASONS_FAILURE:
            return Object.assign({}, state, {
                getContactReasonsData: action.payload,
            });
        case actionTypes.SAVE_CONTACT_ADMIN_DETAILS_SUCCESS:
            return Object.assign({}, state, {
                saveContactData: action.payload,
            });
        case actionTypes.SAVE_CONTACT_ADMIN_DETAILS_FAILURE:
            return Object.assign({}, state, {
                saveContactData: action.error,
            });
        case actionTypes.CHANGEPASSWORD_SUCCESS:
            return Object.assign({}, state, {
                updatePassword: action.payload.data
            });

        case actionTypes.CHANGEPASSWORD_FAILURE:
            return Object.assign({}, state, {
                updatePassword: action.payload
            });
        case actionTypes.RESET_SAVE_CONTACT_ADMIN_DETAILS:
            return Object.assign({}, state, {
                saveContactData: null,
            });
        default:
            return state;
    }
}
export default headerReducer;