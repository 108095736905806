import * as actionTypes from './quizActionTypes';

const questionReducer = (state = {}, action) => {
    switch (action.type) {
        case actionTypes.FETCH_QUESTIONS_SUCCESS:
            return Object.assign({}, state, {
                result: action.payload,
            });
        case actionTypes.STORE_RESULT_SUCCESS:
            return Object.assign({}, state, {
                result: action.payload,
            });
        case actionTypes.FETCH_QUIZ_DATA_SUCCESS:
            return Object.assign({}, state, {
                quizData: action.payload,
            });
        case actionTypes.FETCH_UNASSIGNED_SKILLS_SUCCESS:
            return Object.assign({}, state, {
                unassignedData: action.payload,
            });
        case actionTypes.STORE_NUDGE_RESULT_SUCCESS:
            return Object.assign({}, state, {
                nudgeResult: action.payload,
            });
        default:
            return state;
    }
}
export default questionReducer;