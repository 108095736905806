import * as actionTypes from './outlookHeaderActionType';

const outlookHeaderReducer = (state = {}, action) => {
    switch (action.type) {
        case actionTypes.FETCH_NOTIFICATIONS_SUCCESS:
            return Object.assign({}, state, {
                notificationData: action.payload,
            });

        case actionTypes.FETCH_NOTIFICATIONS_FAILURE:
            return Object.assign({}, state, {
                notificationData: action.error,
            });

        case actionTypes.READ_NOTIFICATIONS_SUCCESS:
            return Object.assign({}, state, {
                notificationReadData: action.payload,
            });

        case actionTypes.READ_NOTIFICATIONS_FAILURE:
            return Object.assign({}, state, {
                notificationReadData: action.error,
            });


        default:
            return state;
    }
}
export default outlookHeaderReducer;