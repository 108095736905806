import * as actionTypes from './pendingSkillsActionType';

const pendingSkillsReducer = (state = {}, action) => {
    switch (action.type) {
        case actionTypes.FETCH_UNCOMPLETED_SKILLS_SUCCESS:
            return Object.assign({}, state, {
                uncompletedSkillsData: action.payload,
            });

        case actionTypes.FETCH_UNCOMPLETED_SKILLS_FAILURE:
            return Object.assign({}, state, {
                uncompletedSkillsData: action.error,
            });
        default:
            return state;
    }
}
export default pendingSkillsReducer;