export const LOG_OUT = 'LOG_OUT';
export const FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS';
export const FETCH_NOTIFICATIONS_FAILURE = 'FETCH_NOTIFICATIONS_FAILURE';

export const READ_NOTIFICATIONS_SUCCESS = 'READ_NOTIFICATIONS_SUCCESS';
export const READ_NOTIFICATIONS_FAILURE = 'READ_NOTIFICATIONS_FAILURE';

export const READ_NOTIFICATION_SUCCESS = 'READ_NOTIFICATION_SUCCESS';
export const READ_NOTIFICATION_FAILURE = 'READ_NOTIFICATION_FAILURE';

export const GET_CONTACT_REASONS_SUCCESS = 'GET_CONTACT_REASONS_SUCCESS';
export const GET_CONTACT_REASONS_FAILURE = 'GET_CONTACT_REASONS_FAILURE';

export const SAVE_CONTACT_ADMIN_DETAILS_SUCCESS ='SAVE_CONTACT_ADMIN_DETAILS_SUCCESS';
export const SAVE_CONTACT_ADMIN_DETAILS_FAILURE ='SAVE_CONTACT_ADMIN_DETAILS_FAILURE';

export const CHANGEPASSWORD_SUCCESS = 'CHANGEPASSWORD_SUCCESS';
export const CHANGEPASSWORD_FAILURE = 'CHANGEPASSWORD_FAILURE';

export const RESET_SAVE_CONTACT_ADMIN_DETAILS = 'RESET_SAVE_CONTACT_ADMIN_DETAILS';
